const StringUtils = {
	/**
	 * Returns the string with the first letter capitalized.
	 *
	 * @see https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript/53930826#53930826
	 *
	 * @param str String to capitalize
	 * @param locale Locale to consider when capitalizing
	 * @returns String with first letter capitalized
	 */
	capitalizeFirstLetter(str: string, locale = navigator.language) {
		return str.replace(/^\p{CWU}/u, (char) => char.toLocaleUpperCase(locale));
	},
};

export default StringUtils;
