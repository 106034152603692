import { useEffect } from "react";
import { FallbackProps } from "react-error-boundary";
import { useMatch, useNavigate } from "react-router-dom";

function BaseErrorFallback({ error }: FallbackProps) {
	const navigate = useNavigate();
	const match = useMatch("not-found");
	useEffect(() => {
		if (error.name === "ChunkLoadError") {
			window.location.reload();
		} else {
			navigate("not-found", { state: error.message });

			if (match) {
				window.location.reload();
			}
		}
	}, [error, navigate, match]);
	return null;
}

export default BaseErrorFallback;
