import { MenuOption } from "types/common/global";

export const currenciesWithName = [
	{ label: "Euro", value: "EUR" },
	{ label: "Swedish Krona", value: "SEK" },
	{ label: "Pound Sterling", value: "GBP" },
	{ label: "US Dollar", value: "USD" },
	{ label: "Czech Koruna", value: "CZK" },
	{ label: "Norwegian Krone", value: "NOK" },
	{ label: "Danish Krone", value: "DKK" },
	{ label: "Russian Ruble", value: "RUB" },
	{ label: "Japanese Yen", value: "JPY" },
	{ label: "Polish Zloty", value: "PLN" },
	{ label: "Australian Dollar", value: "AUD" },
	{ label: "Hong Kong Dollar", value: "HKD" },
	{ label: "Indian Rupee", value: "INR" },
	{ label: "Turkish Lira", value: "TRY" },
	{ label: "Chinese Yuan", value: "CNY" },
	{ label: "Canadian Dollar", value: "CAD" },
	{ label: "Serbian Dinar", value: "RSD" },
];

export const currenciesData = Object.freeze(
	currenciesWithName.map((fc) => fc.value)
);

export const currencies: readonly MenuOption[] = Object.freeze(
	currenciesData.map((d) => ({
		label: d,
		value: d,
	}))
);
