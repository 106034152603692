import {
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Theme,
	Typography,
	useMediaQuery,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MouseEvent, Suspense, lazy, useMemo, useState } from "react";
import useOrder from "services/Order/useOrder";
import { MainCheckoutLayoutParams } from "types/common/pages";
import { useLocation, useParams } from "react-router-dom";
import useEventFromOrder from "services/Event/useEvent";
import { useTranslation } from "react-i18next";
import {
	MobilePaymentItemOption,
	PaymentSummaryListItem,
} from "./PaymentSummaryItem.styles";
import { useOrderService } from "services/Order";
import { OrderLine, OrderOptionLines } from "services/common/models/order";
import { MainCheckoutRoutes } from "App";
import DialogSuspenseFallback from "components/common/DialogSuspenseFallback";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import { ProductId } from "types/models/Product";
import TicketDifferenceItem from "./TicketDifferenceItem";
import { useConfirmationDialog } from "contexts/ConfirmationDialogProvider/ConfirmationDialogProvider";
import DeleteIcon from "@mui/icons-material/Delete";
import { wmPalette } from "theme/WSGTheme";
import { ParticipantPersonalDetails } from "types/services/orderService.types";
import { useSaveDraftMutation } from "services/Participants/mutations";
import useEventCustomQuestions from "services/Event/useEventCustomQuestions";
import { lazyRetry } from "utils/lazy-retry";
const ChangeTicketDialog = lazy(() =>
	lazyRetry(
		() => import("components/Checkout/ChangeTicketDialog"),
		"changeTicketDialog"
	)
);

interface PaymentSummaryItemProps {
	participantId: string;
	participantName?: string;
	/** This is required in-order to display a fallback if the new participant does not have a name attached*/
	participantFallbackName: string;
	items: OrderLine[];
	isFirst?: boolean;
	getFormTickets: () => ParticipantPersonalDetails[] | null;
	optionItem: OrderOptionLines[];
}

function PaymentSummaryItem({
	participantId,
	participantName,
	items,
	participantFallbackName,
	isFirst = false,
	getFormTickets,
	optionItem,
}: PaymentSummaryItemProps) {
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("md")
	);
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const { data: order, mutate: mutateOrder } = useOrder(orderId);
	const { data: event } = useEventFromOrder(order?.eventId);
	const { t } = useTranslation(["common", "dialogs"]);
	const orderService = useOrderService();
	const { confirm } = useConfirmationDialog();

	const [contextAnchorElement, setContextAnchorElement] =
		useState<null | HTMLElement>(null);
	const [isEditing, setIsEditing] = useState(false);
	const { pathname } = useLocation();
	const isPersonalDetailsPage = pathname.endsWith(
		`/${MainCheckoutRoutes.PersonalDetails}`
	);
	const isAddOnsPage = pathname.endsWith(`/${MainCheckoutRoutes.AddOns}`);
	const isTeamInfoPage = pathname.endsWith(`/${MainCheckoutRoutes.TeamInfo}`);
	const { isChangeTicketRoute, isClaimSpotRoute } = useCheckoutRouteMatch();
	const saveDraft = useSaveDraftMutation();
	const { data: customQuestions } = useEventCustomQuestions(
		order?.eventId,
		order?.raceId
	);
	const toggleContextMenu = (e: MouseEvent<HTMLElement>) => {
		setContextAnchorElement((anchorEl) => (anchorEl ? null : e.currentTarget));
	};

	const getParticipantName = (): string => {
		return participantName?.trim() ? participantName : participantFallbackName;
	};

	const handleEditParticipantClose = () => {
		setIsEditing(false);
		setContextAnchorElement(null);
	};

	const requestConfirmation = async () => {
		const confirmed = await confirm({
			title: t("dialogs:deleteParticipant.modal.title"),
			message: t("dialogs:deleteParticipant.modal.description"),
			confirmText: t("common:delete"),
			cancelText: t("common:button.cancel.label"),
			icon: "trash",
			materialIcon: (
				<DeleteIcon sx={{ fontSize: "64px", color: wmPalette.grey[50] }} />
			),
			variant: "critical",
		});

		if (confirmed && order) {
			const data = getFormTickets();
			if (data) {
				await saveDraft.trigger({
					participants: data,
					orderId: order.orderId,
				});
			}
			if (!saveDraft.isMutating) {
				handleDelete(true);
			}
		}
	};

	const handleDelete = async (confirmedDelete: boolean) => {
		if (confirmedDelete && order) {
			const updatedOrder = await orderService.deleteParticipant(
				order.orderId,
				participantId
			);

			if (updatedOrder) {
				mutateOrder(updatedOrder, { revalidate: false });
			}
		}

		setContextAnchorElement(null);
	};

	const hasAnyParticipantSelectedItab = order?.participants.some(
		(p) => p.hasITabSelected
	);

	const showDeleteOption =
		!order?.teamTicket &&
		(isPersonalDetailsPage || isAddOnsPage || isTeamInfoPage) &&
		!isFirst;
	const showChangeOption = order?.teamTicket
		? isPersonalDetailsPage && isFirst && !isClaimSpotRoute
		: isPersonalDetailsPage && !isClaimSpotRoute;
	const hasOption = showDeleteOption || showChangeOption;

	const showOptionContextMenu =
		(isPersonalDetailsPage || isAddOnsPage || isTeamInfoPage) &&
		hasOption &&
		!isMobile &&
		!isClaimSpotRoute;

	const generalTicketItems = useMemo(() => {
		if (isChangeTicketRoute) {
			return items.filter((i) => i.productId !== ProductId.TicketUpgrade);
		}

		return items;
	}, [isChangeTicketRoute, items]);

	const ticketUpgradeItem = useMemo(() => {
		if (!isChangeTicketRoute) {
			return null;
		}

		return items.find((i) => i.productId === ProductId.TicketUpgrade);
	}, [isChangeTicketRoute, items]);

	return (
		<>
			<Stack rowGap=".25rem" sx={{ width: "100%" }}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="subtitle2" fontSize={14}>
						{getParticipantName()}
					</Typography>
					{showOptionContextMenu && (
						<IconButton onClick={toggleContextMenu} sx={{ padding: "1px" }}>
							<MoreVertIcon />
						</IconButton>
					)}
				</Stack>
				{ticketUpgradeItem && (
					<TicketDifferenceItem
						ticketUpgradeItem={ticketUpgradeItem}
						currency={order?.convertToCurrency}
						refundableBookingDifference={order?.refundInsuranceAmountConverted}
					/>
				)}
				{!isClaimSpotRoute &&
					generalTicketItems.map((i) => (
						<PaymentSummaryListItem
							key={i.productId}
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography fontSize={14} maxWidth="24ch">
								{i.productName}
							</Typography>
							<Typography fontSize={14}>{i.priceCurrencyFormatted}</Typography>
						</PaymentSummaryListItem>
					))}

				{hasAnyParticipantSelectedItab &&
					optionItem.map((i) => (
						<PaymentSummaryListItem
							key={i.productId}
							direction="row"
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography fontSize={14} maxWidth="24ch">
								{i.productName}
							</Typography>
							<Typography fontSize={14}>{i.priceCurrencyFormatted}</Typography>
						</PaymentSummaryListItem>
					))}

				{isClaimSpotRoute && (
					<PaymentSummaryListItem
						key={order?.teamName}
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<Typography fontSize={14} maxWidth="24ch">
							{order?.teamName}
						</Typography>
					</PaymentSummaryListItem>
				)}
				{isMobile && (
					<Stack direction="row" alignItems="center" gap={4}>
						{showChangeOption && (
							<MobilePaymentItemOption
								variant="primary-text-dark"
								size="small"
								onClick={() => setIsEditing(true)}
							>
								{t("changeTicket.option")}
							</MobilePaymentItemOption>
						)}
						{showDeleteOption && (
							<MobilePaymentItemOption
								variant="primary-text-dark"
								size="small"
								onClick={requestConfirmation}
							>
								{t("deleteParticipant.option")}
							</MobilePaymentItemOption>
						)}
					</Stack>
				)}
			</Stack>
			{!isMobile && (
				<Menu
					open={Boolean(contextAnchorElement)}
					anchorEl={contextAnchorElement}
					onClose={toggleContextMenu}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					transformOrigin={{ vertical: "top", horizontal: "right" }}
				>
					{showChangeOption && (
						<MenuItem onClick={() => setIsEditing(true)}>
							{t("changeTicket.option")}
						</MenuItem>
					)}
					{showDeleteOption && (
						<MenuItem onClick={requestConfirmation}>
							{t("deleteParticipant.option")}
						</MenuItem>
					)}
				</Menu>
			)}
			{event && (
				<Suspense fallback={<DialogSuspenseFallback />}>
					<ChangeTicketDialog
						eventId="brussels-marathon"
						participant={{ id: participantId, name: getParticipantName() }}
						open={isEditing}
						onClose={handleEditParticipantClose}
					/>
				</Suspense>
			)}
		</>
	);
}

export default PaymentSummaryItem;
