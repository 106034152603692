import { Order } from "services/common/models/order";
import useSWRImmutable from "swr";
import {
	configureDefaultActions,
	ServiceActionDefaults,
} from "services/common/utils/hookUtils";
import { useErrorHandler } from "react-error-boundary";
import { useLocale } from "contexts/LocaleProvider/LocaleProvider";
import { currenciesData } from "types/common/models";
import { useEffect } from "react";
import axios from "axios";
import { ServiceBaseUrls } from "services/common/constants";
import { PageId } from "types/common/pages";
import { axiosErrorHandler } from "services/AxiosErrorHandling";
import { matchPath } from "react-router-dom";
import { MainRoutes } from "App";

const useOrder = (
	orderId?: string,
	defaultCurrency?: string,
	actions?: ServiceActionDefaults
) => {
	const { currency, onLocaleStateChange, preventCurrencyChange } = useLocale();

	const modifiers = useSWRImmutable<Order>(
		orderId ? [orderId, defaultCurrency ?? currency] : null,
		async (key: [string, string]) => {
			const matchedPath = matchPath(
				{ path: `${MainRoutes.ClaimSpot}/*`, end: false },
				location.pathname
			);
			let url = `${ServiceBaseUrls.Order}/${orderId}?currency=${
				defaultCurrency ?? currency
			}`;
			if (matchedPath) {
				url = `${ServiceBaseUrls.Order}/${orderId}?currency=${
					defaultCurrency ?? currency
				}&token=${matchedPath.params.participantToken}`;
			}
			const { data } = await axios.get<Order>(url);

			return data;
		},
		{
			onSuccess(order) {
				if (order.userDisplayCurrency) {
					const matchedCurrency = currenciesData.find(
						(c) => c.toLowerCase() === order.userDisplayCurrency?.toLowerCase()
					);
					if (matchedCurrency) {
						onLocaleStateChange?.("currency", matchedCurrency);
					}
				}
			},
		}
	);

	useEffect(() => {
		if (
			preventCurrencyChange?.value === false &&
			modifiers.data?.userDisplayCurrency &&
			modifiers.data?.orderStateSequence !== PageId.TicketSelect
		) {
			preventCurrencyChange.setValue(true);
		}
	}, [modifiers.data?.userDisplayCurrency, preventCurrencyChange?.value]);

	if (modifiers.error) {
		const apiError = axiosErrorHandler(axios).getErrorMessage(modifiers.error);
		if (typeof newrelic !== "undefined") {
			newrelic.noticeError(apiError.message, {
				requestUrl: `${ServiceBaseUrls.Order}/${orderId}?currency=${
					defaultCurrency ?? currency
				}`,
			});
		}
	}

	useErrorHandler(modifiers.error);

	configureDefaultActions(modifiers, actions);

	return modifiers;
};

export default useOrder;
