import { Box, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Order } from "services/common/models/order";
import PaymentSummaryItem from "../../PaymentSummaryItem/PaymentSummaryItem";
import { ParticipantPersonalDetails } from "types/services/orderService.types";
import useEventFromOrder from "services/Event/useEvent";
import useCheckoutRouteMatch from "hooks/useRouteCheck";

interface GeneralParticipantsListProps {
	order: Order;
	onGetParticipantName: (index: number) => string | null;
	hidden?: boolean;
	getFormTickets: () => ParticipantPersonalDetails[] | null;
}

const GeneralParticipantsList = ({
	order,
	onGetParticipantName,
	hidden = false,
	getFormTickets,
}: GeneralParticipantsListProps) => {
	const { t } = useTranslation("common");
	const { data: event } = useEventFromOrder(order?.eventId);
	const { isClaimSpotRoute } = useCheckoutRouteMatch();
	if (hidden) {
		return null;
	}

	return (
		<Box>
			{order.participants.map((p, index) => {
				const editedParticipantName = onGetParticipantName(index);

				if (isClaimSpotRoute) {
					if (
						(p.orderLines.length && p.orderLines.length === 0) ||
						(order.teamTicket && index > 0)
					) {
						return null;
					}
				} else {
					if (
						!event?.hasITabEnabled &&
						((p.orderLines.length && p.orderLines.length === 0) ||
							(order.teamTicket && index > 0))
					) {
						return null;
					}
				}

				return (
					<ListItem key={p.id} disableGutters>
						<PaymentSummaryItem
							participantId={p.id}
							participantName={editedParticipantName || p.fullName}
							participantFallbackName={`${t("common:participant.label")} ${
								index + 1
							}`}
							items={p.orderLines}
							isFirst={index === 0}
							getFormTickets={getFormTickets}
							optionItem={p.orderOptionLines}
						/>
					</ListItem>
				);
			})}
		</Box>
	);
};

export default GeneralParticipantsList;
