import { AppBar, AppBarProps, IconButton, styled } from "@mui/material";
import { wmPalette } from "theme/WSGTheme";

export const NavigationBarIcon = styled(IconButton)`
	color: ${wmPalette.black["90"]};
`;

export const StyledNavigationBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
	backgroundColor: wmPalette.white["100"],
	paddingInline: "1rem",
	position: "sticky",
	boxShadow: "none",
	zIndex: 2,
	[theme.breakpoints.down("sm")]: {
		zIndex: 1,
		paddingInline: ".5rem",
	},
}));
