import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import PageLocaleSettingsProvider from "contexts/LocaleProvider";
import "services/Localization/i18n";
import dayjs from "dayjs";
import toObjectPlugin from "dayjs/plugin/toObject";
import DurationPlugin from "dayjs/plugin/duration";
import isSameOrAfterPlugin from "dayjs/plugin/isSameOrAfter";
import isSameOrBeforePlugin from "dayjs/plugin/isSameOrBefore";
import PageControlProvider from "contexts/PageControlProvider/PageControlProvider";
import RelativeTimePlugin from "dayjs/plugin/relativeTime";
import PaymentProvider from "contexts/PaymentProvider";
import "services/interceptors";

axios.defaults.baseURL = process.env["REACT_APP_API_URL"];

dayjs.extend(toObjectPlugin);
dayjs.extend(DurationPlugin);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(isSameOrBeforePlugin);
dayjs.extend(RelativeTimePlugin);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<React.StrictMode>
		<PageControlProvider>
			<PageLocaleSettingsProvider>
				<PaymentProvider>
					<App />
				</PaymentProvider>
			</PageLocaleSettingsProvider>
		</PageControlProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
