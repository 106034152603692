import { SxProps, Theme } from "@mui/material";
import wsgTheme, { wmPalette } from "theme/WSGTheme";

const creditSectionContainerSx: SxProps<Theme> = {
	fontSize: "0.875rem",
	[".MuiFormControl-root.Mui-disabled"]: {
		cursor: "not-allowed",
	},
};

const remainingCreditsDialogPaperSx: SxProps<Theme> = {
	minWidth: (theme: Theme) => (theme.breakpoints.down("sm") ? "auto" : "560px"),
};

const remainingCreditsDialogActionsSx: SxProps = {
	padding: "0 1.5rem 1.5rem",
};

const creditValueTextSx: SxProps = {
	fontSize: "1rem",
	color: wmPalette.grey[90],
	[wsgTheme.breakpoints.up("md")]: {
		fontSize: "2.5rem",
	},
	transition: "font-size ease-in-out 0.2s",
};

const remainingCreditAlertSx: SxProps = {
	padding: "1.5rem",
	width: "fit-content",
};

const getOpenRemainingCreditsDialogLinkSx: SxProps<Theme> = {
	fontSize: "0.75rem",
	mb: ".75rem",
	cursor: "pointer",
	width: "fit-content",
};

const errorPopoverSx: Record<"paper" | "root", SxProps> = {
	root: { pointerEvents: "none" },
	paper: { minWidth: "200px", maxWidth: "250px", padding: ".5rem" },
};

const toggleSx: Record<"checkbox" | "label" | "formControl", SxProps> = {
	formControl: {
		[".Mui-disabled:hover"]: {
			cursor: "not-allowed",
		},
	},
	checkbox: {
		["&.Mui-disabled"]: {
			color: wmPalette.grey[40],
		},
	},
	label: {
		["&.MuiTypography-root.Mui-disabled"]: {
			color: wmPalette.grey[50],
		},
	},
};

export default {
	creditSectionContainerSx,
	remainingCreditAlertSx,
	creditValueTextSx,
	remainingCreditsDialogActionsSx,
	remainingCreditsDialogPaperSx,
	getOpenRemainingCreditsDialogLinkSx,
	errorPopoverSx,
	toggleSx,
};
