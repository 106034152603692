import useSWRImmutable from "swr";
import {
	configureDefaultActions,
	ServiceActionDefaults,
} from "services/common/utils/hookUtils";
import { useErrorHandler } from "react-error-boundary";
import axios from "axios";
import { ServiceBaseUrls } from "services/common/constants";
import { axiosErrorHandler } from "services/AxiosErrorHandling";
import { Stock } from "services/common/models/stock";

const useOrderStock = (
	orderId?: string,
	hasStocksEnabled?: boolean,
	fetchOnMount?: boolean,
	actions?: ServiceActionDefaults
) => {
	const defaultStock: Stock = { data: [] };
	const modifiers = useSWRImmutable<Stock>(
		hasStocksEnabled === true && orderId ? orderId : null,
		async (key: string) => {
			if (key) {
				const url = `${ServiceBaseUrls.StockOrderManagement}/${key}/availability`;
				const { data } = await axios.get<Stock>(url);
				return data;
			}
			return defaultStock;
		},
		{
			revalidateOnFocus: false,
			revalidateOnMount: fetchOnMount,
			revalidateIfStale: false,
			refreshWhenHidden: false,
			fallbackData: defaultStock,
		}
	);
	if (modifiers.error) {
		const apiError = axiosErrorHandler(axios).getErrorMessage(modifiers.error);
		if (typeof newrelic !== "undefined") {
			newrelic.noticeError(apiError.message, {
				requestUrl: `${ServiceBaseUrls.StockOrderManagement}/${orderId}/availability`,
			});
		}
	}

	useErrorHandler(modifiers.error);

	configureDefaultActions(modifiers, actions);
	return modifiers;
};

export default useOrderStock;
