import { Box, styled } from "@mui/material";

export const SettingsDialogItemButton = styled(Box)(({ theme }) => ({
	display: "grid",
	width: "100%",
	paddingBlock: ".75rem",
	backgroundColor: "transparent",
	borderColor: "transparent",
	alignItems: "center",
	gridTemplateColumns: "24px max-content 48px",
	columnGap: ".5rem",
	placeSelf: "center stretch",
	"&[data-disabled=true]": {
		[".MuiTypography-root"]: {
			color:
				theme.themeName === "ahotu"
					? theme.palette.grey[600]
					: theme.palette.grey[900],
		},
	},
}));
