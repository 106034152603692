import useSWR from "swr";
import {
	configureDefaultActions,
	ServiceActionDefaults,
} from "services/common/utils/hookUtils";
import { useErrorHandler } from "react-error-boundary";
import { ServiceBaseUrls } from "services/common/constants";
import axios from "axios";
import { OrderParticipant } from "services/common/models/order";
import { axiosErrorHandler } from "services/AxiosErrorHandling";
import { matchPath } from "react-router-dom";
import { MainRoutes } from "App";

const getParticipant = async (
	orderId: string,
	participantToken: string
): Promise<OrderParticipant> => {
	const { data } = await axios.get<OrderParticipant>(
		`${ServiceBaseUrls.Team}/${orderId}/claim/${participantToken}`
	);
	return data;
};

const useParticipant = (
	orderId?: string,
	participantToken?: string,
	actions?: ServiceActionDefaults
) => {
	const isClaimSpotRoute = matchPath(
		{ path: `${MainRoutes.ClaimSpot}/*`, end: false },
		location.pathname
	);
	const modifiers = useSWR<OrderParticipant>(
		isClaimSpotRoute && orderId && participantToken
			? [orderId, participantToken]
			: null,
		(key: [string, string]) => getParticipant(key[0], key[1])
	);

	if (modifiers.error) {
		const apiError = axiosErrorHandler(axios).getErrorMessage(modifiers.error);
		if (typeof newrelic !== "undefined") {
			newrelic.noticeError(apiError.message, {
				requestUrl: `${ServiceBaseUrls.Team}/${orderId}/claim/${participantToken}`,
			});
		}
	}

	useErrorHandler(modifiers.error);

	configureDefaultActions(modifiers, actions);

	return modifiers;
};

export default useParticipant;
