import { SxProps, Theme } from "@mui/material";
import wsgTheme, { wmPalette } from "theme/WSGTheme";

export const contentContainerSx: SxProps<Theme> = {
	[wsgTheme.breakpoints.up("sm")]: {
		marginTop: "1rem",
		paddingInline: 0,
	},
};

export const sidebarSx: SxProps = {
	alignSelf: "start",
	maxHeight: "fit-content",
	top: "7.5vh",
	zIndex: 1,
	[wsgTheme.breakpoints.up(1024)]: {
		zIndex: 0,
	},
};

export const stepperSx = (itemCount: number): SxProps => ({
	my: "3rem",
	mx: "auto",
	maxWidth: `${100 - 100 / itemCount}%`,
});

export const stepperTextSx = {
	["span"]: {
		color: wmPalette.grey[50],
	},
};
