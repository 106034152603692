import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { OrderLine } from "services/common/models/order";
import { PaymentSummaryListItem } from "../PaymentSummaryItem.styles";

interface TicketDifferenceItemProps {
	ticketUpgradeItem: OrderLine;
	refundableBookingDifference?: number;
	currency?: string;
}

const TicketDifferenceItem = ({
	ticketUpgradeItem,
	refundableBookingDifference,
	currency,
}: TicketDifferenceItemProps) => {
	const { t } = useTranslation(["common", "payment-page"]);
	return (
		<Stack>
			<Typography fontSize={14}>
				{t("payment-page:priceDifference.title")}
			</Typography>
			<PaymentSummaryListItem
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				isChangeTicketView
			>
				<Typography fontSize={14} maxWidth="24ch">
					{t("payment-page:ticket")}
				</Typography>
				<Typography fontSize={14}>
					{ticketUpgradeItem.priceCurrencyFormatted}
				</Typography>
			</PaymentSummaryListItem>

			{refundableBookingDifference &&
				refundableBookingDifference > 0 &&
				currency && (
					<PaymentSummaryListItem
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						isChangeTicketView
					>
						<Typography fontSize={14} maxWidth="24ch">
							{t("common:refundableBooking.label")}
						</Typography>
						<Typography fontSize={14}>
							{refundableBookingDifference} {currency}
						</Typography>
					</PaymentSummaryListItem>
				)}
		</Stack>
	);
};

export default TicketDifferenceItem;
