import { Box, styled, SxProps, Theme } from "@mui/material";

export const primaryColorText: SxProps = { color: "primary.main" };
export const addParticipantBtnContainerSx: SxProps = {
	...primaryColorText,
	"& .MuiListItemIcon-root": {
		minWidth: "2rem",
	},
};

export const addCouponAccordionSx: SxProps = {
	border: "none",
	"& .MuiButtonBase-root": {
		paddingInline: 0,
	},
	"& .MuiAccordionDetails-root": {
		padding: ".5rem 0",
	},
};

export const SummaryContentContainer = styled(Box)(({ theme }) => ({
	backgroundColor: "white",
	zIndex: 1,
	padding: "0.5rem 1rem",
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	gap: "0.5rem",
	[theme.breakpoints.up("md")]: {
		height: "auto",
	},
}));

export const addParticipantBtnSx: SxProps<Theme> = {
	paddingInline: 1,
	justifyContent: "start",
	gap: 0,
	color: (theme) => theme.palette.tertiary.main,
	fontWeight: 400,
};
