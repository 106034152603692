import { Card } from "@mui/material";
import { containerCardSx } from "./DesktopPaymentSummary.styles";
import PaymentDetails from "components/Checkout/PaymentSummary/PaymentDetails";
import PaymentPromises from "components/Checkout/PaymentSummary/PaymentPromises";
import { DevicePaymentSummaryProps } from "components/Checkout/PaymentSummary/PaymentSummary";
import { SummaryImage } from "components/Checkout/PaymentSummary/PaymentSummary.styles";
import { useParams } from "react-router-dom";
import { MainCheckoutLayoutParams } from "types/common/pages";
import useOrder from "services/Order/useOrder";
import useEventFromOrder from "services/Event/useEvent";
import useCheckoutRouteMatch from "hooks/useRouteCheck";

function DesktopPaymentSummary({
	onAddParticipantClick,
	...props
}: DevicePaymentSummaryProps) {
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const { data: order } = useOrder(orderId);
	const { data: event } = useEventFromOrder(order?.eventId);
	const { isClaimSpotRoute } = useCheckoutRouteMatch();

	return (
		<aside>
			<Card sx={containerCardSx}>
				{event?.image && (
					<SummaryImage
						sx={{
							backgroundImage: `url(${event?.imageSmall})`,
						}}
					/>
				)}
				<PaymentDetails
					{...props}
					onAddParticipantBtnClick={onAddParticipantClick}
				/>
			</Card>
			{!isClaimSpotRoute && (
				<PaymentPromises
					isRefundableBookingEnabled={event?.isRefundableBookingEnabled ?? true}
				/>
			)}
		</aside>
	);
}

export default DesktopPaymentSummary;
