import { useErrorHandler } from "react-error-boundary";
import { SWRRequestKeys } from "services/common/constants";
import {
	configureDefaultActions,
	ServiceActionDefaults,
} from "services/common/utils/hookUtils";
import { useEventService } from "services/Event";
import useSWR from "swr";
import { CustomQuestions } from "types/CustomQuestions";

export const useEventCustomQuestions = (
	eventId?: string,
	raceId?: string,
	actions?: ServiceActionDefaults
) => {
	const { getCustomQuestions } = useEventService();
	const modifiers = useSWR<CustomQuestions>(
		eventId && raceId && SWRRequestKeys.CustomQuestions,
		() => getCustomQuestions({ eventId, raceId })
	);

	useErrorHandler(modifiers.error);
	configureDefaultActions(modifiers, actions);

	return modifiers;
};

export default useEventCustomQuestions;
