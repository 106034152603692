import useMutation from "swr/mutation";
import axios, { AxiosError } from "axios";
import { ServiceBaseUrls } from "services/common/constants";
import useCurrency from "services/Localization/useCurrency";
import { Order } from "services/common/models/order";
import { useSnackbar } from "notistack";
import { MutationExtraArg } from "types/common/swr";
import { ISaveDraftRequest } from "types/services/participantsService.types";
import { axiosErrorHandler } from "services/AxiosErrorHandling";
import { ITabPreferenceRequest } from "types/services/orderService.types";

type ParticipantsMutationKey = "saveDraft" | "updateITabPreference";

export const useSaveDraftMutation = () => {
	const currency = useCurrency();
	const { enqueueSnackbar } = useSnackbar();
	let capturedOrderId: string;
	let capturedPayload: string;
	return useMutation<Order, any, ParticipantsMutationKey, ISaveDraftRequest>(
		"saveDraft",
		async (
			_: ParticipantsMutationKey,
			{ arg }: MutationExtraArg<ISaveDraftRequest>
		) => {
			if (!arg.orderId) {
				throw new AxiosError(
					"Order Id is not provided",
					AxiosError.ERR_BAD_REQUEST
				);
			}

			capturedOrderId = arg.orderId;
			capturedPayload = JSON.stringify(arg.participants);
			const { data } = await axios.put<Order>(
				`${ServiceBaseUrls.Participants}/${arg.orderId}/draft?currency=${currency}`,
				arg.participants
			);
			return data;
		},
		{
			onError(err) {
				const isAxiosError = axios.isAxiosError(err);
				const apiError = axiosErrorHandler(axios).getErrorMessage(err);
				const orderId = capturedOrderId;
				const payload = capturedPayload;
				if (isAxiosError) {
					const errorData = err.response?.data;

					if (Array.isArray(errorData)) {
						// HACK: This is a temporary fix for the error message
						// that is returned from the API. The API should return
						// an object instead of an array.
						if (errorData.some((d) => typeof d === "object")) {
							const errorObj = errorData as Array<Record<string, any>>;
							enqueueSnackbar(Object.values<string>(errorObj[0])[0], {
								variant: "error",
							});
							if (typeof newrelic !== "undefined") {
								newrelic.noticeError(Object.values<string>(errorObj[0])[0], {
									requestUrl: apiError.requestUrl,
									orderId: orderId,
									payload: apiError.payload,
								});
							}
						}

						if (errorData.some((d) => typeof d === "string")) {
							enqueueSnackbar(errorData, {
								variant: "error",
							});
							if (typeof newrelic !== "undefined") {
								newrelic.noticeError(JSON.stringify(errorData), {
									requestUrl: apiError.requestUrl,
									orderId: orderId,
									payload: apiError.payload,
								});
							}
						}
					}
					return;
				}
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Participants}/${orderId}/draft?currency=${currency}`,
						orderId: orderId,
						payload: payload,
					});
				}
				enqueueSnackbar(
					"There was an issue when saving your personal details",
					{ variant: "error" }
				);
			},
		}
	);
};

export const useITabPreferenceMutation = () => {
	const { enqueueSnackbar } = useSnackbar();
	const currency = useCurrency();
	let capturedOrderId: string;
	let capturedFormData: {
		orderId: string;
		participantId: string;
		hasITabSelected: boolean;
	};
	return useMutation<
		Order,
		any,
		ParticipantsMutationKey,
		ITabPreferenceRequest
	>(
		"updateITabPreference",
		async (
			_: ParticipantsMutationKey,
			{ arg }: MutationExtraArg<ITabPreferenceRequest>
		) => {
			if (!arg.orderId) {
				throw new AxiosError(
					"Order Id is not provided",
					AxiosError.ERR_BAD_REQUEST
				);
			}
			capturedOrderId = arg.orderId;
			const { ...formData } = arg;
			capturedFormData = { ...formData };
			const { data } = await axios.put<Order>(
				`${ServiceBaseUrls.ITabs}/${arg.orderId}/update?currency=${currency}`,
				{ ...formData }
			);
			return data;
		},
		{
			onError(err) {
				const orderId = capturedOrderId;
				const message = "Failed to update payment preference";
				enqueueSnackbar(message, { variant: "error" });
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.ITabs}/${orderId}/update?currency=${currency}`,
						orderId: orderId,
					});
				}
			},
		}
	);
};
