import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Menu } from "@mui/material";
import { useState, MouseEvent, ReactElement, useEffect } from "react";
import { MenuOption } from "types/common/global";
import {
	NavigationMenuItem,
	NMDropdownButton,
} from "./NavigationMenuDropdown.styles";
import { useTranslation } from "react-i18next";

interface NavigationMenuDropdownProps {
	labelIcon: ReactElement;
	options: readonly MenuOption[];
	onItemClick?(e: MouseEvent<HTMLElement>, itemIndex: number): void;
	fallbackMenuLabel?: string;
	defaultValue?: string;
	disabled?: boolean;
}

function NavigationMenuDropdown({
	options,
	labelIcon,
	onItemClick,
	fallbackMenuLabel,
	defaultValue,
	disabled,
}: NavigationMenuDropdownProps) {
	const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { t } = useTranslation("common");

	const handleAnchorElClick = (e: MouseEvent<HTMLElement>) => {
		setAnchorEl(e.currentTarget);
	};

	const handleMenuItemClick = (e: MouseEvent<HTMLElement>, index: number) => {
		setSelectedOptionIndex(index);
		onItemClick?.(e, index);
		removeAnchorEl();
	};

	const removeAnchorEl = () => setAnchorEl(null);

	useEffect(() => {
		if (options && defaultValue) {
			const index = options.findIndex(
				(o) => o.value.toString() === defaultValue
			);

			setSelectedOptionIndex(index === -1 ? 0 : index);
		}
	}, [options, defaultValue]);

	const selectedOption = options[selectedOptionIndex];

	return (
		<>
			<NMDropdownButton
				id="language-selector-button"
				startIcon={labelIcon}
				onClick={handleAnchorElClick}
				size="small"
				disableRipple
				disableElevation
				disabled={disabled}
			>
				{selectedOption.i18nKey && <span>{t(selectedOption.i18nKey)}</span>}
				{!selectedOption.i18nKey && (
					<span>{selectedOption.label ?? fallbackMenuLabel}</span>
				)}
				<KeyboardArrowDownIcon />
			</NMDropdownButton>
			<Menu
				open={Boolean(anchorEl)}
				onClose={removeAnchorEl}
				anchorEl={anchorEl}
				variant="selectedMenu"
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				MenuListProps={{ "aria-labelledby": "language-selector-button" }}
			>
				{options.map((opt, index) => (
					<NavigationMenuItem
						key={index}
						disabled={selectedOptionIndex === index}
						selected={selectedOptionIndex === index}
						onClick={(e) => handleMenuItemClick(e, index)}
					>
						{opt.i18nKey ? t(opt.i18nKey) : opt.label}
					</NavigationMenuItem>
				))}
			</Menu>
		</>
	);
}

export default NavigationMenuDropdown;
