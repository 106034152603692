import PageControlContext, {
	ContinueBtnProps,
	ContinueBtnState,
	pageControlContextDefaults,
	pageControlReducer,
} from "./PageControlContext";
import { PropsWithChildren, useContext, useReducer, useState } from "react";
import { pageControlReducerStateDefaults } from "./PageControlContext.types";

function PageControlProvider({ children }: PropsWithChildren) {
	const [continueBtnProps, setContinueBtnProps] = useState<ContinueBtnProps>(
		pageControlContextDefaults.continueBtnProps
	);
	const [previousContinueBtnState, setPreviousContinueBtnState] =
		useState<ContinueBtnProps>();
	const [state, dispatch] = useReducer(
		pageControlReducer,
		pageControlReducerStateDefaults
	);

	const setButtonState = (
		state: ContinueBtnState | "previous",
		message = ""
	) => {
		if (state === "previous" && previousContinueBtnState) {
			setContinueBtnProps(previousContinueBtnState);
			return;
		}

		setPreviousContinueBtnState(continueBtnProps);
		setContinueBtnProps({
			isDisabled: state !== "enabled",
			isLoading: state === "loading",
			message,
		});
	};

	const enableContinueBtn = () => {
		setButtonState("enabled");
	};

	const continueButtonState: ContinueBtnState = (() => {
		if (continueBtnProps.isDisabled) {
			if (continueBtnProps.isLoading) {
				return "loading";
			}

			return "disabled";
		}

		return "enabled";
	})();

	return (
		<PageControlContext.Provider
			value={{
				continueBtnProps,
				setContinueBtnState: setButtonState,
				continueButtonState,
				enableContinueBtn,
				dispatch,
				pageControlState: state,
			}}
		>
			{children}
		</PageControlContext.Provider>
	);
}

export default PageControlProvider;

export const usePageControlContext = () => useContext(PageControlContext);
