import {
	DialogProps,
	List,
	ListItem,
	Stack,
	Theme,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import Icon from "components/common/Icon/Icon";
import { PropsWithChildren, useContext, useState, useEffect } from "react";
import ConfirmationDialogContext, {
	ConfirmContentType,
	ConfirmationDialogProps,
} from "./ConfirmationDialogContext";
import {
	AhotuIconContainer,
	ConfirmationActions,
	ConfirmationContent,
	ConfirmationTitle,
	ConfirmationMessage,
	ConfirmationButton,
	StyledConfirmationDialog,
	ConfirmationLink,
} from "./ConfirmationDialogProvider.styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { wmPalette } from "theme/WSGTheme";
import React from "react";
import ReactCountryFlag from "react-country-flag";

interface UseConfirmationState {
	resolve: (value: boolean) => void;
}

const ConfirmationDialogProvider = ({ children }: PropsWithChildren) => {
	const [promise, setPromise] = useState<UseConfirmationState>();
	const [dialogProps, setDialogProps] = useState<ConfirmationDialogProps>();
	const [contentType, setContentType] = useState<ConfirmContentType>();
	const theme = useTheme();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("md")
	);

	const confirm = (props: ConfirmationDialogProps) => {
		return new Promise<boolean>((resolve) => {
			setDialogProps(props);
			setPromise({ resolve });
		});
	};

	const handleClose = () => {
		setPromise(undefined);
	};

	const handleConfirm = () => {
		promise?.resolve(true);
		handleClose();
	};

	const handleCancel = () => {
		promise?.resolve(false);
		handleClose();
	};

	const handleModalCancel: DialogProps["onClose"] = (event, reason) => {
		if (
			dialogProps?.disableBackdropClick === true &&
			reason === "backdropClick"
		) {
			return;
		}
		promise?.resolve(false);
		handleClose();
	};

	const getContentType = () => {
		if (typeof dialogProps?.message === "string") {
			return "string";
		}
		if (Array.isArray(dialogProps?.message)) {
			return "array";
		}
		if (React.isValidElement(dialogProps?.message)) {
			return "element";
		}
		return null;
	};

	const getContent = () => {
		if (contentType === "string" || contentType === "element") {
			return (
				<ConfirmationMessage whiteSpace={"pre-wrap"}>
					{dialogProps?.message}
				</ConfirmationMessage>
			);
		}
		if (contentType === "array") {
			return (
				<List sx={{ listStyleType: "disc", pl: 2 }}>
					{(dialogProps?.message as string[]).map((item) => (
						<ListItem sx={{ display: "list-item" }}>{item}</ListItem>
					))}
				</List>
			);
		}
	};

	useEffect(() => {
		setContentType(getContentType());
	}, [dialogProps?.message]);

	const getOpenLinkIcon = () => {
		return (
			<AhotuIconContainer>
				{theme.themeName === "ahotu" && <Icon name={"newTab"} />}
				{theme.themeName === "worldsmarathons" && (
					<OpenInNewIcon sx={{ fontSize: "24px", color: wmPalette.grey[50] }} />
				)}
			</AhotuIconContainer>
		);
	};
	return (
		<ConfirmationDialogContext.Provider
			value={{
				confirm,
			}}
		>
			{children}
			<StyledConfirmationDialog
				open={promise !== undefined}
				variant="vertical"
				isMobile={isMobile}
				onClose={handleModalCancel}
			>
				{dialogProps?.flag && (
					<AhotuIconContainer>
						<ReactCountryFlag
							countryCode={dialogProps.flag}
							svg
							style={{
								width: "fit-content",
								height: "4rem",
								border: "1px solid #7F8589",
							}}
						/>
					</AhotuIconContainer>
				)}
				{dialogProps?.icon && theme.themeName === "ahotu" && (
					<AhotuIconContainer>
						<Icon name={dialogProps.icon} rawSize={["64px", "64px"]} />
					</AhotuIconContainer>
				)}
				{dialogProps?.materialIcon && theme.themeName === "worldsmarathons" && (
					<AhotuIconContainer>{dialogProps.materialIcon}</AhotuIconContainer>
				)}
				<ConfirmationTitle variant="h2" modalType={dialogProps?.variant}>
					{dialogProps?.title}
				</ConfirmationTitle>
				<ConfirmationContent contentType={contentType}>
					{getContent()}
				</ConfirmationContent>
				{dialogProps?.variant === "vertical" ? (
					<ConfirmationActions variant={dialogProps?.variant}>
						<ConfirmationButton
							variant="outlined"
							color={
								theme.themeName === "worldsmarathons" ? "secondary" : "primary"
							}
							onClick={handleConfirm}
							fullWidth
						>
							{dialogProps?.confirmLink ? (
								<Stack
									width={"100%"}
									direction="row"
									gap={"0.5rem"}
									justifyContent={"center"}
								>
									{dialogProps?.confirmText} {getOpenLinkIcon()}
								</Stack>
							) : (
								dialogProps?.confirmText
							)}
						</ConfirmationButton>
						{dialogProps?.cancelText && (
							<ConfirmationLink onClick={handleCancel}>
								{dialogProps?.cancelText}
							</ConfirmationLink>
						)}
					</ConfirmationActions>
				) : (
					<ConfirmationActions variant={dialogProps?.variant}>
						<ConfirmationButton
							variant={
								theme.themeName === "worldsmarathons"
									? "primary-text-dark"
									: undefined
							}
							color={theme.themeName === "worldsmarathons" ? "neutral" : "info"}
							onClick={handleCancel}
							fullWidth
						>
							{dialogProps?.cancelText}
						</ConfirmationButton>
						<ConfirmationButton
							variant={theme.themeName === "ahotu" ? "outlined" : undefined}
							onClick={handleConfirm}
							fullWidth
						>
							{dialogProps?.confirmText}
						</ConfirmationButton>
					</ConfirmationActions>
				)}
			</StyledConfirmationDialog>
		</ConfirmationDialogContext.Provider>
	);
};

export default ConfirmationDialogProvider;

export const useConfirmationDialog = () => {
	const context = useContext(ConfirmationDialogContext);
	if (context === undefined) {
		throw new Error(
			"useConfirmationDialog must be used within a ConfirmationDialogProvider"
		);
	}
	return context;
};
