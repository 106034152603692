export function customFlat<T>(arr: (T | T[])[]): T[] {
	return arr.reduce<T[]>(
		(acc, val) =>
			Array.isArray(val) ? acc.concat(customFlat(val)) : acc.concat(val),
		[]
	);
}

export function customFlatMap<T, U>(
	arr: T[] | undefined,
	callback: (item: T) => U[]
): U[] {
	if (arr === undefined) {
		// Handle the case where arr is undefined
		return [];
	}

	return ([] as U[]).concat(...arr.map(callback));
}
