import { createContext } from "react";

export interface SummaryContextProps {
	orderTotal: string;
}

const SummaryContext = createContext<SummaryContextProps>({
	orderTotal: "0 EUR",
});

export default SummaryContext;
