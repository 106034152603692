import axios from "axios";
import { PropsWithChildren, useMemo } from "react";
import { ApiServices, ServiceBaseUrls } from "services/common/constants";
import { Order } from "services/common/models/order";
import { useCurrency } from "services/Localization";
import Contextualizer from "services/ServiceContextualizer";
import { Nullable } from "types/common/global";
import { IGetUserCreditsResponse } from "./UserCredits.types";
import { axiosErrorHandler } from "services/AxiosErrorHandling";

interface ICreditService {
	activateCredits(orderId: string): Promise<Nullable<Order>>;
	getUserCredits(): Promise<Nullable<IGetUserCreditsResponse>>;
}

const CreditServiceContext = Contextualizer.createContext(
	ApiServices.CreditService
);

function CreditService({ children }: PropsWithChildren) {
	const currency = useCurrency();

	const creditService = useMemo<ICreditService>(
		() => ({
			async activateCredits(orderId) {
				try {
					const data = await axios.post<Order>(
						`${ServiceBaseUrls.Credits}/${orderId}/use-credits?currency=${currency}`
					);

					return data.data;
				} catch (e) {
					const apiError = axiosErrorHandler(axios).getErrorMessage(e);
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: `${ServiceBaseUrls.Credits}/${orderId}/use-credits?currency=${currency}`,
							orderId: orderId,
						});
					}
					return null;
				}
			},
			async getUserCredits() {
				try {
					const data = await axios.get<IGetUserCreditsResponse>(
						`${ServiceBaseUrls.Credits}/me/credits?currency=${currency}`
					);

					return data.data;
				} catch (e) {
					const apiError = axiosErrorHandler(axios).getErrorMessage(e);
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: `${ServiceBaseUrls.Credits}/me/credits?currency=${currency}`,
						});
					}
					return null;
				}
			},
		}),
		[currency]
	);

	return (
		<CreditServiceContext.Provider value={creditService}>
			{children}
		</CreditServiceContext.Provider>
	);
}

export const useCreditService = () => {
	const context = Contextualizer.use<ICreditService>(ApiServices.CreditService);

	if (!context) {
		throw Error("useCreditService must be used within a CreditServiceContext");
	}

	return context;
};

export default CreditService;
