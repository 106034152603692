import { Stack, Typography } from "@mui/material";
import useDomain from "hooks/useDomain";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useEventFromOrder from "services/Event/useEvent";
import useEventProxy from "services/Event/useEventProxy";
import { useCurrency } from "services/Localization";
import useOrder from "services/Order/useOrder";
import { currenciesWithName } from "types/common/models";
import { MainCheckoutLayoutParams } from "types/common/pages";
import footerStyles from "./Footer.styles";
import { usePageControlContext } from "contexts/PageControlProvider/PageControlProvider";

const Footer = () => {
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const order = useOrder(orderId);
	const event = useEventFromOrder(order.data?.eventId);
	const eventProxy = useEventProxy(order.data?.eventId);
	const checkoutCurrency = useCurrency();
	const { t } = useTranslation("common");
	const {
		MainRoutePatterns: { isConfirmationPage, isSelectTicketPage },
	} = useCheckoutRouteMatch();
	const domain = useDomain();
	const { pageControlState } = usePageControlContext();

	const hideMobileBar =
		domain.data?.origin === "ahotu" &&
		(pageControlState?.selectTicketState.count ?? 0) < 1;

	if (order.isLoading || event.isLoading || eventProxy.isLoading) {
		return null;
	}

	if (!order.data || !event.data || !eventProxy.data) {
		return null;
	}

	const { organisers: organiser } = eventProxy.data;
	const { currency } = order.data;

	const currencyName = currenciesWithName.find(
		(c) => c.value.toLowerCase() === currency.toLowerCase()
	)?.label;

	return (
		<Stack
			gap={3}
			component="footer"
			data-theme={domain.data?.origin}
			data-show-buffer={
				(isConfirmationPage === null && isSelectTicketPage === null) ||
				!hideMobileBar
			}
			sx={footerStyles.footerSx}
		>
			<Typography
				component="p"
				variant="subtitle1"
				sx={footerStyles.footerDisclaimerTextSx}
			>
				<Trans
					i18nKey="common:footer"
					values={{
						organiser,
						currencyName,
						currency: currency.toUpperCase(),
						checkoutCurrency,
					}}
					components={{
						highlightedText: (
							<Typography
								component="span"
								variant="subtitle1"
								fontWeight="bold"
								fontSize="inherit"
							/>
						),
					}}
				/>
			</Typography>
			{domain.data?.displayName && (
				<Typography
					component="p"
					variant="subtitle1"
					sx={footerStyles.footerCopyrightTextSx}
				>
					{t("common:copyright", { origin: domain.data.displayName })}
				</Typography>
			)}
		</Stack>
	);
};

export default Footer;
