import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Skeleton, Stack } from "@mui/material";
import { MainCheckoutRoutes } from "App";
import { useOrderService } from "services/Order";
import { MainCheckoutLayoutParams } from "types/common/pages";
import {
	PaypalRequestBody,
	PurchaseUnit,
} from "types/models/PaypalRequestBody";
import useOrder from "services/Order/useOrder";
import { useSnackbar } from "notistack";
import { PaymentQueryKeys } from "types/models/Payment";
import { usePayment } from "contexts/PaymentProvider";
import {
	PayPalButtonOnClickActions,
	PaymentErrors,
} from "contexts/PaymentProvider/PaymentContext.types";
import PayPalPaymentReducerWrapper from "./PayPalPaymentReducerWrapper";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import { useCreatePayPalIntentMutation } from "services/Order/mutations";
import useRecommendedEventMutation from "services/Analytics/Mutations/useRecommendedEventMutation";
import { GAAnalyticsEvent } from "types/services/analyticsService.types";
import useEventFromOrder from "services/Event/useEvent";
import useOrderStock from "services/StockOrderManagement/useOrderStock";
import useStockManagement from "hooks/useStockManagement";

interface PayPalPaymentFormProps {
	isSupported: boolean;
}

function PayPalPaymentForm({ isSupported }: PayPalPaymentFormProps) {
	const { pathname } = useLocation();
	const isPaymentPage = pathname.includes(`/${MainCheckoutRoutes.Payment}`);

	const paypalClientIdEnvVar = process.env["REACT_APP_PAYPAL_CLIENT_ID"];

	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const { data: order, mutate: mutateOrder } = useOrder(orderId);
	const { data: event } = useEventFromOrder(order?.eventId);
	const [paypalClientId, setPaypalClientId] = useState<string>();
	const [payPalDisabled, setPayPalDisabled] = useState(false);
	const [requestBody, setRequestBody] = useState<PaypalRequestBody>();
	const { updateCheckoutPageState, confirmPayPalOrder } = useOrderService();
	const { enqueueSnackbar } = useSnackbar();
	const {
		refundableBookingError,
		refundableBookingOptionSelected,
		paymentRequirements,
		setExpandedOption,
		disabledOptions,
	} = usePayment();

	const couponCode = order?.coupon?.code;

	const hasAnyParticipantSelectedItab = order?.participants.some(
		(p) => p.hasITabSelected
	);

	const isFreeOrder =
		order?.totalValueInCents === 0 && !hasAnyParticipantSelectedItab;
	const [searchParams] = useSearchParams();
	const { isChangeTicketRoute } = useCheckoutRouteMatch();
	const createPayPalPaymentIntent = useCreatePayPalIntentMutation({
		onIntentFail: () => {
			setExpandedOption("stripe");
			disabledOptions.set((prev) => [...prev, "paypal"]);
		},
	});
	const recommendedEvent = useRecommendedEventMutation();
	const { mutate: mutateOrderStock } = useOrderStock(
		order?.orderId,
		event?.hasStocksEnabled,
		false
	);
	const { showOutOfStockModal, findMandatoryOutOfStock, getOutOfStockNames } =
		useStockManagement(event);

	const redirectStatusQueryParam = searchParams.get(
		PaymentQueryKeys.RedirectStatus
	);

	useEffect(() => {
		const clientId = paypalClientIdEnvVar;
		if (clientId) {
			setPaypalClientId(clientId);
		}
	}, [paypalClientIdEnvVar]);

	useEffect(() => {
		if (
			isSupported &&
			orderId &&
			isPaymentPage &&
			!isFreeOrder &&
			redirectStatusQueryParam !== "succeeded"
		) {
			const setupPayPal = async () => {
				const paypalIntentOrderDetails =
					await createPayPalPaymentIntent.trigger({ orderId });
				const body = {
					intent: "CAPTURE",
					purchase_units:
						paypalIntentOrderDetails?.transactions.map(
							(t) =>
								({
									amount: {
										currency_code: t.amount.currency,
										value: t.amount.total,
									},
									reference_id: t.reference_id,
									description: t.description,
									invoice_id: t.invoice_number,
								} as PurchaseUnit)
						) ?? [],
				} as PaypalRequestBody;

				paypalIntentOrderDetails && setRequestBody(body);
			};

			setPayPalDisabled(true);
			setupPayPal();
			setTimeout(() => {
				setPayPalDisabled(false);
			}, 2500);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		orderId,
		order?.hasRefundOptionSelected,
		couponCode,
		isPaymentPage,
		isFreeOrder,
		redirectStatusQueryParam,
		isSupported,
	]);

	const continueToConfirmation = async () => {
		if (order) {
			const updatedOrder = await updateCheckoutPageState(order.orderId);
			if (updatedOrder) {
				await mutateOrder(updatedOrder, { revalidate: false });
			}
		}
	};

	const handlePayPalButtonError = useCallback(
		(err: Record<string, unknown>) => {
			if (typeof newrelic !== "undefined") {
				newrelic.noticeError(JSON.stringify(err));
			}
			const errorMessage = err?.toString().split("at")[0].split(":")[1];

			const paymentRequirementsNotSelected =
				!paymentRequirements.selected || refundableBookingError.show;

			if (paymentRequirementsNotSelected) {
				if (
					!paymentRequirements.selected ||
					errorMessage === PaymentErrors.PAYMENT_REQUIREMENTS_NOT_MET_ERROR
				) {
					paymentRequirements.set((prev) => ({
						...prev,
						show: true,
						error: PaymentErrors.PAYMENT_REQUIREMENTS_NOT_MET_ERROR,
					}));
				}

				if (
					!refundableBookingOptionSelected ||
					errorMessage === PaymentErrors.REFUNDABLE_BOOKING_UNSELECTED_ERROR
				) {
					refundableBookingError.set(() => ({
						show: true,
						error: PaymentErrors.REFUNDABLE_BOOKING_UNSELECTED_ERROR,
					}));
				}
			} else {
				enqueueSnackbar(
					err
						? err.toString().split("at")[0]
						: "PayPal failed to complete your payment, try a different method or try again.",
					{ variant: "error" }
				);
			}
		},
		[refundableBookingOptionSelected, paymentRequirements, enqueueSnackbar]
	);

	async function handlePayPalButtonClick(
		_: Record<string, unknown>,
		actions: PayPalButtonOnClickActions
	) {
		const withRefundableBooking = !isChangeTicketRoute;
		if (event?.hasStocksEnabled) {
			const orderStock = await mutateOrderStock();
			if (orderStock) {
				const addonStock = orderStock.data;
				const outOfStockAddons = addonStock.filter((a) => !a.hasStocks);
				if (outOfStockAddons.length > 0) {
					const outOfStockNames = getOutOfStockNames(outOfStockAddons);
					const hasMandatorySoldOut = findMandatoryOutOfStock(outOfStockAddons);
					showOutOfStockModal(outOfStockNames, hasMandatorySoldOut);
					return actions.reject();
				}
			}
		}
		if (
			!paymentRequirements.selected ||
			(withRefundableBooking &&
				!refundableBookingOptionSelected &&
				event?.isRefundableBookingEnabled)
		) {
			handlePayPalButtonError({});
			return actions.reject();
		}

		if (order) {
			recommendedEvent.trigger({
				analyticsEvent: GAAnalyticsEvent.AddPaymentInfo,
				allowMultiple: false,
			});
		}

		return actions.resolve();
	}

	async function handlePostApproveAction(v: any) {
		if (order) {
			const confirmed = await confirmPayPalOrder(
				order.orderId,
				v.id,
				v.payer.payer_id
			);

			if (confirmed) {
				await continueToConfirmation();
			}
		}
	}

	const paymentCurrency = useMemo(() => {
		return requestBody?.purchase_units.map((u) => u.amount.currency_code)[0];
	}, [requestBody]);

	if (!isPaymentPage) {
		return null;
	}

	if (!paypalClientId || !paymentCurrency) {
		return (
			<Stack>
				<Skeleton width="75%" />
				<Skeleton width="25%" />
			</Stack>
		);
	}

	return (
		<PayPalScriptProvider
			options={{
				"client-id": paypalClientId,
				components: "buttons,marks",
				currency: paymentCurrency,
			}}
		>
			<PayPalPaymentReducerWrapper>
				{({ isLoading }) => (
					<PayPalButtons
						key={order?.orderId}
						fundingSource="paypal"
						style={{ label: "pay", tagline: true }}
						disabled={payPalDisabled || isLoading}
						forceReRender={[
							requestBody,
							refundableBookingOptionSelected,
							paymentRequirements.selected,
						]}
						onClick={handlePayPalButtonClick}
						createOrder={
							requestBody
								? (_, actions) => actions.order.create(requestBody)
								: undefined
						}
						onApprove={async (_, actions) =>
							actions.order?.capture().then(handlePostApproveAction)
						}
						onError={handlePayPalButtonError}
					/>
				)}
			</PayPalPaymentReducerWrapper>
		</PayPalScriptProvider>
	);
}

export default PayPalPaymentForm;
