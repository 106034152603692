import AuthenticationContext, {
	authContextDefaults,
	IAuthenticationContext,
} from "./AuthenticationContext";
import {
	PropsWithChildren,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import AuthenticatedUser, {
	IAuthenticatedUser,
} from "types/models/Authentication/AuthenticatedUser";
import { Order } from "services/common/models/order";
import { PageId } from "types/common/pages";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import { useLocation } from "react-router-dom";
import { MainCheckoutRoutes } from "App";

interface AuthenticationProviderProps extends PropsWithChildren {
	order?: Order;
}

function AuthenticationProvider({
	order,
	children,
}: AuthenticationProviderProps) {
	const { isChangeTicketRoute, isClaimSpotRoute } = useCheckoutRouteMatch();
	const [authenticatedUser, setAuthenticatedUser] = useState<
		IAuthenticatedUser | undefined
	>(authContextDefaults.user);
	const [showAuthenticationBanner, setShowAuthenticationBanner] =
		useState(false);

	const location = useLocation();
	const onConfirmationPage = location.pathname.endsWith(
		MainCheckoutRoutes.Overview
	);

	const authenticationData: IAuthenticationContext = useMemo(
		() => ({
			user: authenticatedUser,
			persistUser(user) {
				localStorage.setItem("user", (user as AuthenticatedUser).token);
				setAuthenticatedUser(user as IAuthenticatedUser);
			},
			handleLogout() {
				localStorage.removeItem("user");
				setAuthenticatedUser(undefined);
			},
			isLoggedIn: authenticatedUser != undefined,
			authenticationBanner: {
				show: showAuthenticationBanner,
				setShow: setShowAuthenticationBanner,
			},
		}),
		[authenticatedUser, showAuthenticationBanner]
	);

	useEffect(() => {
		const savedAccessToken = localStorage.getItem("user");
		if (savedAccessToken) {
			const authUser = new AuthenticatedUser(savedAccessToken);
			setAuthenticatedUser(authUser);
		}
	}, []);

	useEffect(() => {
		if (!authenticationData.isLoggedIn) {
			const isOrderPage = order?.orderStateSequence === PageId.PersonalDetails;
			setShowAuthenticationBanner(
				(Boolean(isChangeTicketRoute) ||
					isOrderPage ||
					Boolean(isClaimSpotRoute)) &&
					!onConfirmationPage
			);
		} else {
			setShowAuthenticationBanner(false);
		}
	}, [
		authenticationData.isLoggedIn,
		order?.orderStateSequence,
		isChangeTicketRoute,
	]);

	return (
		<AuthenticationContext.Provider value={authenticationData}>
			{children}
		</AuthenticationContext.Provider>
	);
}

export default AuthenticationProvider;

export const useAuthentication = () => useContext(AuthenticationContext);
