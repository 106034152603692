import { IconButton } from "@mui/material";
import { SnackbarKey, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/CloseOutlined";

interface SnackbarDismissActionProps {
	snackbarId: SnackbarKey;
}

function SnackbarDismissAction({ snackbarId }: SnackbarDismissActionProps) {
	const { closeSnackbar } = useSnackbar();
	return (
		<>
			<IconButton onClick={() => closeSnackbar(snackbarId)}>
				<CloseIcon />
			</IconButton>
		</>
	);
}

export default SnackbarDismissAction;
