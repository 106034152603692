import { Theme, useMediaQuery } from "@mui/material";
import {
	Dispatch,
	MouseEvent,
	MutableRefObject,
	SetStateAction,
	Suspense,
	lazy,
	useState,
} from "react";
import { FormikProps } from "formik";
import { PersonalDetailsForm } from "pages/PersonalDetailsPage/PersonalDetailsPage";
import { MobilePaymentSummary } from "./MobilePaymentSummary";
import DesktopPaymentSummary from "components/Checkout/PaymentSummary/DesktopPaymentSummary";
import { useLocation, useParams } from "react-router-dom";
import { MainCheckoutLayoutParams } from "types/common/pages";
import useOrder from "services/Order/useOrder";
import { OrderParticipant } from "services/common/models/order";
import TeamInfoForm from "types/models/TeamInfo/teamInfoForm";
import { MainCheckoutRoutes } from "App";
import { useOrderService } from "services/Order";
import { FormValidityData } from "types/misc/SummaryBar";
import { usePageControlContext } from "contexts/PageControlProvider/PageControlProvider";
import DialogSuspenseFallback from "components/common/DialogSuspenseFallback";
import SummaryProvider from "contexts/SummaryProvider";
import { usePayment } from "contexts/PaymentProvider";
import { PaymentErrors } from "contexts/PaymentProvider/PaymentContext.types";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import { GAAnalyticsEvent } from "types/services/analyticsService.types";
import useRecommendedEventMutation from "services/Analytics/Mutations/useRecommendedEventMutation";
import { PersonalDetails } from "pages/PersonalDetailsPage/PersonalDetails.schema";
import dayjs from "dayjs";
import { Nullable } from "types/common/global";
import { ParticipantPersonalDetails } from "types/services/orderService.types";
import useEventCustomQuestions from "services/Event/useEventCustomQuestions";
import useEventFromOrder from "services/Event/useEvent";
import { lazyRetry } from "utils/lazy-retry";
import useOrderStock from "services/StockOrderManagement/useOrderStock";
import useStockManagement from "hooks/useStockManagement";

const AddTicketDialog = lazy(() =>
	lazyRetry(
		() => import("components/Checkout/AddTicketDialog"),
		"addTicketDialog"
	)
);

export interface PaymentSummaryProps {
	formRef?: MutableRefObject<FormikProps<PersonalDetailsForm | TeamInfoForm>>;
	hookFormRef?: MutableRefObject<HTMLFormElement | undefined>;
	formikValues?: FormikProps<PersonalDetailsForm | TeamInfoForm>["values"];
	isFormValid?: FormValidityData;
	setFormValid?: Dispatch<SetStateAction<FormValidityData | undefined>>;
}

export interface DevicePaymentSummaryProps extends PaymentSummaryProps {
	onAddParticipantClick(e: MouseEvent<HTMLButtonElement>): void;
	onSubmit(e: MouseEvent<HTMLButtonElement>): void;
	getFormTickets: () => ParticipantPersonalDetails[] | null;
}

function PaymentSummary(paymentSummaryProps: PaymentSummaryProps) {
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("md")
	);
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const { mutate: mutateOrder, data: order } = useOrder(orderId);
	const { data: event } = useEventFromOrder(order?.eventId);
	const { pathname } = useLocation();
	const orderService = useOrderService();
	const { setContinueBtnState, continueButtonState } = usePageControlContext();
	const {
		paymentRequirements,
		refundableBookingOptionSelected,
		refundableBookingError,
	} = usePayment();
	const [showAddParticipantDialog, setShowAddParticipantDialog] =
		useState(false);
	const { data: customQuestions } = useEventCustomQuestions(
		order?.eventId,
		order?.raceId
	);
	const { mutate: mutateOrderStock } = useOrderStock(
		order?.orderId,
		event?.hasStocksEnabled,
		false
	);
	const { showOutOfStockModal, findMandatoryOutOfStock, getOutOfStockNames } =
		useStockManagement(event);

	const isPaymentsPage = pathname.includes(MainCheckoutRoutes.Payment);
	const {
		isChangeTicketRoute,
		isClaimSpotRoute,
		MainRoutePatterns: { isSelectTicketPage },
	} = useCheckoutRouteMatch();
	const recommendedEvent = useRecommendedEventMutation();
	const { setIsPaymentProcessing } = usePayment();

	const submitForm = () => {
		const { formRef, hookFormRef } = paymentSummaryProps;
		if (hookFormRef && hookFormRef.current && isSelectTicketPage) {
			hookFormRef.current.dispatchEvent(
				new Event("submit", { cancelable: true, bubbles: true })
			);
			return;
		}
		if (formRef && formRef.current) {
			formRef.current.handleSubmit();
		}
	};

	const continueFromPaymentPageAsFreeOrder = async () => {
		setIsPaymentProcessing(true);
		if (orderId && order) {
			if (event?.hasStocksEnabled) {
				const orderStock = await mutateOrderStock();
				if (orderStock) {
					const addonStock = orderStock.data;
					const outOfStockAddons = addonStock.filter((a) => !a.hasStocks);
					if (outOfStockAddons.length > 0) {
						const outOfStockNames = getOutOfStockNames(outOfStockAddons);
						const hasMandatorySoldOut =
							findMandatoryOutOfStock(outOfStockAddons);
						setIsPaymentProcessing(false);
						setContinueBtnState("enabled");
						await showOutOfStockModal(outOfStockNames, hasMandatorySoldOut);
						setIsPaymentProcessing(false);
						return;
					}
				}
			}
			recommendedEvent.trigger({
				analyticsEvent: GAAnalyticsEvent.AddPaymentInfo,
				allowMultiple: false,
			});
			const res = await Promise.all([
				orderService.createFreeOrder(orderId),
				orderService.updateCheckoutPageState(orderId),
			]);
			if (res[0] && res[1]) {
				mutateOrder(res[1], { revalidate: false });
			}
		}
		setIsPaymentProcessing(false);
	};

	const handlePaymentRequirementsError = () => {
		if (!paymentRequirements.selected) {
			paymentRequirements.set((prev) => ({ ...prev, show: true }));
		}

		if (!refundableBookingOptionSelected) {
			refundableBookingError.set({
				show: true,
				error: PaymentErrors.REFUNDABLE_BOOKING_UNSELECTED_ERROR,
			});
		}
	};

	const getContinueAction = (): (() => Promise<void> | void) => {
		if (isPaymentsPage) {
			const hasAnyParticipantSelectedItab = order?.participants.some(
				(p) => p.hasITabSelected
			);

			const isFreeOrder =
				order &&
				order.totalValueInCents === 0 &&
				!hasAnyParticipantSelectedItab;
			const isError =
				isFreeOrder || isChangeTicketRoute || !event?.isRefundableBookingEnabled
					? !paymentRequirements.selected
					: !paymentRequirements.selected || !refundableBookingOptionSelected;

			if (isError) {
				return handlePaymentRequirementsError;
			}

			if (isFreeOrder) {
				return continueFromPaymentPageAsFreeOrder;
			}
		}

		if (isClaimSpotRoute) {
			if (!paymentRequirements.selected) {
				handlePaymentRequirementsError();
			}
		}
		return submitForm;
	};

	const next = async () => {
		const previousBtnState = continueButtonState;
		setContinueBtnState("loading");
		const action = getContinueAction();
		await action();
		setContinueBtnState(previousBtnState);
	};

	const DevicePaymentSummary = isMobile
		? MobilePaymentSummary
		: DesktopPaymentSummary;

	const getFormTickets = () => {
		if (!paymentSummaryProps.formikValues) {
			return null;
		}

		if (
			"tickets" in paymentSummaryProps.formikValues &&
			(paymentSummaryProps.formikValues as PersonalDetailsForm).tickets
		) {
			const tickets = (paymentSummaryProps.formikValues as PersonalDetailsForm)
				.tickets;
			if (order && tickets) {
				const data: ParticipantPersonalDetails[] = tickets.map((t, index) => {
					const participant: Nullable<OrderParticipant> =
						order.participants[index];
					const dob: any = dayjs(t.dateOfBirth).toObject();

					const requiredQuestions: PersonalDetails = Object.assign({}, t);
					delete requiredQuestions.dateOfBirth;

					return {
						...requiredQuestions,
						id: participant?.id,
						fullName: `${t.firstName} ${t.lastName}`,
						birthDate: {
							day: `${dob.date.toString()}`,
							// Months are zero-indexed in dayjs
							month: `${(dob.months + 1).toString()}`,
							year: `${dob.years.toString()}`,
						},
						orderLines: participant?.orderLines ?? [],
						addonOrderLines: participant?.addonOrderLines ?? [],
						ticket: participant?.ticket ?? {},
						extraInformationObject: participant?.extraInformationObject ?? {},
						extraInfoSections: customQuestions,
					} as ParticipantPersonalDetails;
				});
				return data;
			}
		}

		return null;
	};

	return (
		<>
			{order && (
				<SummaryProvider
					order={order}
					formikValues={paymentSummaryProps.formikValues}
				>
					<DevicePaymentSummary
						{...paymentSummaryProps}
						onSubmit={next}
						onAddParticipantClick={() => setShowAddParticipantDialog(true)}
						getFormTickets={getFormTickets}
					/>
				</SummaryProvider>
			)}
			<Suspense fallback={<DialogSuspenseFallback />}>
				<AddTicketDialog
					isOpen={showAddParticipantDialog}
					onClose={() => setShowAddParticipantDialog(false)}
					getFormTickets={getFormTickets}
				/>
			</Suspense>
		</>
	);
}
export default PaymentSummary;
