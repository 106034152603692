import {
	Backdrop,
	Button,
	Collapse,
	Dialog,
	IconButton,
	Stack,
} from "@mui/material";
import { IconName } from "components/common/Icon/assets";
import Icon from "components/common/Icon/Icon";
import { useLocale } from "contexts/LocaleProvider/LocaleProvider";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocaleSettings } from "types/common/global";
import { currencies, languages, measures } from "types/common/models";
import DialogSlideTransition from "../common/Dialog/Transitions/DialogSlideTransition";
import {
	OverflowContainer,
	SettingsContentContainer,
	SettingsContentContainerHeader,
	SettingsViewItemButton,
} from "./SettingsDialog.styles";
import SettingsDialogItem from "./SettingsDialogItem/SettingsDialogItem";
import useDomain from "hooks/useDomain";
import { LocaleContext } from "contexts/LocaleProvider";

type SettingsView = "language" | "currency" | "measure";

interface ActiveSettingView {
	title: string;
	icon: IconName;
}

interface SettingsDialogProps {
	isOpen: boolean;
	onClose?(): void;
}

function SettingsDialog({ isOpen, onClose }: SettingsDialogProps) {
	const { t, ready } = useTranslation(["common"]);
	const [settingsView, setSettingsView] = useState<SettingsView | undefined>();
	const {
		getLocaleStateDisplayText,
		language,
		currency,
		measure,
		onLocaleStateChange,
	} = useLocale();
	const domain = useDomain();

	const updateSetting = (key: keyof LocaleSettings, value: string) => {
		onLocaleStateChange?.(key, value);
	};

	const closeSettings = () => {
		if (settingsView === "currency") {
			setSettingsView(undefined);
		}
		if (onClose) onClose();
	};

	const { preventCurrencyChange } = useContext(LocaleContext);

	useEffect(() => {
		return () => {
			setSettingsView(undefined);
		};
	}, []);

	const labelNameMap = useMemo(() => {
		return {
			language: t("common:languageLabel"),
			currency: t("common:currencyLabel"),
			measure: t("common:measureLabel"),
		};
	}, [t]);

	const activeSettingsView: ActiveSettingView = useMemo(() => {
		switch (settingsView) {
			case "currency":
				return {
					title: labelNameMap.currency,
					icon: "dollar",
				};

			case "measure":
				return {
					title: labelNameMap.measure,
					icon: "tapeMeasure",
				};

			default:
				return {
					title: labelNameMap.language,
					icon: "globe",
				};
		}
	}, [settingsView, t]);

	const selectableLanguages = useMemo(() => {
		return languages.filter((l) => {
			return l;
		});
	}, [languages, domain.data?.origin]);

	return (
		<Dialog
			fullScreen
			open={isOpen}
			TransitionComponent={DialogSlideTransition.RightToLeft}
		>
			<Backdrop open={!ready} />
			<SettingsContentContainer maxWidth="lg">
				<SettingsContentContainerHeader>
					<IconButton onClick={closeSettings} disabled={!ready}>
						<Icon name="close" />
					</IconButton>
					<Collapse
						in={Boolean(settingsView)}
						unmountOnExit
						sx={{ width: "100%" }}
					>
						<Stack justifyContent="stretch" width="100%">
							<Button
								startIcon={<Icon name="chevronLeft" />}
								disabled={!ready}
								onClick={() => setSettingsView(undefined)}
								sx={{ paddingInline: 0, justifyContent: "flex-start" }}
								fullWidth={false}
							>
								All
							</Button>
							{settingsView && (
								<SettingsDialogItem
									title={activeSettingsView.title}
									startIcon={activeSettingsView.icon}
								/>
							)}
						</Stack>
					</Collapse>
				</SettingsContentContainerHeader>
				<Collapse in={!settingsView} unmountOnExit>
					<div>
						<SettingsDialogItem
							title={`${labelNameMap.language}: ${getLocaleStateDisplayText(
								"language"
							)}`}
							startIcon="globe"
							onClick={() => setSettingsView("language")}
						/>
						<SettingsDialogItem
							title={`${labelNameMap.currency}: ${getLocaleStateDisplayText(
								"currency"
							)}`}
							startIcon="dollar"
							IconProps={{
								startIcon: {
									size: "sm",
								},
							}}
							onClick={() => setSettingsView("currency")}
							disabled={preventCurrencyChange?.value}
						/>
						<SettingsDialogItem
							title={`${labelNameMap.measure}: ${getLocaleStateDisplayText(
								"measure"
							)}`}
							startIcon="tapeMeasure"
							onClick={() => setSettingsView("measure")}
						/>
					</div>
				</Collapse>

				<OverflowContainer>
					{settingsView === "language" && !domain.isLoading && (
						<div>
							{selectableLanguages.map((lang) => (
								<SettingsViewItemButton
									key={lang.value}
									onClick={() =>
										updateSetting("language", lang.value.toString())
									}
									active={language === lang.value}
								>
									{lang.label}
								</SettingsViewItemButton>
							))}
						</div>
					)}
					{settingsView === "currency" && (
						<div>
							{currencies.map((c, index) => (
								<SettingsViewItemButton
									key={index}
									active={c.value === currency}
									onClick={() => updateSetting("currency", c.value.toString())}
								>
									{c.label}
								</SettingsViewItemButton>
							))}
						</div>
					)}
					{settingsView === "measure" && (
						<div>
							{measures.map((m, index) => (
								<SettingsViewItemButton
									key={index}
									active={m.value === measure}
									onClick={() => updateSetting("measure", m.value.toString())}
								>
									{m.label}
								</SettingsViewItemButton>
							))}
						</div>
					)}
				</OverflowContainer>
			</SettingsContentContainer>
		</Dialog>
	);
}

export default SettingsDialog;
