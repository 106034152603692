import {
	useScrollTrigger,
	Link,
	useTheme,
	Box,
	Stack,
	Typography,
} from "@mui/material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import { NavBarItem, NavBarSection } from "./DesktopNavigationBar.styles";
import NavigationMenuDropdown from "components/NavigationMenuDropdown";
import { languages } from "types/common/models/languages";
import { currencies } from "types/common/models/currencies";
import { measures } from "types/common/models/measures";
import { StyledNavigationBar } from "components/NavigationBar/NavigationBar.styles";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { LocaleContext } from "contexts/LocaleProvider";
import CSSGrid from "components/common/CSSGrid";
import UserMenu from "components/UserMenu";
import { useAuthentication } from "contexts/AuthenticationProvider/AuthenticationProvider";
import { MainDomains } from "types/services/navigation.types";
import { ReactComponent as AhotuLogo } from "assets/ahotu-logo.svg";
import { ReactComponent as WMLogo } from "assets/wm-logo.svg";
import useDomain from "hooks/useDomain";
import Icon from "components/common/Icon/Icon";

interface DesktopNavigationBarProps {
	onLoginClick(): void;
	onSignupClick(): void;
}

function DesktopNavigationBar({
	onLoginClick,
	onSignupClick,
}: DesktopNavigationBarProps) {
	const { t } = useTranslation(["common"]);

	const { localeState, onLocaleStateChange, preventCurrencyChange } =
		useContext(LocaleContext);
	const { user } = useAuthentication();
	const theme = useTheme();
	const domain = useDomain();

	useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
	});

	return (
		<StyledNavigationBar elevation={0}>
			<CSSGrid
				container
				alignItems="center"
				justifyContent="space-between"
				columns={12}
			>
				<CSSGrid item xs={4}>
					<NavBarSection>
						<NavigationMenuDropdown
							labelIcon={
								domain.isWorldsMarathons ? (
									<LanguageOutlinedIcon />
								) : (
									<Icon name="globe" />
								)
							}
							defaultValue={localeState.language}
							fallbackMenuLabel="Language"
							options={languages}
							onItemClick={(_, itemIndex) =>
								onLocaleStateChange?.(
									"language",
									languages[itemIndex]?.value.toString() ?? "en"
								)
							}
						/>
						<NavigationMenuDropdown
							labelIcon={
								domain.isWorldsMarathons ? (
									<AttachMoneyOutlinedIcon />
								) : (
									<Typography component="span">$</Typography>
								)
							}
							defaultValue={localeState.currency}
							fallbackMenuLabel="Currency"
							options={currencies}
							onItemClick={(_, itemIndex) =>
								onLocaleStateChange?.(
									"currency",
									currencies[itemIndex]?.value.toString() ?? "EUR"
								)
							}
							disabled={preventCurrencyChange?.value}
						/>
						<NavigationMenuDropdown
							labelIcon={
								domain.isWorldsMarathons ? (
									<StraightenOutlinedIcon />
								) : (
									<Icon name="tapeMeasure" />
								)
							}
							defaultValue={localeState.measure?.toString()}
							fallbackMenuLabel="Measure"
							options={measures}
							onItemClick={(_, itemIndex) =>
								onLocaleStateChange?.(
									"measure",
									measures[itemIndex]?.value.toString()
								)
							}
						/>
					</NavBarSection>
				</CSSGrid>
				<CSSGrid item xs={4}>
					<NavBarSection
						direction="row"
						justifyContent="center"
						alignItems="center"
					>
						{domain.data?.origin && (
							<Link href={MainDomains[domain.data.origin]}>
								{theme.themeName === "ahotu" ? (
									<Box py="1rem">
										<AhotuLogo />
									</Box>
								) : (
									<Stack justifyContent="center" alignItems="center">
										<WMLogo style={{ width: "60%" }} />
									</Stack>
								)}
							</Link>
						)}
					</NavBarSection>
				</CSSGrid>
				{theme.themeName === "worldsmarathons" && (
					<CSSGrid item xs={4}>
						<NavBarSection justifyContent="flex-end">
							{user ? (
								<UserMenu
									name={user.name}
									avatarImageSrc={user.profilePictureSrc}
								/>
							) : (
								<>
									<NavBarItem
										disableRipple
										disableElevation
										onClick={onSignupClick}
									>
										{t("signup")}
									</NavBarItem>
									<NavBarItem
										disableRipple
										disableElevation
										onClick={onLoginClick}
									>
										{t("login")}
									</NavBarItem>
								</>
							)}
						</NavBarSection>
					</CSSGrid>
				)}
			</CSSGrid>
		</StyledNavigationBar>
	);
}

export default DesktopNavigationBar;
