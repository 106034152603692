import { styled } from "@mui/material/styles";
import { Button, Stack } from "@mui/material";
import { wmPalette } from "theme/WSGTheme";

export const NavBarItem = styled(Button)(() => ({
	color: wmPalette.black["90"],
	paddingInline: 0,
}));

export const NavBarSection = styled(Stack)`
	flex-direction: row;
	padding-block: 0.5rem;
	gap: 1rem;
`;
