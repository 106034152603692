import { Skeleton, Stack } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useOrder from "services/Order/useOrder";
import { TotalLabel } from "./PaymentSummaryTotal.styles";

interface PaymentSummaryTotalProps {
	total: string;
}

const PaymentSummaryTotal = memo(function SummaryTotal({
	total,
}: PaymentSummaryTotalProps) {
	const { t } = useTranslation("common");
	const { orderId } = useParams();
	const { isLoading } = useOrder(orderId);
	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center">
			<TotalLabel id="total-label" variant="body2">
				{t("total.summary.label")}
			</TotalLabel>
			{isLoading ? (
				<Skeleton variant="rectangular" width="40%" />
			) : (
				<TotalLabel aria-labelledby="total-label" variant="body2">
					{total}
				</TotalLabel>
			)}
		</Stack>
	);
});

export default PaymentSummaryTotal;
