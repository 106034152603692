import { useCreditService } from ".";
import { IGetUserCreditsResponse } from "./UserCredits.types";
import { SWRRequestKeys } from "services/common/constants";
import { useErrorHandler } from "react-error-boundary";
import { Nullable } from "types/common/global";
import useSWR from "swr";
import { useAuthentication } from "contexts/AuthenticationProvider/AuthenticationProvider";

const useUserCredits = () => {
	const { getUserCredits } = useCreditService();
	const { isLoggedIn, user } = useAuthentication();

	const shouldGetCredits = isLoggedIn && Boolean(user?.token);

	const modifiers = useSWR<Nullable<IGetUserCreditsResponse>>(
		shouldGetCredits ? SWRRequestKeys.RemainingUserCredits : null,
		getUserCredits
	);

	useErrorHandler(modifiers.error);

	return modifiers;
};

export default useUserCredits;
