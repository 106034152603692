import { Box, Collapse, SxProps, Theme } from "@mui/material";
import { MainCheckoutRoutes } from "App";
import PayPalPaymentForm from "components/Checkout/PayPalPayment";
import { usePageControlContext } from "contexts/PageControlProvider/PageControlProvider";
import { usePayment } from "contexts/PaymentProvider";
import { MouseEvent } from "react";
import { useLocation, useParams } from "react-router-dom";
import useOrder from "services/Order/useOrder";
import { MainCheckoutLayoutParams, PageId } from "types/common/pages";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import { useTranslation } from "react-i18next";
import {
	StyledContinueButton,
	continueButtonError,
} from "./ContinueButton.styles";
import useDomain from "hooks/useDomain";
import { Typography } from "@mui/material";

interface ContinueButtonProps {
	onClick?(e: MouseEvent<HTMLButtonElement>): void;
	sx?: SxProps<Theme>;
}

function ContinueButton({ onClick, sx }: ContinueButtonProps) {
	const { continueBtnProps } = usePageControlContext();
	const { expandedOption, isCurrencySupported } = usePayment();
	const { pathname } = useLocation();
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const { data: order } = useOrder(orderId);
	const isPaymentsPage = pathname.includes(`/${MainCheckoutRoutes.Payment}`);
	const {
		isClaimSpotRoute,
		MainRoutePatterns: { isSelectTicketPage },
	} = useCheckoutRouteMatch();
	const { t } = useTranslation("common");
	const domain = useDomain();
	const { pageControlState } = usePageControlContext();

	const handleLoadingButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onClick?.(e);
	};

	const getButtonText = () => {
		if (isClaimSpotRoute) {
			return t("acceptInvitation.summary.button");
		}
		if (isPaymentsPage) {
			return t("paySecurely.summary.button");
		}
		if (isSelectTicketPage) {
			return t("next.summary.button");
		}

		return t("continue.summary.button_ahotu");
	};

	return (
		<span>
			{pageControlState?.error?.message &&
				pageControlState?.error?.type === "button" && (
					<Typography
						variant="body1"
						component="span"
						color={(theme) => theme.palette.error.main}
						align="center"
						sx={continueButtonError}
					>
						{pageControlState?.error?.message}
					</Typography>
				)}
			<Collapse in={expandedOption === "paypal" && isCurrencySupported.current}>
				<PayPalPaymentForm isSupported={isCurrencySupported.current} />
			</Collapse>
			<Collapse in={expandedOption === "stripe" || !isPaymentsPage}>
				<StyledContinueButton
					data-theme={domain.data?.origin}
					loading={continueBtnProps.isLoading}
					endIcon={<Box />}
					onClick={handleLoadingButtonClick}
					variant="contained"
					disabled={
						order?.orderStateSequence === PageId.Payment
							? undefined
							: continueBtnProps.isDisabled
					}
					fullWidth
					sx={sx}
				>
					{getButtonText()}
				</StyledContinueButton>
			</Collapse>
		</span>
	);
}

export default ContinueButton;
