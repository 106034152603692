import { Box, styled, SxProps, Theme } from "@mui/material";

export const primaryColorText: SxProps<Theme> = {
	color: (theme: Theme) => theme.palette.primary.main,
};
export const SummaryImage = styled(Box)(({ theme }) => ({
	top: 0,
	backgroundPosition: "50%",
	backgroundRepeat: "no-repeat",
	backgroundSize: "cover",
	flexGrow: 0,
	marginBottom: "1rem",
	width: "90%",
	height: "clamp(175px, 20vmin, 180px)",
	marginInline: "auto",
	borderRadius: theme.themeName === "ahotu" ? "0" : "8px 8px 0 0",
	[theme.breakpoints.up("md")]: {
		position: "relative",
		width: "100%",
		height: "clamp(120px, 20vmin, 180px)",
		zIndex: 0,
	},
}));

export const SummaryContentContainer = styled(Box)`
	padding: 0.5rem 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const addParticipantButtonSx: SxProps<Theme> = {
	...primaryColorText,
	"& .MuiListItemIcon-root": {
		minWidth: "2rem",
	},
};
