import { Container, Stack, styled } from "@mui/material";

export const SettingsContentContainer = styled(Container)`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	max-height: 100vh;
	overflow: hidden;
`;

export const SettingsContentContainerHeader = styled(Stack)`
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	padding-block: 1rem;
	border-bottom: 1px solid #f3f5f6;
`;

interface SettingsViewItemButtonProps {
	active?: boolean;
}

export const SettingsViewItemButton = styled("button", {
	shouldForwardProp(propName) {
		return propName !== "active";
	},
})<SettingsViewItemButtonProps>(({ active = false }) => ({
	display: "flex",
	backgroundColor: "transparent",
	border: "none",
	padding: "1rem 0.25rem",
	cursor: "pointer",
	fontWeight: active ? "bold" : "normal",
	fontSize: "1rem",
}));

export const OverflowContainer = styled("div")({
	overflowY: "scroll",
});
