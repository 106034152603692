import { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps extends PropsWithChildren {
	isAllowed: boolean;
	redirectPath: string;
}

function ProtectedRoute({
	isAllowed,
	redirectPath,
	children,
}: ProtectedRouteProps) {
	if (!isAllowed) {
		return <Navigate to={`../${redirectPath}`} />;
	}

	return <>{children ? children : <Outlet />}</>;
}

export default ProtectedRoute;
