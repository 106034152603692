/* eslint-disable prettier/prettier */
import i18n from "i18next";
import BrowserLanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { languages } from "types/common/models/languages";

const apiKey = process.env["REACT_APP_NEXUS_KEY"];
const nexusVersion = process.env["REACT_APP_NEXUS_VERSION"];
const i18nexusVersioned = `https://cdn.i18nexus.com/versions/${nexusVersion}/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;
const i18nexusLatest = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18n
  .use(HttpBackend)
  .use(BrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        "localStorage",
        "navigator",
        "querystring",
        "subdomain",
        "cookie",
        "sessionStorage",
        "htmlTag",
        "path"
      ]
    },
    fallbackLng: "en",
    ns: [
      "personal-details",
      "payment-page",
      "add-on",
      "team",
      "confirmation",
      "dialogs",
      "common",
      "authentication",
      "labels",
      "select-ticket"
    ],
    nsSeparator: ":",
    defaultNS: "default",
    debug: process.env["NODE_ENV"] === "development",

    supportedLngs: languages.map((l) => l.value) as string[],

    backend: {
      loadPath:
        process.env["NODE_ENV"] === "development"
          ? i18nexusLatest
          : i18nexusVersioned
    }
  });

export default i18n;
