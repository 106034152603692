import { ApiErrorCodes } from "types/common/enums";

export interface IApiError {
	code: ApiErrorCodes;
	message: string | string[];
}

class ApiError implements IApiError {
	code: ApiErrorCodes;
	message: string;
	requestUrl: string;
	payload: string;
	isAxiosError: boolean;

	constructor(
		statusCode: ApiErrorCodes,
		messages: string | string[],
		requestUrl: string,
		payload: string,
		isAxiosError: boolean
	) {
		this.code = statusCode;
		this.message =
			typeof messages === "string" ? (messages as string) : messages[0];
		this.requestUrl = requestUrl;
		this.payload = payload;
		this.isAxiosError = isAxiosError;
	}

	static isApiError(e: any): e is ApiError {
		return (e as ApiError).code !== undefined;
	}
}

export default ApiError;
