import { SxProps, Theme } from "@mui/material";
import AhotuTheme from "theme/AhotuTheme";
import { wmPalette } from "theme/WSGTheme";

export const orderEventSummarySx: SxProps<Theme> = {
	backgroundColor: (theme) => theme.palette.background.paper,
};

export const orderSummaryEventDetailsSx: SxProps<Theme> = {
	paddingBlock: "1rem",
	width: "100%",
	justifyContent: "center",
	display: "flex",
	color: (theme) =>
		theme.themeName === "worldsmarathons"
			? wmPalette.grey[90]
			: AhotuTheme.AhotuColorPalette.black.soft,
	".MuiTypography-root": {
		textAlign: "center",
		marginInline: "auto",

		"&:nth-of-type(1)": {
			minWidth: "150px",
		},

		"&:nth-of-type(2)": {
			minWidth: "250px",
		},
	},
};
