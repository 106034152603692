import { SxProps } from "@mui/material";

export const gridContainerStaticSx: SxProps = {
	display: "grid",
	width: "100%",
};

export const gridItemStaticSx: SxProps = {
	gridColumn: "auto",
};
