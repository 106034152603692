import { useErrorHandler } from "react-error-boundary";
import { Event } from "services/common/models/event";
import {
	configureDefaultActions,
	ServiceActionDefaults,
} from "services/common/utils/hookUtils";
import { useEventService } from "services/Event";
import { useCurrency } from "services/Localization";
import useSWR from "swr";

export const useEventFromOrder = (
	eventId?: string,
	actions?: ServiceActionDefaults
) => {
	const { getEvent } = useEventService();
	const currency = useCurrency();
	const modifiers = useSWR<Event>(
		eventId && currency && (() => [eventId, currency]),
		(key) => getEvent(key[0])
	);
	useErrorHandler(modifiers.error);

	configureDefaultActions(modifiers, actions);

	return modifiers;
};

export default useEventFromOrder;
