import { Box, ListItem, Stack, Typography } from "@mui/material";
import { Order } from "services/common/models/order";
import { useTranslation } from "react-i18next";
import TeamInfoForm from "types/models/TeamInfo/teamInfoForm";
import TeamInfoSummaryItem from "components/Checkout/TeamInfoSummaryItem";
import useCheckoutRouteMatch from "hooks/useRouteCheck";

interface TeamMemberListProps {
	order: Order;
	hidden?: boolean;
	formParticipants: TeamInfoForm["members"];
}

const TeamMemberList = ({
	order,
	hidden = false,
	formParticipants,
}: TeamMemberListProps) => {
	const { t } = useTranslation("common");
	const { MainRoutePatterns } = useCheckoutRouteMatch();
	const isTeamTicket = order.teamTicket;

	if (
		hidden ||
		!isTeamTicket ||
		((!formParticipants || formParticipants.length === 0) &&
			(order.participants.length < 1 || MainRoutePatterns.isTeamInfoPage))
	) {
		return null;
	}

	return (
		<Box>
			<>
				<ListItem disableGutters>
					<Typography fontWeight="bold">{t("teamMembers.label")}</Typography>
				</ListItem>
				<ListItem disableGutters disablePadding>
					<Stack gap={0} sx={{ width: "100%" }}>
						{(!MainRoutePatterns.isTeamInfoPage
							? order.participants
							: formParticipants
						)?.map((m, index) => (
							<TeamInfoSummaryItem
								key={index}
								index={index}
								fullName={m.fullName}
							/>
						))}
					</Stack>
				</ListItem>
			</>
		</Box>
	);
};

export default TeamMemberList;
