import { createTheme } from "@mui/material";
import common from "./common";

const AhotuColorPalette = {
	green: {
		5: "#eafff4",
		10: "#d4fde6",
		60: "#0cd693",
		70: "#00c282",
		80: "#01ad6f",
	},
	blue: {
		10: "#F1F9FC",
		20: "#DBEFF8",
		70: "#3DADDC",
		80: "#2394C3",
		100: "#191B52",
	},
	red: {
		20: "#fcd9e0",
		100: "#e40936",
	},
	black: {
		100: "#000000",
		soft: "#202228",
	},
	grey: {
		5: "#F9F9F9",
		10: "#F3F5F6",
		20: "#EBEEEF",
		30: "#D1D7DB",
		40: "#B3B8BC",
		50: "#7F8589",
		80: "#3F484D",
		90: "#353E44",
	},
	white: "#FFFFFF",
	purple: {
		100: "#8d29cd",
		20: "#ecdcf6",
		10: "#f8edff",
	},
	markup: {
		100: "#ff90c6",
		40: "#ff90c6",
		20: "#ffd6ea",
	},
};

const AhotuCommonHeadingStyles = {
	fontWeight: "700",
};

const AhotuCommonTextStyles = {
	body: {
		default: {
			fontWeight: 400,
			color: AhotuColorPalette.black.soft,
			fontSize: common.pxToRemCssValue(16),
		},
		semiBold: {
			fontWeight: 600,
			fontSize: common.pxToRemCssValue(16),
		},
		bold: {
			fontWeight: 700,
			fontSize: common.pxToRemCssValue(16),
		},
	},
	subtitle: {
		common: {
			fontSize: common.pxToRemCssValue(14),
		},
		default: {
			fontWeight: 400,
		},
		bold: {
			fontWeight: 700,
		},
	},
};

const ahotuTheme = createTheme({
	themeName: "ahotu",
	breakpoints: {
		values: common.breakpoints,
	},
	components: {
		MuiAlert: {
			variants: [
				{
					props: { severity: "error" },
					style: {
						backgroundColor: AhotuColorPalette.red[20],
						color: AhotuColorPalette.grey[90],
						[".MuiSvgIcon-root"]: {
							color: AhotuColorPalette.red[100],
						},
					},
				},
				{
					props: { severity: "warning" },
					style: {
						backgroundColor: AhotuColorPalette.purple[10],
						color: AhotuColorPalette.grey[90],
						[".MuiSvgIcon-root"]: {
							color: AhotuColorPalette.purple[100],
						},
					},
				},
			],
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0,
				sx: {
					borderRadius: 0,
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				disableRipple: true,
				disableFocusRipple: true,
				disableTouchRipple: true,
			},
			styleOverrides: {
				root: {
					"&:hover": {
						color: AhotuColorPalette.green[70],
					},
					"&.Mui-checked": {
						color: AhotuColorPalette.green[70],
					},
				},
			},
		},
		MuiDialog: {
			defaultProps: {
				sx: {
					[".MuiPaper-root"]: {
						borderRadius: "8px",
					},
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					"&.Mui-checked": {
						color: AhotuColorPalette.green[70],
					},
					"&:hover": {
						color: AhotuColorPalette.green[70],
						textDecoration: "none",
					},
				},
			},
		},
		MuiFormControl: {
			styleOverrides: {
				root: {
					".MuiFormGroup-root .MuiFormControlLabel-root:hover": {
						".MuiRadio-root": {
							color: AhotuColorPalette.green[70],
						},
					},
					"&:hover": {
						".MuiCheckbox-root": {
							color: AhotuColorPalette.green[70],
							"&.Mui-checked": {
								color: AhotuColorPalette.green[60],
							},
						},
						".Mui-disabled": {
							color: AhotuColorPalette.grey[30],
							textDecoration: "none",
							userSelect: "none",
						},
					},
					".Mui-disabled": {
						".MuiCheckbox-root, .MuiCheckbox-root.MuiChecked, .MuiTypography-root":
							{
								color: AhotuColorPalette.grey[30],
							},
						".MuiTypography-root": {
							color: AhotuColorPalette.grey[40],
						},
					},
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
				disableFocusRipple: true,
				disableRipple: true,
				disableTouchRipple: true,
			},
			styleOverrides: {
				root: {
					borderRadius: common.pxToRemCssValue(32),
					padding: `${common.pxToRemCssValue(8)} ${common.pxToRemCssValue(32)}`,
					fontWeight: 600,
					textTransform: "none",
					"&.Mui-disabled": {
						backgroundColor: AhotuColorPalette.grey[40],
						color: AhotuColorPalette.white,
					},
					"&.MuiLoadingButton-root": {
						":is(.MuiLoadingButton-loading)": {
							backgroundColor: AhotuColorPalette.black.soft,
						},
						".MuiLoadingButton-loadingIndicator": {
							color: AhotuColorPalette.white,
						},
					},
				},
				contained: {
					":hover": {
						backgroundColor: AhotuColorPalette.grey[80],
					},
					":focus, :focus-within": {
						backgroundColor: AhotuColorPalette.grey[90],
					},
				},
				outlined: {
					backgroundColor: AhotuColorPalette.white,
					border: `2px solid ${AhotuColorPalette.black.soft}`,
					":hover": {
						border: `2px solid ${AhotuColorPalette.black.soft}`,
						backgroundColor: AhotuColorPalette.grey[10],
					},
				},
				text: {
					backgroundColor: AhotuColorPalette.white,
					fontWeight: 400,
					":hover": {
						backgroundColor: AhotuColorPalette.grey[10],
					},
					"&.Mui-disabled": {
						backgroundColor: AhotuColorPalette.white,
						color: AhotuColorPalette.grey[30],
					},
				},
			},
			variants: [
				{
					props: { size: "small" },
					style: {
						padding: `${common.pxToRemCssValue(8)} ${common.pxToRemCssValue(
							24
						)}`,
						fontSize: common.pxToRemCssValue(16),
						lineHeight: common.pxToRemCssValue(20),
					},
				},
				{
					props: { variant: "contained", size: "medium" },
					style: {
						padding: `${common.pxToRemCssValue(8)} ${common.pxToRemCssValue(
							32
						)}`,
					},
				},
				{
					props: { variant: "contained", size: "small" },
					style: {
						padding: `${common.pxToRemCssValue(8)} ${common.pxToRemCssValue(
							24
						)}`,
						backgroundColor: AhotuColorPalette.grey[80],
					},
				},
			],
		},
		MuiTypography: {
			styleOverrides: {
				h1: {
					fontSize: common.pxToRemCssValue(24),
					...AhotuCommonHeadingStyles,
				},
				h2: {
					fontSize: common.pxToRemCssValue(20),
					...AhotuCommonHeadingStyles,
				},
				h3: {
					fontSize: common.pxToRemCssValue(18),
					...AhotuCommonHeadingStyles,
				},
				h4: {
					fontSize: common.pxToRemCssValue(16),
					...AhotuCommonHeadingStyles,
				},
				h5: {
					fontSize: common.pxToRemCssValue(14),
					...AhotuCommonHeadingStyles,
				},
				h6: {
					fontSize: common.pxToRemCssValue(12),
					...AhotuCommonHeadingStyles,
				},
				body1: {
					...AhotuCommonTextStyles.body.default,
				},
				body2: {
					...AhotuCommonTextStyles.body.bold,
				},
				subtitle1: {
					...AhotuCommonTextStyles.subtitle.common,
					...AhotuCommonTextStyles.subtitle.default,
				},
				subtitle2: {
					...AhotuCommonTextStyles.subtitle.common,
					...AhotuCommonTextStyles.subtitle.bold,
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					color: AhotuColorPalette.black.soft,
				},
			},
		},
	},
	palette: {
		contrastThreshold: 4.5,
		primary: {
			main: AhotuColorPalette.black.soft,
			dark: AhotuColorPalette.black.soft,
			light: AhotuColorPalette.green[60],
			contrastText: "#ffffff",
		},
		secondary: {
			main: AhotuColorPalette.green[70],
			dark: AhotuColorPalette.green[80],
			light: AhotuColorPalette.green[60],
			contrastText: "#ffffff",
		},
		tertiary: {
			main: AhotuColorPalette.blue[80],
			light: AhotuColorPalette.blue[70],
			dark: AhotuColorPalette.blue[100],
			contrastText: "#ffffff",
		},
		text: {
			primary: AhotuColorPalette.black.soft,
		},
		background: {
			default: AhotuColorPalette.grey[20],
		},
		error: {
			main: AhotuColorPalette.red[100],
			light: AhotuColorPalette.red[20],
		},
		neutral: {
			main: AhotuColorPalette.grey[90],
		},
		info: {
			main: AhotuColorPalette.black.soft,
		},
		common: {
			black: AhotuColorPalette.black.soft,
			white: AhotuColorPalette.white,
		},
		grey: {
			"100": AhotuColorPalette.grey[5],
			"200": AhotuColorPalette.grey[10],
			"300": AhotuColorPalette.grey[20],
			"400": AhotuColorPalette.grey[30],
			"500": AhotuColorPalette.grey[40],
			"600": AhotuColorPalette.grey[50],
			"700": AhotuColorPalette.grey[80],
			"800": AhotuColorPalette.grey[90],
			"900": AhotuColorPalette.grey[90],
		},
		warning: {
			main: AhotuColorPalette.purple[20],
		},
	},
	typography: {
		fontFamily: ["Inter", "Helvetica Neue", "Helvetica", "Arial"].join(","),
	},
});

export default { theme: ahotuTheme, AhotuColorPalette };
