import { MainRoutes } from "App";
import { useEffect, useState } from "react";
import { matchPath } from "react-router-dom";
import useOrder from "services/Order/useOrder";
import {
	CheckoutDomain,
	CheckoutDomainDisplayName,
	Domain,
} from "types/common/global";

interface DomainMap {
	origin: Domain;
	displayName: string;
}

const useDomain = () => {
	const [orderId, setOrderId] = useState<string>();
	const [domainMap, setDomainMap] = useState<DomainMap>();
	const { data, ...order } = useOrder(orderId);

	useEffect(() => {
		const matchedPath = Object.values(MainRoutes)
			.map((route) => matchPath({ path: route, end: false }, location.pathname))
			.find((r) => r !== null);

		if (matchedPath) {
			setOrderId(matchedPath.params.orderId);
		}
	}, []);

	useEffect(() => {
		if (data?.origin != null) {
			const domainName = CheckoutDomain[data.origin];
			setDomainMap({
				origin: domainName,
				displayName: CheckoutDomainDisplayName[domainName],
			});
		}
	}, [data?.origin]);

	return {
		isWorldsMarathons: domainMap?.origin === "worldsmarathons",
		data: domainMap,
		...order,
	};
};

export default useDomain;
