import { AxiosError } from "axios";
import TagManager from "react-gtm-module";
import useMutation from "swr/mutation";
import { MutationExtraArg } from "types/common/swr";
import {
	AnalyticsConstants,
	RecommendedEventRequest,
} from "types/services/analyticsService.types";
import { AnalyticsMutationKeys } from "..";
import useOrder from "services/Order/useOrder";
import { useParams } from "react-router-dom";
import { MainCheckoutLayoutParams } from "types/common/pages";
import { customFlatMap } from "services/common/utils/arrayUtils";

const useRecommendedEventMutation = () => {
	const defaultCurrency = AnalyticsConstants.TransactionCurrency;
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const order = useOrder(orderId, defaultCurrency);
	return useMutation<void, any, AnalyticsMutationKeys, RecommendedEventRequest>(
		"recommendedEvent",
		async (
			_: AnalyticsMutationKeys,
			{ arg }: MutationExtraArg<RecommendedEventRequest>
		) => {
			if (order.data?.convertToCurrency === defaultCurrency || arg.currency) {
				const orderCurrency = arg.currency ?? defaultCurrency;
				const orderValue = arg.value ?? order.data?.totalValueConverted;
				const orderItems =
					arg.items ??
					customFlatMap(order.data?.participants, (p) => {
						return p.orderLines.map((ol) => {
							if (ol.productType === "extra") {
								return {
									item_id: ol.productId,
									item_name: ol.productName,
									price: ol.priceConverted,
									quantity: 1,
									item_brand: p.ticket.productId,
								};
							}
							return {
								item_id: ol.productId,
								item_name: ol.productName,
								price: ol.priceConverted,
								quantity: 1,
							};
						});
					});
				if (orderValue === undefined || !orderItems || !arg.analyticsEvent) {
					throw new AxiosError(
						"Missing value or items or analyticsEvent",
						AxiosError.ERR_BAD_REQUEST
					);
				}

				const sessionStorageId = `${orderId}:${arg.analyticsEvent}`;
				const added = sessionStorage.getItem(sessionStorageId) || undefined;
				if (!added || arg.allowMultiple) {
					sessionStorage.setItem(sessionStorageId, "visited");
					TagManager.dataLayer({
						dataLayer: {
							items: undefined,
						},
					});
					if (arg.transaction_id) {
						const isFreeOrder =
							order.data && order.data.totalValueInCents === 0;
						TagManager.dataLayer({
							dataLayer: {
								event: arg.analyticsEvent,
								event_id: order.data?.eventId,
								currency: orderCurrency,
								value: orderValue,
								items: orderItems,
								transaction_id: arg.transaction_id,
								payment_type: isFreeOrder
									? "free-order"
									: order.data?.paymentMethod,
								revenue: orderValue * ((order.data?.commission ?? 0) / 100),
							},
						});
					} else {
						TagManager.dataLayer({
							dataLayer: {
								event: arg.analyticsEvent,
								event_id: order.data?.eventId,
								currency: orderCurrency,
								value: orderValue,
								items: orderItems,
								payment_type: order.data?.paymentMethod,
								transaction_id: order.data?.orderReference,
							},
						});
					}
				}
			}
		}
	);
};

export default useRecommendedEventMutation;
