import { Button, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MouseEvent, PropsWithChildren, useState } from "react";

interface MenuButtonProps extends PropsWithChildren {
	buttonId: string;
	menuId: string;
	menuItems: { text: string; onClick?(e: MouseEvent<HTMLElement>): void }[];
	hideDropdownIcon?: boolean;
	disableHoverEffect?: boolean;
	"aria-label"?: string;
	buttonSx?: SxProps<Theme>;
}

const MenuButton = ({
	children,
	buttonId,
	menuId,
	menuItems,
	hideDropdownIcon = false,
	"aria-label": ariaLabel,
	disableHoverEffect = false,
	buttonSx,
}: MenuButtonProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement>();
	const isMenuOpen = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	return (
		<>
			<Button
				id={buttonId}
				variant="text"
				aria-label={ariaLabel}
				endIcon={
					hideDropdownIcon ? undefined : (
						<KeyboardArrowDownIcon color="action" />
					)
				}
				disableRipple
				aria-controls={isMenuOpen ? menuId : undefined}
				aria-haspopup="true"
				aria-expanded={isMenuOpen ? "true" : undefined}
				onClick={handleClick}
				sx={
					disableHoverEffect
						? { ":hover": { background: "none" }, ...buttonSx }
						: buttonSx
				}
			>
				{children}
			</Button>
			<Menu
				id={menuId}
				anchorEl={anchorEl}
				open={isMenuOpen}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": buttonId,
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				sx={{ width: "100%" }}
			>
				{menuItems?.map((mi, i) => (
					<MenuItem key={i} onClick={mi.onClick}>
						{mi.text}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

export default MenuButton;
