import { ServiceActionDefaults } from "services/common/utils/hookUtils";

export type Domain = "ahotu" | "worldsmarathons";

export const CheckoutDomain: Record<number, Domain> = {
	0: "worldsmarathons",
	1: "ahotu",
};

export const CheckoutDomainDisplayName: Record<Domain, string> = {
	worldsmarathons: "World's Marathons",
	ahotu: "Ahotu",
};

export interface MenuOption {
	value: string | number;
	label?: string;
	i18nKey?: string;
}

export interface LocaleSettings {
	language?: string;
	currency?: string;
	measure?: string | number;
	modified: boolean;
}

export type Language = MenuOption;
export type Currency = MenuOption;
export type Measure = MenuOption;

export interface ServiceHookOptions {
	actions?: ServiceActionDefaults;
}

export type Nullable<T> = T | null | undefined;

export interface CurrencyDependent {
	currency?: string;
}

export interface DateParts {
	date?: string;
	year: string;
	month: string;
	day: string;
}

export interface GlobalReducerAction<
	ActionTypePattern,
	ActionPayloadType = Record<string, any>
> {
	type: ActionTypePattern;
	payload: ActionPayloadType;
}

export const RegExPatterns = {
	startWhitespace: /^\s/,
	nonLatin:
		/[^\u0000-\u007F\u00C0-\u024F\u1E00-\u1EFF\u0370-\u03FF\u1F00-\u1FFF]/,
	hasNumber: /\d+/g,
	time: /^((?!(0:00)|(00:00))0?[0-9]{2}:[0-5][0-9])$/g,
	lowercase: /[a-z]+/g,
	uppercase: /[A-Z]+/g,
	nonAlpha: /[^\w\s]+/g,
	nonWhitespace: /^\S+$/g,
	url:
		// eslint-disable-next-line
		/[-\w\u00A1-\uFFFF@:%._\+~#=]{1,256}\.[\w\u00A1-\uFFFF]{2,63}(\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))?/,
	email:
		// eslint-disable-next-line
		/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,
};
