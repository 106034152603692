import { Dispatch, createContext } from "react";
import {
	PageControlReducerAction,
	PageControlReducerState,
	SetSelectTicketErrorAction,
	SetTemporaryTotalPayloadAction,
	SetTicketCountAction,
} from "./PageControlContext.types";

export interface ContinueBtnProps {
	isLoading?: boolean;
	isDisabled?: boolean;
	message?: string;
}

export type ContinueBtnState = "disabled" | "loading" | "enabled";

export interface PageControlContextProps {
	continueBtnProps: ContinueBtnProps;
	setContinueBtnState(
		state: ContinueBtnState | "previous",
		message?: string
	): void;
	continueButtonState: ContinueBtnState;
	enableContinueBtn(): void;
	pageControlState?: PageControlReducerState;
	dispatch: Dispatch<PageControlReducerAction>;
}

export const pageControlContextDefaults: PageControlContextProps = {
	continueBtnProps: { message: "", isLoading: false, isDisabled: false },
	setContinueBtnState: () => ({}),
	continueButtonState: "disabled",
	enableContinueBtn: () => void 0,
	dispatch: () => void 0,
};

export const pageControlReducer = (
	state: PageControlReducerState,
	action: PageControlReducerAction
): PageControlReducerState => {
	switch (action.type) {
		case "SET_TEMP_TOTAL": {
			const tempTotalAction = action as SetTemporaryTotalPayloadAction;
			return {
				...state,
				temporaryTotal: tempTotalAction.payload.total,
			};
		}
		case "SET_TICKET_COUNT": {
			const ticketCountAction = action as SetTicketCountAction;
			return {
				...state,
				selectTicketState: {
					...ticketCountAction.payload,
				},
			};
		}

		case "SET_ERROR": {
			const setTicketErrorAction = action as SetSelectTicketErrorAction;
			return {
				...state,
				error: {
					message: setTicketErrorAction.payload.message,
					type: setTicketErrorAction.payload.type,
				},
			};
		}

		default:
			throw new Error(`Unhandled Action Type: ${action}`);
	}
};

const PageControlContext = createContext<PageControlContextProps>(
	pageControlContextDefaults
);

export default PageControlContext;
