import { Typography } from "@mui/material";
import { IconName } from "components/common/Icon/assets";
import Icon, { IconProps } from "components/common/Icon/Icon";
import { SettingsDialogItemButton } from "./SettingsDialogItem.styles";

type IconPropsWithoutName = Omit<IconProps, "name">;

interface SettingsDialogItemProps {
	startIcon?: IconName;
	endIcon?: IconName;
	title: string;
	onClick?: () => void;
	IconProps?: {
		startIcon?: IconPropsWithoutName;
		endIcon?: IconPropsWithoutName;
	};
	disabled?: boolean;
}

const SettingsDialogItem = ({
	startIcon,
	endIcon,
	title,
	onClick,
	IconProps,
	disabled,
}: SettingsDialogItemProps) => {
	const endIconName: IconName | undefined = (() => {
		if (onClick) {
			return "arrowRight";
		}

		return endIcon;
	})();

	return (
		<SettingsDialogItemButton
			component="button"
			onClick={!disabled ? onClick : undefined}
			aria-label={title}
			data-disabled={disabled}
		>
			{startIcon && <Icon name={startIcon} {...IconProps?.startIcon} />}
			<Typography>{title}</Typography>
			{endIconName && <Icon name={endIconName} {...IconProps?.endIcon} />}
		</SettingsDialogItemButton>
	);
};

export default SettingsDialogItem;
