import axios, { AxiosResponse } from "axios";
import { PropsWithChildren, useMemo } from "react";
import {
	ILoginForm,
	RegistrationBody,
	ResetPasswordBody,
} from "services/Authentication/types";
import { axiosErrorHandler } from "services/AxiosErrorHandling";
import { ApiServices, ServiceBaseUrls } from "services/common/constants";
import Contextualizer from "services/ServiceContextualizer";
import ApiError from "types/models/ApiError";
import {
	LoginResponse,
	RegistrationResponse,
} from "types/models/Authentication";

const AuthenticationContext = Contextualizer.createContext(
	ApiServices.AuthenticationService
);

interface IAuthenticationService {
	register(body: RegistrationBody): Promise<RegistrationResponse | ApiError>;
	login(body: ILoginForm): Promise<LoginResponse | ApiError>;
	checkAccountConfirmationStatus(email: string): Promise<boolean | ApiError>;
	resetPassword(email: string): Promise<string | ApiError>;
}

function AuthenticationService({ children }: PropsWithChildren) {
	const authService = useMemo<IAuthenticationService>(
		() => ({
			async register(body) {
				try {
					const res = await axios.post(
						`${ServiceBaseUrls.UserAccount}/register`,
						body
					);
					return res.data;
				} catch (e) {
					const apiError = axiosErrorHandler(axios).getErrorMessage(e);
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: `${ServiceBaseUrls.UserAccount}/register`,
							payload: JSON.stringify(body),
						});
					}
					return apiError;
				}
			},
			async login(body) {
				try {
					const res = await axios.post(
						`${ServiceBaseUrls.UserAccount}/login`,
						body
					);
					return res.data;
				} catch (e) {
					const apiError = axiosErrorHandler(axios).getErrorMessage(e);
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: `${ServiceBaseUrls.UserAccount}/login`,
							payload: JSON.stringify(body),
						});
					}
					return apiError;
				}
			},
			async checkAccountConfirmationStatus(email) {
				try {
					const res = await axios.post<boolean>(
						`${ServiceBaseUrls.UserAccount}/verify-confirmemail?userId=${email}`
					);
					return res.data;
				} catch (e) {
					const apiError = axiosErrorHandler(axios).getErrorMessage(e);
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: `${ServiceBaseUrls.UserAccount}/verify-confirmemail?userId=${email}`,
						});
					}
					return apiError;
				}
			},
			async resetPassword(email) {
				try {
					const res = await axios.post<
						string,
						AxiosResponse<string>,
						ResetPasswordBody
					>(`${ServiceBaseUrls.UserAccount}/send-password-link`, { email });
					return res.data;
				} catch (e) {
					const apiError = axiosErrorHandler(axios).getErrorMessage(e);
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: `${ServiceBaseUrls.UserAccount}/send-password-link`,
							payload: email,
						});
					}
					return apiError;
				}
			},
		}),
		[]
	);

	return (
		<AuthenticationContext.Provider value={authService}>
			{children}
		</AuthenticationContext.Provider>
	);
}

export const useAuthenticationService = () =>
	Contextualizer.use<IAuthenticationService>(ApiServices.AuthenticationService);

export default AuthenticationService;
