import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	IconButton,
	Stack,
	TextField,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import {
	expandIconSX,
	addCouponAccordionSx,
	addCouponITabTextSx,
} from "./CouponCodeSection.styles";
import CSSGrid from "components/common/CSSGrid";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useOrder from "services/Order/useOrder";
import { MainCheckoutLayoutParams } from "types/common/pages";
import { object, string } from "yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { usePaymentModifier } from "components/Checkout/PaymentSummary/PaymentModifierProvider";
import { useEffect, useState } from "react";
import {
	useAddCouponCodeMutation,
	useRemoveCouponCodeMutation,
} from "services/Order/mutations";
import { LoadingButton } from "@mui/lab";
import { usePayment } from "contexts/PaymentProvider";
import axios from "axios";
import { axiosErrorHandler } from "services/AxiosErrorHandling";

interface CouponCodeSection {
	showAppliedCoupon?: boolean;
}

const CouponCodeSection = ({
	showAppliedCoupon = false,
}: CouponCodeSection) => {
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("md")
	);
	const { themeName } = useTheme();
	const { t } = useTranslation(["common", "payment-page"]);
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const {
		data: order,
		mutate: mutateOrder,
		isLoading: orderIsLoading,
	} = useOrder(orderId);
	const { isUsingCredits, openPopover, closePopover, errorPopoverElement } =
		usePaymentModifier();
	const { isPaymentProcessing } = usePayment();
	const [hasITabSelected, setHasITabSelected] = useState(false);
	const addCoupon = useAddCouponCodeMutation();
	const removeCoupon = useRemoveCouponCodeMutation();

	async function handleAddCoupon(
		values: { couponCode: string },
		helpers: FormikHelpers<{ couponCode: string }>
	) {
		if (orderId && values) {
			try {
				const updatedOrder = await addCoupon.trigger({
					orderId,
					couponCode: values.couponCode,
				});
				if (updatedOrder) {
					mutateOrder(updatedOrder, { revalidate: false });
				}
			} catch (error) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(error);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: apiError.requestUrl,
						orderId: orderId,
						payload: values.couponCode,
					});
				}
				helpers.setFieldError("couponCode", t("applyCoupon.error_invalid"));
			}
		}
	}

	async function handleRemoveCoupon(
		resetForm: FormikHelpers<any>["resetForm"]
	) {
		if (order) {
			const didRemoveCoupon = await removeCoupon.trigger({
				orderId,
			});
			if (didRemoveCoupon) {
				resetForm();
				mutateOrder();
			}
		}
	}

	function handleChange(
		handleChange: { (): void; (): void },
		errors: FormikErrors<{ couponCode: string }>,
		setFieldError: () => void
	) {
		if (errors) {
			setFieldError();
		}
		handleChange();
	}

	function checkITabSelected() {
		const hasAnyParticipantSelectedItab = order?.participants.some(
			(p) => p.hasITabSelected
		);
		if (typeof hasAnyParticipantSelectedItab === "boolean") {
			setHasITabSelected(hasAnyParticipantSelectedItab);
		}
	}

	const couponForm = useFormik({
		initialValues: { couponCode: "" },
		validationSchema: object({
			couponCode: string().required(t("applyCoupon.error")),
		}),
		onSubmit: handleAddCoupon,
		validateOnChange: false,
	});

	useEffect(() => {
		if (isUsingCredits || location.pathname) couponForm.resetForm();
		checkITabSelected();
	}, [isUsingCredits, location.pathname, hasITabSelected, order?.participants]);

	if (!order) {
		return null;
	}

	/* If coupon code object exists show the discount and button to remove it */
	if (showAppliedCoupon) {
		const isCloseIconLoading = removeCoupon.isMutating || orderIsLoading;
		return (
			<Stack gap={1} my={2}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography>{t("common:discount.summary.couponLabel")}</Typography>
					{isCloseIconLoading ? (
						<LoadingButton
							loading
							sx={{ paddingBlock: 0, paddingInline: 1, minWidth: 0 }}
						/>
					) : (
						<IconButton
							sx={{ padding: 0 }}
							onClick={() => handleRemoveCoupon(couponForm.resetForm)}
							disabled={isPaymentProcessing}
						>
							<CloseIcon />
						</IconButton>
					)}
				</Stack>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography fontWeight="700">{order.coupon.code}</Typography>
					<Typography color="red">
						{/* TODO: This should be part of the "total" label and not the right side value */}
						{/* Only included here because of the absence of "discountValue" property  */}
						-{order.coupon.value}
						{order.coupon.percentage ? "%" : ` ${order.convertToCurrency}`}
					</Typography>
				</Stack>

				{hasITabSelected && (
					<Typography sx={addCouponITabTextSx}>
						{t("common:discount.summary.iTabLabel")}
					</Typography>
				)}
			</Stack>
		);
	}

	const isPopoverVisible = isUsingCredits && Boolean(errorPopoverElement);
	const isApplyDisabled =
		isUsingCredits ||
		couponForm.values.couponCode === "" ||
		addCoupon.isMutating ||
		isPaymentProcessing;
	const isApplyLoading = addCoupon.isMutating || orderIsLoading;

	/* If coupon code object doesn't exist show the form */
	return (
		<Accordion
			elevation={0}
			disableGutters
			sx={addCouponAccordionSx}
			onMouseEnter={isUsingCredits ? openPopover : undefined}
			onMouseLeave={isUsingCredits ? closePopover : undefined}
			disabled={isUsingCredits}
			aria-haspopup={isUsingCredits ? "true" : undefined}
			aria-owns={isPopoverVisible ? errorPopoverElement?.id : undefined}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon sx={expandIconSX} />}>
				{t("common:applyCoupon.summary.label")}
			</AccordionSummary>
			<AccordionDetails sx={{ zIndex: 1, position: "relative" }}>
				<Box
					component="form"
					onSubmit={couponForm.handleSubmit}
					sx={{ zIndex: 1, position: "relative" }}
				>
					<CSSGrid alignItems="flex-start" gap={1}>
						<CSSGrid item xs={12} sm={8} md={9}>
							<TextField
								name="couponCode"
								variant="outlined"
								size={isMobile ? "medium" : "small"}
								placeholder={t("applyCoupon.summary.placeholder")}
								value={couponForm.values.couponCode}
								onChange={(e) =>
									handleChange(
										() => couponForm.handleChange(e),
										couponForm.errors,
										() => couponForm.setFieldError("couponCode", "")
									)
								}
								helperText={couponForm.errors.couponCode}
								error={Boolean(couponForm.errors.couponCode)}
								fullWidth
								disabled={isUsingCredits || isPaymentProcessing}
							/>
						</CSSGrid>
						<CSSGrid item xs={12} sm={4} md={3}>
							<LoadingButton
								title={
									isApplyDisabled
										? t("payment-page:paymentModfier.coupon.apply.disabled")
										: t("common:apply")
								}
								variant={themeName === "ahotu" ? "text" : "primary-text-dark"}
								type="submit"
								fullWidth
								size="large"
								disabled={isApplyDisabled}
								loading={isApplyLoading}
							>
								{t("common:apply")}
							</LoadingButton>
						</CSSGrid>
					</CSSGrid>
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

export default CouponCodeSection;
