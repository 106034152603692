import { SxProps, Theme } from "@mui/material";
import AhotuTheme from "theme/AhotuTheme";
import wsgTheme from "theme/WSGTheme";

const footerSx: SxProps<Theme> = {
	padding: "2.5rem 1rem",
	fontSize: ".875rem",
	marginBlockStart: "1rem",
	backgroundColor: (theme) => theme.palette.grey[300],
	"&[data-theme=ahotu]": {
		[AhotuTheme.theme.breakpoints.down("md")]: {
			["&[data-show-buffer=true]"]: {
				mb: "10.5rem",
			},
		},
	},
	"&[data-theme=worldsmarathons]": {
		[wsgTheme.breakpoints.down("md")]: {
			["&[data-show-buffer=true]"]: {
				mb: "13.5rem",
			},
		},
	},
};

const footerDisclaimerTextSx: SxProps<Theme> = {
	marginX: "auto",
	textAlign: "center",
	color: (theme) => theme.palette.grey[600],
	fontSize: "inherit",
};

const footerCopyrightTextSx: SxProps<Theme> = {
	marginX: "auto",
	textAlign: "center",
	color: (theme) => theme.palette.grey[700],
	fontSize: "inherit",
};

export default {
	footerDisclaimerTextSx,
	footerCopyrightTextSx,
	footerSx,
};
