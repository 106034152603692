import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function DialogSuspenseFallback() {
	return (
		<Backdrop
			open
			sx={{
				backgroundColor: "white",
				position: "fixed",
				top: 0,
				left: 0,
				zIndex: 100,
				width: "100vw",
				height: "100vh",
			}}
		>
			<CircularProgress />
		</Backdrop>
	);
}

export default DialogSuspenseFallback;
