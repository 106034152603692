import { Avatar, Box, Link, Stack, Typography, useTheme } from "@mui/material";
import SettingsDialog from "components/Checkout/SettingsDialog";
import {
	NavigationBarIcon,
	StyledNavigationBar,
} from "../NavigationBar.styles";
import { useState } from "react";
import { NavigationBar } from "components/NavigationBar/NavigationBar";
import { ReactComponent as AhotuLogo } from "assets/ahotu-logo.svg";
import { ReactComponent as WMLogo } from "assets/wm-logo.svg";
import Icon from "components/common/Icon/Icon";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "contexts/AuthenticationProvider/AuthenticationProvider";
import MenuButton from "components/common/MenuButton";
import useDomain from "hooks/useDomain";
import { MainDomains } from "types/services/navigation.types";

type MobileNavigationBarProps = Omit<NavigationBar, "onSignupClick">;

function MobileNavigationBar({ onLoginClick }: MobileNavigationBarProps) {
	const [isPageSettingDialogOpen, setIsPageSettingDialogOpen] = useState(false);
	const theme = useTheme();
	const { t } = useTranslation(["common"]);
	const { isLoggedIn, user, handleLogout } = useAuthentication();
	const domain = useDomain();

	const togglePageSettingsDialog = () => {
		setIsPageSettingDialogOpen((prevState) => !prevState);
	};

	const userInitials = (() => {
		const nameSplit = user?.name?.split(" ");
		const firstNameInitial = nameSplit?.[0]?.[0];
		const lastNameInitial = nameSplit?.[1]?.[0];
		return `${firstNameInitial}${lastNameInitial}`;
	})();

	return (
		<StyledNavigationBar>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				{domain.data?.origin && (
					<Link href={MainDomains[domain.data.origin]}>
						<Box py="1rem">
							{theme.themeName === "ahotu" ? (
								<AhotuLogo style={{ minWidth: "128px" }} />
							) : (
								<WMLogo style={{ width: "60%" }} />
							)}
						</Box>
					</Link>
				)}
				<Stack direction="row" gap={1}>
					{theme.themeName === "worldsmarathons" && !isLoggedIn && (
						<NavigationBarIcon onClick={onLoginClick}>
							<Typography variant="body1" fontSize=".875rem">
								{t("common:login")}
							</Typography>
						</NavigationBarIcon>
					)}
					{theme.themeName === "worldsmarathons" && isLoggedIn && (
						<MenuButton
							buttonId="logged-in-user"
							menuId="logged-in-context-menu"
							menuItems={[{ text: "Logout", onClick: handleLogout }]}
							hideDropdownIcon
							buttonSx={{ paddingInline: 1, minWidth: 0 }}
						>
							{user && user.profilePictureSrc && (
								<Avatar
									src={user?.profilePictureSrc}
									alt={user?.name}
									sx={{ height: "24px", width: "24px" }}
								/>
							)}
							{user && !user.profilePictureSrc && (
								<Avatar
									sx={{
										height: "24px",
										width: "24px",
										fontSize: ".75rem",
										fontWeight: 500,
										bgcolor: "primary.main",
									}}
								>
									{userInitials}
								</Avatar>
							)}
						</MenuButton>
					)}
					<NavigationBarIcon onClick={togglePageSettingsDialog}>
						<Icon name="settings" />
					</NavigationBarIcon>
				</Stack>
			</Stack>
			<SettingsDialog
				isOpen={isPageSettingDialogOpen}
				onClose={togglePageSettingsDialog}
			/>
		</StyledNavigationBar>
	);
}

export default MobileNavigationBar;
