import { useEffect, useMemo, useState } from "react";

export const LocalStorageKeys = {
	StorageTest: "skypeStorageTest",
	Currency: "currency",
	Language: "i18nextLng",
	Measure: "measure",
} as const;
export type LocalStorageKeys =
	typeof LocalStorageKeys[keyof typeof LocalStorageKeys];

interface IUseLocalStorage {
	value?: string;
	setValue(value: any): void;
}

const useLocalStorage = (key: LocalStorageKeys): IUseLocalStorage => {
	const [storageValue, setStorageValue] = useState<string>();
	function isLocalStorageEnabled() {
		const value = "skypeStorageTest";
		try {
			localStorage.setItem(value, value);
			localStorage.removeItem(value);
			return true;
		} catch (e) {
			return false;
		}
	}

	useEffect(() => {
		if (!navigator.cookieEnabled || !isLocalStorageEnabled()) return;

		const value = localStorage.getItem(key);
		if (value) {
			setStorageValue(value);
		}
	}, [key]);

	return useMemo(
		() => ({
			value: storageValue,
			setValue: (value: any) => localStorage.setItem(key, value),
		}),
		[storageValue, key]
	);
};

export default useLocalStorage;
