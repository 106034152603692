import { PropsWithChildren, useMemo } from "react";
import TagManager from "react-gtm-module";
import { ApiServices } from "services/common/constants";
import Contextualizer from "services/ServiceContextualizer";
import {
	AnalyticsConstants,
	AnalyticsItem,
	GAAnalyticsEvent,
	IAnalyticsService,
	TagManagerData,
} from "types/services/analyticsService.types";

export type AnalyticsMutationKeys = "customEvent" | "recommendedEvent";

const AnalyticsServiceContext = Contextualizer.createContext(
	ApiServices.AnalyticsService
);

interface AnalyticsServiceProps extends PropsWithChildren {}

function AnalyticsService({ children }: AnalyticsServiceProps) {
	const analyticsService = useMemo<IAnalyticsService>(
		() => ({
			async reportNewRegistration(order) {
				const gtmData: TagManagerData = {
					event: GAAnalyticsEvent.NewRegistration,
					transactionId: order.orderReference,
					transactionCurrency: AnalyticsConstants.TransactionCurrency,
					transactionAffiliation: order.marathonId,
					transactionTotal: order.applicationFeeInCentsForTracking / 100,
					transactionProducts: [],
					content_ids: [order.marathonId],
					content_type: "product",
				};

				const productItems: AnalyticsItem = {};

				for (const participant of order.participants) {
					participant.orderLines.forEach((o) => {
						const { productId, productName, productType, price } = o;
						if (productItems[productId]) {
							productItems[productId].quantity++;
						} else {
							productItems[productId] = {
								sku: productId,
								name: productName,
								category: productType,
								price: price * (order.commission / 100),
								quantity: 1,
							};
						}
					});
				}

				gtmData.transactionProducts.push(Object.values(productItems));

				TagManager.dataLayer({ dataLayer: gtmData });
			},
			async reportEventToAnalytics(event: string, orderId: string) {
				const data = {
					event,
				};
				const sessionStorageId = `${orderId}:${event}`;
				const added = sessionStorage.getItem(sessionStorageId) || undefined;
				if (!added) {
					sessionStorage.setItem(sessionStorageId, "visited");
					TagManager.dataLayer({
						dataLayer: data,
					});
				}
			},
		}),
		[]
	);

	return (
		<AnalyticsServiceContext.Provider value={analyticsService}>
			{children}
		</AnalyticsServiceContext.Provider>
	);
}

export const useAnalyticsService = () =>
	Contextualizer.use<IAnalyticsService>(ApiServices.AnalyticsService);

export default AnalyticsService;
