import {
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	Button,
	Link,
	styled,
} from "@mui/material";
import Dialog from "components/Checkout/common/Dialog";
import AhotuTheme from "theme/AhotuTheme";
import { ConfirmContentType } from "./ConfirmationDialogContext";

export const StyledConfirmationDialog = styled(Dialog)<{
	variant?: "critical" | "default" | "vertical";
	isMobile?: boolean;
}>`
	box-sizing: content-box;
	.MuiDialog-paper {
		max-width: ${(props) => (props.isMobile ? "21rem" : "25rem")};
		padding: 2rem;
		height: min-content;
		margin: 32px;
		border-radius: 0.5rem;
	}
`;

export const ConfirmationTitle = styled(DialogTitle)<{
	modalType?: "critical" | "default" | "vertical";
}>(({ theme, modalType }) => ({
	textAlign: "center",
	paddingInline: 0,
	padding:
		modalType === "vertical" ? `0.5rem 0rem 0rem 0rem !important` : undefined,
}));

export const ConfirmationContent = styled(DialogContent)<{
	contentType?: ConfirmContentType;
}>(({ theme, contentType }) => ({
	textAlign: "center",
	paddingInline: 0,
	paddingLeft: contentType === "array" ? `0rem !important` : undefined,
}));

export const ConfirmationMessage = styled(DialogContentText)(({ theme }) => ({
	color:
		theme.themeName === "ahotu"
			? AhotuTheme.AhotuColorPalette.black["soft"]
			: undefined,
}));

export const ConfirmationActions = styled(DialogActions)<{
	variant?: "critical" | "default" | "vertical";
}>(({ theme, variant }) => ({
	display: "flex",
	flexDirection: variant === "vertical" ? "column" : undefined,
	justifyContent: "center",
	paddingInline: 0,
	gap: variant === "vertical" ? "1.5rem" : undefined,
	padding: variant === "vertical" ? "0rem !important" : undefined,
}));

export const ConfirmationButton = styled(Button)(({ theme }) => ({
	fontSize: theme.themeName === "ahotu" ? "16px" : undefined,
	height: theme.themeName === "ahotu" ? "56px" : undefined,
}));

export const ConfirmationLink = styled(Link)(({ theme }) => ({
	fontSize: theme.themeName === "ahotu" ? "16px" : undefined,
	fontWeight: 400,
	lineHeight: "1.5rem",
	cursor: "pointer",
	textDecorationColor:
		theme.themeName === "ahotu"
			? AhotuTheme.AhotuColorPalette.black["soft"]
			: theme.palette.common.black,
}));

export const AhotuIconContainer = styled("div")`
	display: flex;
	justify-content: center;
`;
