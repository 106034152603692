import { usePageControlContext } from "contexts/PageControlProvider/PageControlProvider";
import SummaryContext from "contexts/SummaryProvider/SummaryContext";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import { PersonalDetailsForm } from "pages/PersonalDetailsPage/PersonalDetailsPage";
import { PropsWithChildren, useMemo } from "react";
import { Coupon, Order } from "services/common/models/order";
import useTeamTicket from "services/Order/useTeamTicket";
import TeamInfoForm from "types/models/TeamInfo/teamInfoForm";

interface SummaryProviderProps extends PropsWithChildren {
	formikValues?: PersonalDetailsForm | TeamInfoForm;
	order: Order;
}

function SummaryProvider({
	children,
	formikValues,
	order,
}: SummaryProviderProps) {
	const teamTicket = useTeamTicket(order.orderId);
	const {
		MainRoutePatterns: { isSelectTicketPage, isPaymentPage },
	} = useCheckoutRouteMatch();
	const { pageControlState } = usePageControlContext();

	const getDiscountedValue = (coupon: Coupon, value: number) => {
		if (coupon && coupon.percentage) {
			return (value * (100 - coupon.value)) / 100;
		}
		return value;
	};

	const hasAnyParticipantSelectedItab = order?.participants.some(
		(p) => p.hasITabSelected
	);

	const orderTotal = useMemo(() => {
		if (isSelectTicketPage && pageControlState?.temporaryTotal !== undefined) {
			return `${pageControlState?.temporaryTotal} ${order.convertToCurrency}`;
		}

		if (hasAnyParticipantSelectedItab) {
			return order.totalValueWithITabFormatted;
		}

		if (teamTicket && formikValues && !isPaymentPage) {
			/**
			 * If the value is a team ticket, we need to be able to update the total value when showing the team info page
			 * since a user can add additional team members that can increase the price
			 */
			const { additionalTeamMemberPrice, includedTeamMemberCount } = teamTicket;
			const teamMembers = (formikValues as TeamInfoForm).members;

			if (teamMembers && additionalTeamMemberPrice && includedTeamMemberCount) {
				const additionalTeamMemberCountInOrder =
					order.participants.length - includedTeamMemberCount;

				const additionalTeamMemberCountInForm =
					teamMembers.length - includedTeamMemberCount;

				const additionalTeamMemberPriceWithDiscounts = getDiscountedValue(
					order.coupon,
					additionalTeamMemberPrice
				);

				const totalOfAddedTeamMembers =
					additionalTeamMemberCountInForm > 0
						? additionalTeamMemberPriceWithDiscounts *
						  additionalTeamMemberCountInForm
						: 0;

				const additionalMemberChargeInOrderTotal =
					additionalTeamMemberCountInOrder > 0
						? additionalTeamMemberPriceWithDiscounts *
						  additionalTeamMemberCountInOrder
						: 0;

				const totalWithoutAdditionalMemberCharge =
					order.totalValueConverted - additionalMemberChargeInOrderTotal;

				const total =
					totalWithoutAdditionalMemberCharge + totalOfAddedTeamMembers;

				const formattedTotal = total.toLocaleString("en-US", {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
				return `${formattedTotal} ${order.convertToCurrency}`;
			}
		}
		return order.totalValueFormatted;
	}, [
		teamTicket,
		formikValues,
		order,
		pageControlState?.temporaryTotal,
		isSelectTicketPage,
	]);

	return (
		<SummaryContext.Provider value={{ orderTotal }}>
			{children}
		</SummaryContext.Provider>
	);
}

export default SummaryProvider;
