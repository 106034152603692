import { ComponentType } from "react";

export const lazyRetry = function <
	T extends () => Promise<{ default: ComponentType<any> }>
>(componentImport: T, name: string): Promise<{ default: ComponentType<any> }> {
	return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
		// check if the window has already been refreshed
		const hasRefreshed = JSON.parse(
			window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false"
		);
		// try to import the component
		componentImport()
			.then((component) => {
				window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
				resolve(component);
			})
			.catch((error: Error) => {
				if (!hasRefreshed) {
					// not been refreshed yet
					window.sessionStorage.setItem(`retry-${name}-refreshed`, "true"); // we are now going to refresh
					return window.location.reload(); // refresh the page
				}
				reject(error); // Default error behavior as already tried refresh
			});
	});
};
