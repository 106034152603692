import NavigationBar from "components/NavigationBar";
import {
	Container,
	Grid,
	Skeleton,
	Stack,
	Theme,
	useMediaQuery,
	Alert,
} from "@mui/material";
import {
	Outlet,
	useLocation,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { contentContainerSx, sidebarSx } from "./MainCheckoutLayout.styles";
import PaymentSummary from "components/Checkout/PaymentSummary";
import CssGrid from "components/common/CSSGrid";
import useOrder from "services/Order/useOrder";
import useParticipant from "services/Team/useParticipant";
import { lazy, useContext, useEffect, useMemo, useRef, useState } from "react";
import { MainCheckoutLayoutParams } from "types/common/pages";
import { FormikProps } from "formik";
import useEventFromOrder from "services/Event/useEvent";

import { MainCheckoutRoutes } from "App";
import { FormValidityData } from "types/misc/SummaryBar";
import { useAnalyticsService } from "services/Analytics";
import { GAAnalyticsEvent } from "types/services/analyticsService.types";
import { AuthFormType } from "components/AuthenticationDialog/AuthenticationDialog";
import AuthenticationProvider, {
	AuthenticationContext,
} from "contexts/AuthenticationProvider";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import useUserJourneyMap from "hooks/useUserJourneyMap";
import OrderSummaryHeader from "components/Checkout/OrderSummaryHeader/OrderSummaryHeader";
import Footer from "components/Checkout/Footer/Footer";
import PageStepper from "components/Checkout/PageStepper/PageStepper";
import useDomain from "hooks/useDomain";
import { usePageControlContext } from "contexts/PageControlProvider/PageControlProvider";
import { lazyRetry } from "utils/lazy-retry";
import { useConfirmationDialog } from "contexts/ConfirmationDialogProvider/ConfirmationDialogProvider";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { wmPalette } from "theme/WSGTheme";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { languages, currenciesData } from "types/common/models";
import { LocaleContext } from "contexts/LocaleProvider";
import { QueryParams } from "types/common/enums";

const AuthenticationBanner = lazy(() =>
	lazyRetry(
		() => import("components/AuthenticationBanner"),
		"authenticationBanner"
	)
);
const AuthenticationDialog = lazy(() =>
	lazyRetry(
		() => import("components/AuthenticationDialog"),
		"authenticationDialog"
	)
);

interface AuthenticationDialogState {
	isOpen: boolean;
	form?: AuthFormType;
}

function MainCheckoutLayout() {
	const isTabletOrLarger = useMediaQuery((theme: Theme) =>
		theme.breakpoints.up("sm")
	);

	const { orderId, participantToken } = useParams<MainCheckoutLayoutParams>();
	const { data: order, isLoading: isOrderLoading } = useOrder(orderId);
	const { isLoading: isEventLoading, data: event } = useEventFromOrder(
		order?.eventId
	);
	const UserJourneyMap = useUserJourneyMap();
	const location = useLocation();
	const { pathname } = location;
	const domain = useDomain();
	const { confirm } = useConfirmationDialog();
	const { t } = useTranslation(["common", "dialogs"]);
	const theme = useTheme();

	const isLoading = isOrderLoading || isEventLoading;

	const [authDialog, setAuthDialog] = useState<AuthenticationDialogState>({
		isOpen: false,
	});
	const { reportEventToAnalytics: reportCheckoutProgress } =
		useAnalyticsService();
	const { isChangeTicketRoute, isClaimSpotRoute } = useCheckoutRouteMatch();
	const { data: participant } = useParticipant(orderId, participantToken);

	const [formikValues, setFormikValues] =
		useState<FormikProps<any>["values"]>();
	const [isFormValid, setFormValid] = useState<FormValidityData | undefined>({
		state: true,
	});
	const formRef = useRef<any>(null);
	const hookFormRef = useRef<HTMLFormElement>();
	const { pageControlState } = usePageControlContext();
	const { onLocaleStateChange } = useContext(LocaleContext);
	const [searchParams, setSearchParams] = useSearchParams();

	const steps = useMemo(() => {
		if (isChangeTicketRoute) {
			return UserJourneyMap.ChangeTicket;
		}

		if (isClaimSpotRoute) {
			return UserJourneyMap.ClaimSpot;
		}

		if (order?.teamTicket) {
			return UserJourneyMap.TeamTicket;
		}

		return UserJourneyMap.Main;
	}, [
		isChangeTicketRoute,
		isClaimSpotRoute,
		order?.teamTicket,
		UserJourneyMap,
	]);

	useEffect(() => {
		setFormValid({ state: true, reason: "", loading: false });
	}, [location]);

	useEffect(() => {
		const isMainRoutePersonalDetails = pathname.endsWith(
			`/${MainCheckoutRoutes.PersonalDetails}`
		);

		if (isMainRoutePersonalDetails && orderId) {
			reportCheckoutProgress(GAAnalyticsEvent.CheckoutStart, orderId);
		}
	}, [pathname, orderId, reportCheckoutProgress]);

	const isConfirmationPage = pathname.endsWith(
		`/${MainCheckoutRoutes.Overview}`
	);

	useEffect(() => {
		const checkRegistrationPossible = async () => {
			if (
				event?.isRegistrationPossible === false &&
				order?.status != "successful"
			) {
				const confirmed = await confirm({
					title: t("dialogs:registrationClosed.modal.title"),
					message: t("dialogs:registrationClosed.modal.description", {
						eventName: event?.title,
					}),
					confirmText: t("common:OK"),
					cancelText: "",
					icon: "registrationClose",
					materialIcon: (
						<DoNotDisturbIcon
							sx={{ fontSize: "64px", color: wmPalette.grey[50] }}
						/>
					),
					variant: "vertical",
					disableBackdropClick: true,
				});

				if (confirmed) {
					if (theme.themeName === "ahotu") {
						window.location.href =
							event?.permalink != null
								? `${process.env["REACT_APP_AHOTU_BASE_URL"]}/event/${event?.permalink}`
								: `${process.env["REACT_APP_AHOTU_BASE_URL"]}`;
					} else {
						window.location.href = `${process.env["REACT_APP_WM_BASE_URL"]}/marathon/${event?.eventId}`;
					}
				}
			}
		};
		checkRegistrationPossible();
	}, [location, event]);

	useEffect(() => {
		//Checking query param `lng`
		if (searchParams.has(QueryParams.language)) {
			const languageParam = searchParams.get(QueryParams.language);

			if (languageParam != null) {
				if (languages.some((o) => o.value.toString().includes(languageParam))) {
					onLocaleStateChange?.("language", languageParam);
				}
			}
			searchParams.delete(QueryParams.language);
			setSearchParams(searchParams);
		}

		if (searchParams.has(QueryParams.currency)) {
			const currencyParam = searchParams.get(QueryParams.currency);

			if (currencyParam != null) {
				if (
					currenciesData.find((c) => c.toLowerCase().includes(currencyParam))
				) {
					onLocaleStateChange?.("currency", currencyParam.toUpperCase());
				}
			}
			searchParams.delete(QueryParams.currency);
			setSearchParams(searchParams);
		}
	}, [searchParams]);

	return (
		<main>
			<AuthenticationProvider order={order}>
				<NavigationBar
					onSignupClick={() =>
						setAuthDialog({ isOpen: true, form: "register" })
					}
					onLoginClick={() => setAuthDialog({ isOpen: true, form: "login" })}
				/>
				<OrderSummaryHeader />
				<Container component="section" maxWidth="md" sx={contentContainerSx}>
					{isLoading && <Skeleton height="15vh" />}
					<PageStepper steps={steps} hidden={isLoading && !order} />
					<AuthenticationContext.Consumer>
						{({ authenticationBanner }) =>
							!isLoading &&
							!isConfirmationPage &&
							authenticationBanner?.show &&
							domain.isWorldsMarathons && (
								<AuthenticationBanner
									onLoginClick={() =>
										setAuthDialog({ isOpen: true, form: "login" })
									}
									onSignupClick={() =>
										setAuthDialog({ isOpen: true, form: "register" })
									}
								/>
							)
						}
					</AuthenticationContext.Consumer>
					{isLoading && (
						<Grid container spacing={2} height="75vh">
							<Grid item xs={12} md={8}>
								<Stack gap={1} height="100%">
									<Skeleton height="100%" sx={{ transform: "none" }} />
								</Stack>
							</Grid>
							<Grid item xs={0} md={4} hidden={!isTabletOrLarger}>
								<Skeleton height="100%" sx={{ transform: "none" }} />
							</Grid>
						</Grid>
					)}
					{!isConfirmationPage && !isLoading ? (
						<CssGrid columns={12} gap={2}>
							<CssGrid item xs={12} md={8}>
								{pageControlState?.error?.message &&
									pageControlState?.error?.type === "banner" && (
										<Alert severity="error" sx={{ margin: "1rem 0rem" }}>
											{pageControlState?.error?.message}
										</Alert>
									)}
								<Outlet
									context={{
										order,
										formRef,
										hookFormRef,
										participant,
										setFormikValues,
										setFormValidity: setFormValid,
									}}
								/>
							</CssGrid>
							<CssGrid item xs={12} md={4} sx={sidebarSx}>
								<PaymentSummary
									formRef={formRef}
									hookFormRef={hookFormRef}
									formikValues={formikValues}
									isFormValid={isFormValid}
									setFormValid={setFormValid}
								/>
							</CssGrid>
						</CssGrid>
					) : (
						<Outlet context={{ order, participant }} />
					)}
				</Container>
				<AuthenticationDialog
					form={authDialog.form}
					open={authDialog.isOpen}
					onClose={() => setAuthDialog({ isOpen: false })}
				/>
			</AuthenticationProvider>
			<Footer />
		</main>
	);
}

export default MainCheckoutLayout;
