export enum EventProductType {
	Race,
	Extra,
	All,
	Participant,
	PackageDeal,
	Donation,
	Addon,
	GiftCard,
	ServiceFee,
	TransactionFee,
}
