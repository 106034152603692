import {
	Button,
	List,
	ListItem,
	Stack,
	Typography,
	Divider,
	Box,
	Link,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Fragment, MouseEvent, useEffect, useState } from "react";
import {
	addParticipantBtnContainerSx,
	addParticipantBtnSx,
	SummaryContentContainer,
} from "components/Checkout/PaymentSummary/PaymentDetails/PaymentDetails.styles";
import PaymentSummaryTotal from "components/Checkout/PaymentSummary/PaymentSummaryTotal";
import useEventFromOrder from "services/Event/useEvent";
import { useLocation, useParams } from "react-router-dom";
import { MainCheckoutLayoutParams } from "types/common/pages";
import useOrder from "services/Order/useOrder";
import TeamInfoForm from "types/models/TeamInfo/teamInfoForm";
import { PersonalDetailsForm } from "pages/PersonalDetailsPage/PersonalDetailsPage";
import { PaymentSummaryProps } from "components/Checkout/PaymentSummary/PaymentSummary";
import { MainCheckoutRoutes } from "App";
import ContinueButton from "components/Checkout/PaymentSummary/ContinueButton";
import CouponCodeSection from "components/Checkout/PaymentSummary/CouponCodeSection";
import CreditSection from "components/Checkout/PaymentSummary/CreditSection";
import FormattedDate from "components/common/Date";
import GeneralParticipantsList from "./GeneralParticipantsList";
import PaymentModifierProvider from "components/Checkout/PaymentSummary/PaymentModifierProvider";
import { useAuthentication } from "contexts/AuthenticationProvider/AuthenticationProvider";
import useSummaryContext from "contexts/SummaryProvider/useSummaryContext";
import TeamMemberList from "./TeamMemberList";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import useDomain from "hooks/useDomain";
import common from "theme/common";
import Icon from "components/common/Icon/Icon";
import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material";
import { ParticipantPersonalDetails } from "types/services/orderService.types";

interface Props extends PaymentSummaryProps {
	onSubmit(e: MouseEvent<HTMLButtonElement>): void;
	onAddParticipantBtnClick(e: MouseEvent<HTMLButtonElement>): void;
	getFormTickets: () => ParticipantPersonalDetails[] | null;
}

function PaymentDetails({
	formikValues,
	onSubmit,
	onAddParticipantBtnClick,
	getFormTickets,
}: Props) {
	const { orderId, participantToken } = useParams<MainCheckoutLayoutParams>();
	const { data: order } = useOrder(orderId);
	const { data: event } = useEventFromOrder(order?.eventId);

	const { pathname } = useLocation();

	const isPersonalDetailsPage = pathname.includes(
		`/${MainCheckoutRoutes.PersonalDetails}`
	);
	const domain = useDomain();

	const { t } = useTranslation("common");
	const { orderTotal } = useSummaryContext();
	const { isLoggedIn } = useAuthentication();
	const {
		isChangeTicketRoute,
		isClaimSpotRoute,
		MainRoutePatterns: { isSelectTicketPage },
	} = useCheckoutRouteMatch();
	const [hasITabEnabled, setHasITabEnabled] = useState(false);

	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("md")
	);

	/**
	 * Used to update the side panel with name entered in the ticket forms
	 *
	 * Uses `PersonalDetailsForm` interface to understand the way the value is pulled out
	 */
	const getEditedParticipantNameFromForm = (formIndex: number) => {
		if (!formikValues) {
			return null;
		}

		if (
			"tickets" in formikValues &&
			(formikValues as PersonalDetailsForm).tickets
		) {
			const participant = formikValues?.tickets?.[formIndex];

			if (participant && participant.firstName && participant.lastName) {
				const { firstName, lastName } = participant;
				const participantName = `${firstName} ${lastName}`;
				return participantName;
			}
		}

		if ("members" in formikValues && (formikValues as TeamInfoForm).members) {
			const participant = formikValues.members[formIndex];
			return participant?.fullName;
		}

		return null;
	};

	const getTitle = () => {
		if (isClaimSpotRoute) {
			return t("common:acceptInvitation.summary.title");
		}
		return t("common:yourOrder.summary.title");
	};

	function checkITabSelected() {
		const hasITabEnableForEvent = event?.hasITabEnabled;
		if (typeof hasITabEnableForEvent === "boolean") {
			setHasITabEnabled(hasITabEnableForEvent);
		}
	}

	useEffect(() => {
		checkITabSelected();
	}, [hasITabEnabled]);

	const showEventTitleLink =
		Boolean(isSelectTicketPage) &&
		!domain.isWorldsMarathons &&
		Boolean(order?.urlReferrer);

	return (
		<SummaryContentContainer component="summary">
			<List disablePadding sx={isMobile ? { zIndex: -1 } : undefined}>
				<Box>
					<Typography
						component="h1"
						variant="body1"
						fontWeight="bold"
						hidden={!order}
					>
						{getTitle()}
					</Typography>
					<Stack direction="column" marginBottom={2}>
						<Typography
							component={!showEventTitleLink ? "h2" : Link}
							href={!showEventTitleLink ? undefined : order?.urlReferrer}
							variant="subtitle1"
							lineHeight={common.pxToRemCssValue(20)}
							target={!showEventTitleLink ? undefined : "_blank"}
							rel="noreferrer"
							sx={
								showEventTitleLink
									? { display: "flex", alignItems: "centers" }
									: undefined
							}
						>
							{event?.title ?? "No Title"}
							{showEventTitleLink && (
								<span>
									<Icon name="externalLink" rawSize={["20px", "20px"]} />
								</span>
							)}
						</Typography>
						<FormattedDate
							component="p"
							variant="subtitle1"
							date={event?.strDateRangeNextRace}
							lineHeight={common.pxToRemCssValue(20)}
						/>
					</Stack>
				</Box>
				{order && !isSelectTicketPage && (
					<GeneralParticipantsList
						order={order}
						onGetParticipantName={getEditedParticipantNameFromForm}
						hidden={order.participants.length === 0}
						getFormTickets={getFormTickets}
					/>
				)}
				{order &&
					!hasITabEnabled &&
					order.teamTicket &&
					!isSelectTicketPage &&
					!isClaimSpotRoute && (
						<TeamMemberList
							order={order}
							formParticipants={(formikValues as TeamInfoForm)?.members}
						/>
					)}
				{domain.data?.origin &&
					isPersonalDetailsPage &&
					!order?.teamTicket &&
					!isClaimSpotRoute && (
						<ListItem
							disableGutters
							sx={addParticipantBtnContainerSx}
							hidden={!order}
						>
							<Button
								disableRipple
								data-theme={domain.data.origin}
								onClick={onAddParticipantBtnClick}
								startIcon={<AddOutlinedIcon />}
								sx={addParticipantBtnSx}
								fullWidth
							>
								{t("common:add_participant")}
							</Button>
						</ListItem>
					)}
				{order &&
					Boolean(
						order.refundInsuranceAmountConverted &&
							order.refundInsuranceAmountConverted > 0
					) &&
					!isChangeTicketRoute &&
					!isClaimSpotRoute && (
						<ListItem disableGutters sx={{ mt: "3rem" }}>
							<Stack
								direction="row"
								justifyContent="space-between"
								width="100%"
							>
								<Typography fontSize="0.875rem" fontWeight="bold">
									{t("common:refundableBooking.label")}
								</Typography>
								<Typography fontSize="0.875rem">
									{order.convertedRefundAmount} {order.convertToCurrency}
								</Typography>
							</Stack>
						</ListItem>
					)}
			</List>
			{!isClaimSpotRoute && order && (
				<PaymentModifierProvider
					order={order}
					hideChildren={
						Boolean(isChangeTicketRoute) || Boolean(isSelectTicketPage)
					}
				>
					<Divider />
					<CouponCodeSection showAppliedCoupon={Boolean(order?.coupon)} />
					{isLoggedIn && domain.data?.origin !== "ahotu" && <CreditSection />}
				</PaymentModifierProvider>
			)}
			<Stack gap={3}>
				{order && !isClaimSpotRoute && (
					<Fragment>
						<Divider />
						<PaymentSummaryTotal total={orderTotal} />
					</Fragment>
				)}
				{!isMobile && <ContinueButton onClick={onSubmit} />}
			</Stack>
		</SummaryContentContainer>
	);
}

export default PaymentDetails;
