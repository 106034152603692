import { Stack, useTheme } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { useTranslation } from "react-i18next";
import { PaymentPromise } from "./PaymentPromises.styles";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import Icon from "components/common/Icon/Icon";

const AhotuDealsAndDiscountsIcon = () => <Icon name="priceTag" />;
const AhotuRefundableBookingIcon = () => <Icon name="tip" />;
const AhotuSecurePaymentIcon = () => <Icon name="creditCard" />;

interface Props {
	isRefundableBookingEnabled: boolean;
}

function PaymentPromises(props: Props) {
	const { t } = useTranslation("common");
	const { isChangeTicketRoute } = useCheckoutRouteMatch();
	const { themeName } = useTheme();
	const promises = [
		{
			label: t("deal&Discounts.usp"),
			icon:
				themeName === "worldsmarathons"
					? LocalActivityIcon
					: AhotuDealsAndDiscountsIcon,
		},
		props.isRefundableBookingEnabled
			? {
					label: t("refundableBookingOption.usp"),
					icon:
						themeName === "worldsmarathons"
							? VerifiedUserIcon
							: AhotuRefundableBookingIcon,
			  }
			: null,
		{
			label: t("securePayments.usp"),
			icon:
				themeName === "worldsmarathons"
					? CreditCardIcon
					: AhotuSecurePaymentIcon,
		},
	];

	if (isChangeTicketRoute) {
		return null;
	}

	return (
		<Stack gap={1} marginY="1.5rem">
			{promises.map((p, index) => {
				if (!p) {
					return null;
				}

				const Icon = p.icon;

				return (
					<Stack key={index} direction="row" alignItems="center" gap={1}>
						<Icon color="primary" fontSize="small" />
						<PaymentPromise>{p.label}</PaymentPromise>
					</Stack>
				);
			})}
		</Stack>
	);
}

export default PaymentPromises;
