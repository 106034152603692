import { Typography, TypographyProps } from "@mui/material";
import dayjs from "dayjs";
import { ElementType, useEffect, useState } from "react";

interface FormattedDateProps extends TypographyProps {
	/** Will default to the current date */
	date?: Date | string;
	format?: string;
	component?: ElementType;
}

function FormattedDate({
	variant,
	date = new Date(),
	format = "DD MMM YYYY (ddd)",
	component,
	...props
}: FormattedDateProps) {
	const [formattedDate, setFormattedDate] = useState<string | null>(null);

	useEffect(() => {
		if (date instanceof Date) {
			setFormattedDate(dayjs(date).format(format));
		} else {
			setFormattedDate(date);
		}
	}, [date, format]);

	return (
		<Typography component={component ?? "time"} variant={variant} {...props}>
			{formattedDate}
		</Typography>
	);
}

export default FormattedDate;
