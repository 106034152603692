import LocaleContext, {
	localeContextDefaults,
	LocaleContextProps,
} from "./LocaleContext";
import {
	PropsWithChildren,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { LocaleSettings } from "types/common/global";
import { currencies, languages, measures } from "types/common/models";
import useLocalStorage, { LocalStorageKeys } from "hooks/useLocalStorage";

/**
 * Context for managing state of translation, currency and metrics across pages
 */
function LocaleProvider({ children }: PropsWithChildren) {
	const { i18n } = useTranslation();
	const localStorageCurrency = useLocalStorage(LocalStorageKeys.Currency);
	const localStorageLanguage = useLocalStorage(LocalStorageKeys.Language);
	const localStorageMeasure = useLocalStorage(LocalStorageKeys.Measure);
	const [preventCurrencyChange, setPreventCurrencyChange] = useState(false);

	const [localeSettings, setLocaleSettings] = useState<LocaleSettings>({
		...localeContextDefaults.localeState,
		currency:
			localStorageCurrency?.value ?? localeContextDefaults.localeState.currency,
		measure:
			measures[parseInt(localStorageMeasure.value || "0")]?.value ??
			measures[0].value,
		modified: false,
	});

	const handleLocaleStateChange = useCallback(
		(key: keyof LocaleSettings | "all", value: string | LocaleSettings) => {
			if (key !== "all") {
				setLocaleSettings((prev) => ({
					...prev,
					modified: true,
					[key]: value,
				}));
			} else {
				i18n.changeLanguage((value as LocaleSettings).language ?? "en");
				setLocaleSettings({ ...(value as LocaleSettings), modified: true });
			}

			if (key === "language") {
				localStorageLanguage.setValue(value);
				i18n.changeLanguage(value as string);
			}

			if (key === "measure") {
				localStorageMeasure.setValue(value);
			}
		},
		[i18n, localStorageLanguage.setValue, localStorageMeasure.setValue]
	);

	const getLocaleStateDisplayText = useCallback(
		(from: keyof LocaleSettings) => {
			if (from === "language") {
				const language = languages.find(
					(l) => l.value.toString() === localeSettings.language
				);

				return language?.label;
			}

			if (from === "currency") {
				const currency = currencies.find(
					(c) => c.value.toString() === localeSettings.currency
				);
				return currency?.label;
			}

			if (from === "measure") {
				const measure = measures.find(
					(m) => m.value.toString() == localeSettings.measure
				);
				return measure?.label;
			}

			return undefined;
		},
		[localeSettings.language, localeSettings.currency, localeSettings.measure]
	);

	useEffect(() => {
		setLocaleSettings((prev) => ({
			...prev,
			language: localStorageLanguage.value
				? localStorageLanguage.value
				: navigator.language,
		}));
	}, [localStorageLanguage.value]);

	useEffect(() => {
		localStorageCurrency.value &&
			setLocaleSettings((prev) => ({
				...prev,
				currency: localStorageCurrency.value,
			}));
	}, [localStorageCurrency.value]);

	useEffect(() => {
		localStorageLanguage.value &&
			setLocaleSettings((prev) => ({
				...prev,
				language: localStorageLanguage.value,
			}));
	}, [localStorageLanguage.value]);

	useEffect(() => {
		localStorageMeasure?.value &&
			setLocaleSettings((prev) => ({
				...prev,
				measure: localStorageMeasure.value,
			}));
	}, [localStorageMeasure.value]);

	useEffect(() => {
		const userLanguages = i18n.languages;
		const supportedLanguages = userLanguages.filter((ul) =>
			languages.some((l) => l.value.toString().includes(ul))
		);

		const newLanguage =
			supportedLanguages.length > 0 ? supportedLanguages[0] : "en";

		setLocaleSettings((prev) => ({
			...prev,
			language: newLanguage,
			modified: true,
		}));
	}, [i18n.languages]);

	const value: LocaleContextProps = useMemo(
		() => ({
			localeState: localeSettings,
			onLocaleStateChange: handleLocaleStateChange,
			preventCurrencyChange: {
				value: preventCurrencyChange,
				setValue: (prevent) => {
					setPreventCurrencyChange(prevent);
				},
			},
			getLocaleStateDisplayText,
		}),
		[localeSettings, handleLocaleStateChange, preventCurrencyChange]
	);

	return (
		<LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
	);
}

export default LocaleProvider;

export const useLocale = () => {
	const localeContext = useContext(LocaleContext);

	if (!localeContext) {
		throw new Error(
			"useLocale must be used within a LocaleProvider. Wrap a parent component in <LocaleProvider> to fix this error."
		);
	}

	const { localeState, ...localeModifiers } = localeContext;

	return {
		currency: localeState.currency,
		language: localeState.language,
		modified: localeState.modified,
		measure: localeState.measure,
		...localeModifiers,
	};
};
