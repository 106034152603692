import axios from "axios";

axios.interceptors.request.use((req) => {
	const token = localStorage.getItem("user");
	if (req.headers && token) {
		req.headers["Authorization"] = `Bearer ${token}`;
	} else {
		delete req.headers?.["Authorization"];
	}

	return req;
});
