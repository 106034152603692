import { useEffect, useState } from "react";
import useEventProducts from "services/Event/useEventProducts";
import { Product } from "types/models/EventProduct/eventProduct.types";
import useOrder from "./useOrder";

const useTeamTicket = (orderId?: string) => {
	const { data: order } = useOrder(orderId);
	const { data: eventProducts } = useEventProducts(
		order?.eventId,
		order?.raceId
	);

	const [teamTicket, setTeamTicket] = useState<Product>();

	useEffect(() => {
		if (order?.teamTicket) {
			const initialParticipantProductId =
				order?.participants[0].orderLines.find(
					(ol) => ol.productType === "race"
				)?.productId;

			if (initialParticipantProductId) {
				const eventProduct = eventProducts?.products?.find(
					(p) => p.productId === initialParticipantProductId
				);

				setTeamTicket(eventProduct);
			}
		}
	}, [orderId, eventProducts, order?.participants, order?.teamTicket]);

	return teamTicket;
};

export default useTeamTicket;
