import React, { Component } from "react";
import packageJson from "../package.json";
(global as any).appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA: string, versionB: string): boolean => {
	const versionsA = versionA.split(/\./g);
	const versionsB = versionB.split(/\./g);

	while (versionsA.length || versionsB.length) {
		const a = Number(versionsA.shift());
		const b = Number(versionsB.shift());

		if (a === b) continue;

		return a > b || isNaN(b);
	}

	return false;
};

interface CacheBusterProps {
	children: (state: {
		loading: boolean;
		isLatestVersion: boolean;
		refreshCacheAndReload: () => void;
	}) => React.ReactNode;
}

interface CacheBusterState {
	loading: boolean;
	isLatestVersion: boolean;
}

class CacheBuster extends Component<CacheBusterProps, CacheBusterState> {
	constructor(props: CacheBusterProps) {
		super(props);

		this.state = {
			loading: true,
			isLatestVersion: false,
		};
	}

	componentDidMount() {
		fetch("/meta.json")
			.then((response) => response.json())
			.then((meta) => {
				const latestVersion = meta.version;
				const currentVersion = (global as any).appVersion;

				const shouldForceRefresh = semverGreaterThan(
					latestVersion,
					currentVersion
				);

				if (shouldForceRefresh) {
					console.log(
						`We have a new version - ${latestVersion}. Should force refresh`
					);
					this.setState({ loading: false, isLatestVersion: false });
				} else {
					console.log(
						`You already have the latest version - ${latestVersion}. No cache refresh needed.`
					);
					this.setState({ loading: false, isLatestVersion: true });
				}
			});
	}

	render() {
		const { loading, isLatestVersion } = this.state;

		return this.props.children({
			loading,
			isLatestVersion,
			refreshCacheAndReload: () => {
				console.log("Clearing cache and hard reloading...");
				if (caches) {
					caches.keys().then(function (names) {
						for (const name of names) caches.delete(name);
					});
				}

				window.location.reload();
			},
		});
	}
}

export default CacheBuster;
