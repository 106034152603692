import {
	Accordion,
	AccordionDetails,
	Backdrop,
	Box,
	Button,
	Fade,
	GlobalStyles,
	Grow,
	Slide,
	Stack,
	Typography,
} from "@mui/material";
import { DevicePaymentSummaryProps } from "components/Checkout/PaymentSummary/PaymentSummary";
import { Fragment, MouseEvent, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ChevronUp from "@mui/icons-material/KeyboardArrowUp";
import ChevronDown from "@mui/icons-material/KeyboardArrowDown";
import {
	StyledAccordionSummary,
	accordionSx,
	AccordionRevealStack,
	mobileSummaryDetails_ContentContainer,
	mobileSummaryDetailsSx,
	slideMenuToggleButtonSx,
	AccordionContainer,
	addParticipantBtnSx,
	summaryTextStack,
} from "./MobilePaymentSummary.styles";

import PaymentDetails from "components/Checkout/PaymentSummary/PaymentDetails";
import { useLocation, useParams } from "react-router-dom";
import { MainCheckoutLayoutParams } from "types/common/pages";
import useOrder from "services/Order/useOrder";
import { MainCheckoutRoutes } from "App";
import useEventFromOrder from "services/Event/useEvent";
import { useTranslation } from "react-i18next";
import ContinueButton from "components/Checkout/PaymentSummary/ContinueButton";
import useSummaryContext from "contexts/SummaryProvider/useSummaryContext";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import Icon from "components/common/Icon/Icon";
import { usePageControlContext } from "contexts/PageControlProvider/PageControlProvider";
import useDomain from "hooks/useDomain";

function MobilePaymentSummary({
	formikValues,
	onSubmit,
	onAddParticipantClick,
	getFormTickets,
}: DevicePaymentSummaryProps) {
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const { pathname } = useLocation();
	const isPersonalDetailsPage = pathname.includes(
		`/${MainCheckoutRoutes.PersonalDetails}`
	);
	const { data: order } = useOrder(orderId);
	const { data: event } = useEventFromOrder(order?.eventId);

	const [open, setOpen] = useState(false);
	const { t } = useTranslation("common");
	const { orderTotal } = useSummaryContext();
	const {
		isClaimSpotRoute,
		MainRoutePatterns: { isSelectTicketPage },
	} = useCheckoutRouteMatch();
	const domain = useDomain();
	const { pageControlState } = usePageControlContext();

	const handleAddParticipantClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onAddParticipantClick(e);
	};

	useEffect(() => {
		setOpen(false);
	}, [pathname]);

	const DirectionalChevron = open ? ChevronDown : ChevronUp;
	const hideMobileBar =
		isSelectTicketPage && (pageControlState?.selectTicketState.count ?? 0) < 1;

	return (
		<Fragment>
			{/* Disable scroll behind accordion */}
			<GlobalStyles
				styles={{
					body: { overflow: open ? "hidden" : "scroll" },
					"main > header": {
						zIndex: !open ? "1100 !important" : 0,
					},
					"main .MuiBox-root:nth-of-type(1)": {
						zIndex: open ? -1 : 0,
						position: open ? "relative" : "static",
					},
				}}
			/>
			<Backdrop
				open={open}
				sx={{ backgroundColor: "rgba(255,255,255,0.8)" }}
				transitionDuration={500}
			/>
			<Fade in={!hideMobileBar} unmountOnExit>
				<AccordionContainer isOpen={open}>
					<Accordion
						expanded={open}
						onChange={() => setOpen(!open)}
						sx={accordionSx}
						TransitionProps={{ unmountOnExit: true, timeout: 300 }}
					>
						<StyledAccordionSummary data-open={open}>
							<Button
								disableElevation
								sx={slideMenuToggleButtonSx}
								endIcon={
									domain.data?.origin === "ahotu" ? (
										<Icon name={open ? "chevronDown" : "chevronUp"} />
									) : (
										<DirectionalChevron />
									)
								}
								disableRipple
							>
								{!open
									? t("mobileSummaryToggle.label")
									: t("mobileSummaryToggle.label_expanded")}
							</Button>
							<Grow in={!open} unmountOnExit timeout={{ enter: 500 }}>
								<AccordionRevealStack data-open={open}>
									<Stack
										direction="row"
										justifyContent={
											open || !isPersonalDetailsPage
												? "flex-start"
												: "space-between"
										}
										alignItems="center"
										className="accordionReveal-row"
										marginTop=".5rem"
										width="100%"
									>
										{!open &&
											isSelectTicketPage &&
											pageControlState?.selectTicketState.count != null && (
												<Typography>
													{pageControlState?.selectTicketState.count ?? 0}{" "}
													selected
												</Typography>
											)}
										{!open && isPersonalDetailsPage && !order?.teamTicket && (
											<Button
												startIcon={<AddIcon />}
												sx={addParticipantBtnSx}
												data-theme={domain.data?.origin}
												onClick={handleAddParticipantClick}
											>
												{t("common:add_participant")}
											</Button>
										)}
									</Stack>
									{order &&
										!open &&
										(!isClaimSpotRoute ? (
											<Fragment>
												<Stack
													sx={summaryTextStack}
													data-theme={domain.data?.origin}
													direction="row"
												>
													{domain.data?.origin === "worldsmarathons" && (
														<Typography>
															{`${order.participants.length} ${
																order.participants.length > 1
																	? t("common:participant.label_plural")
																	: t("common:participant.label")
															}`}
														</Typography>
													)}
													<Typography fontSize="1.125rem" fontWeight={700}>
														{orderTotal}
													</Typography>
													{domain.data?.origin === "ahotu" && (
														<ContinueButton onClick={onSubmit} />
													)}
												</Stack>
												{domain.data?.origin === "worldsmarathons" && (
													<ContinueButton onClick={onSubmit} />
												)}
											</Fragment>
										) : (
											<ContinueButton onClick={onSubmit} />
										))}
								</AccordionRevealStack>
							</Grow>
						</StyledAccordionSummary>
						<AccordionDetails sx={mobileSummaryDetailsSx}>
							<Slide in={open} direction="up" unmountOnExit timeout={500}>
								<Box sx={mobileSummaryDetails_ContentContainer}>
									<PaymentDetails
										{...{ formikValues, onSubmit }}
										onAddParticipantBtnClick={onAddParticipantClick}
										getFormTickets={getFormTickets}
									/>
								</Box>
							</Slide>
						</AccordionDetails>
					</Accordion>
				</AccordionContainer>
			</Fade>
		</Fragment>
	);
}

export default MobilePaymentSummary;
