import { Avatar, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { avatarSx } from "components/UserMenu/UserMenu.styles";
import { useAuthentication } from "contexts/AuthenticationProvider/AuthenticationProvider";
import MenuButton from "components/common/MenuButton";
import { ExternalRoutes } from "types/services/navigation.types";
import { wmPalette } from "theme/WSGTheme";
import { useTranslation } from "react-i18next";
import { useCreditService } from "services/UserCredits";
import { useParams } from "react-router-dom";
import { MainCheckoutLayoutParams } from "types/common/pages";
import useOrder from "services/Order/useOrder";

interface UserMenuProps {
	name: string;
	avatarImageSrc?: string;
}

const UserMenu = ({ name, avatarImageSrc }: UserMenuProps) => {
	const { t } = useTranslation(["authentication"]);
	const { handleLogout, user } = useAuthentication();
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const { data: order, mutate: updateOrder } = useOrder(orderId);
	const { activateCredits } = useCreditService();

	const navigateToProfile = () => {
		if (!user) return;
		window.open(
			ExternalRoutes.ProfilePage(user.username),
			"_blank",
			"noreferrer"
		);
	};

	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("md")
	);

	async function handleLogoutClick() {
		if (order && order.creditsUsedConverted > 0) {
			const newOrder = await activateCredits(order.orderId);
			if (newOrder) {
				updateOrder(newOrder);
			}
		}

		handleLogout();
	}

	return (
		<MenuButton
			buttonId="avatar-button"
			menuId="avatar-menu"
			menuItems={[
				{ text: t("authentication:profile.label"), onClick: navigateToProfile },
				{ text: t("authentication:logout"), onClick: handleLogoutClick },
			]}
			hideDropdownIcon={isMobile}
			disableHoverEffect
		>
			<Stack direction="row" alignItems="center" gap="1rem">
				<Avatar src={avatarImageSrc ?? ".jpg"} alt={name} sx={avatarSx} />
				{!isMobile && (
					<Typography
						variant="body1"
						fontSize=".875rem"
						color={wmPalette.grey[90]}
					>
						{name}
					</Typography>
				)}
			</Stack>
		</MenuButton>
	);
};

export default UserMenu;
