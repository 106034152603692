import axios from "axios";
import { axiosErrorHandler } from "services/AxiosErrorHandling";
import { ServiceBaseUrls, SWRRequestKeys } from "services/common/constants";
import { EventProxy } from "services/common/models/eventProxy";
import useSWR from "swr";

const useEventProxy = (eventId?: string) => {
	return useSWR<EventProxy>(
		eventId ? SWRRequestKeys.EventProxy : null,
		async () => {
			try {
				const { data } = await axios.get(
					`${ServiceBaseUrls.Marathons}/${eventId}/proxy`
				);
				return data;
			} catch (error) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(error);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Marathons}/${eventId}/proxy`,
					});
				}
			}
		}
	);
};

export default useEventProxy;
