import {
	ScriptContextDerivedState,
	usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { ReactElement } from "react";

interface ChildrenType extends Omit<ScriptContextDerivedState, "isPending"> {
	isLoading: boolean;
}

interface PayPalPaymentReducerWrapperProps {
	children(props: ChildrenType): ReactElement;
}

const PayPalPaymentReducerWrapper = ({
	children,
}: PayPalPaymentReducerWrapperProps) => {
	const [{ isPending, ...paypalReducerProps }] = usePayPalScriptReducer();
	return children({ ...paypalReducerProps, isLoading: isPending });
};

export default PayPalPaymentReducerWrapper;
