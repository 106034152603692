import { Theme, useMediaQuery } from "@mui/material";
import DesktopNavigationBar from "components/NavigationBar/DesktopNavigationBar";
import MobileNavigationBar from "components/NavigationBar/MobileNavigationBar";
import { LocaleContext } from "contexts/LocaleProvider";
import useLocalStorage from "hooks/useLocalStorage";
import { useContext, useEffect } from "react";
import { useCurrency } from "services/Localization";

export interface NavigationBar {
	onLoginClick(): void;
	onSignupClick(): void;
}

function NavigationBar({ onLoginClick, onSignupClick }: NavigationBar) {
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("md")
	);

	const { localeState } = useContext(LocaleContext);
	const currency = useCurrency();

	const localStorageCurrency = useLocalStorage("currency");

	useEffect(() => {
		if (currency && localeState.modified) {
			localStorageCurrency?.setValue(currency);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currency, localeState]);

	return isMobile ? (
		<MobileNavigationBar onLoginClick={onLoginClick} />
	) : (
		<DesktopNavigationBar
			onSignupClick={onSignupClick}
			onLoginClick={onLoginClick}
		/>
	);
}

export default NavigationBar;
