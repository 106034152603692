import { SxProps } from "@mui/material";
import { wmPalette } from "theme/WSGTheme";

export const expandIconSX: SxProps = {
	color: wmPalette.grey[90],
};

export const addCouponAccordionSx: SxProps = {
	border: "none",
	"& .MuiButtonBase-root": {
		paddingInline: 0,
	},
	"& .MuiAccordionDetails-root": {
		padding: ".5rem 0",
	},
	"&.Mui-disabled": {
		backgroundColor: "transparent",
	},
};

export const addCouponITabTextSx: SxProps = {
	color: "#585F63",
	fontSize: "0.75rem",
	fontWeight: 400,
	lineHeight: "1.125rem",
};
