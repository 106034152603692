import { Button, Stack, styled } from "@mui/material";

export const MobilePaymentItemOption = styled(Button)`
	text-decoration: underline;
	padding: 0;
	font-size: 0.875rem;
	font-weight: 400;

	&:hover,
	&:focus {
		background-color: transparent !important;
		text-decoration: underline;
	}
`;

interface PaymentSummaryListItemProps {
	isChangeTicketView?: boolean;
}

export const PaymentSummaryListItem = styled(Stack, {
	shouldForwardProp: (prop) => {
		return prop !== "isChangeTicketView";
	},
})<PaymentSummaryListItemProps>(({ isChangeTicketView = false }) => ({
	marginInlineStart: isChangeTicketView ? `1rem` : 0,
}));
