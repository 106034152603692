import { createTheme } from "@mui/material";
import { Shadows } from "@mui/material/styles/shadows";
import common from "./common";

const wmPalette = {
	green: {
		100: "#02A449",
		70: "#00C282",
		30: "#00d186",
		20: "#97EBBD",
		10: "#D4FDE6",
		5: "#EAFFF4",
	},
	black: {
		100: "#000000",
		90: "#353E44",
		50: "#7F8589",
		40: "#B3B8BC",
		30: "#D1D7DB",
		20: "#EBEEEF",
		10: "F6F8FA",
	},
	blue: {
		100: "#2394C3",
		70: "#3DADDC",
		20: "#DBEFF8",
	},
	grey: {
		90: "#353e44",
		80: "#3F484D",
		60: "#5b6064",
		50: "#7f8589",
		40: "#b3b8bc",
		30: "#d1d7db",
		20: "#ebeeef",
		10: "#f6f8fa",
		5: "#f9f9f9",
	},
	softBlack: {
		100: "#202228",
	},
	red: {
		100: "#EA1919",
		20: "#FCD9E0",
	},
	white: {
		100: "#FFFFFF",
	},
	orange: {
		100: "#F98C0C",
	},
	yellow: {
		100: "#F6DD62",
		10: "#FFF9C8",
	},
	purple: {
		100: "#8D29CD",
		90: "#A152D3",
		10: "#EFD9FC",
	},
};

const headings = {
	fontWeight: "bold",
};

const WMCommonStyles = {
	body: {
		default: {
			fontWeight: 400,
			color: wmPalette.grey[90],
			fontSize: common.pxToRemCssValue(16),
		},
		semiBold: {
			fontWeight: 600,
			fontSize: common.pxToRemCssValue(16),
		},
		bold: {
			fontWeight: 700,
			fontSize: common.pxToRemCssValue(16),
		},
	},
	subtitle: {
		common: {
			fontSize: common.pxToRemCssValue(14),
		},
		default: {
			fontWeight: 400,
		},
		bold: {
			fontWeight: 700,
		},
	},
};

const baseTheme = createTheme();

const shadows = [
	"0px 4px 8px rgba(0, 0, 0, 0.08)",
	"0px 4px 8px rgba(0, 0, 0, 0.08)",
	"0px 4px 8px rgba(0, 0, 0, 0.08)",
	"0px 4px 9px rgba(0, 0, 0, 0.08)",
	"0px 4px 9px rgba(0, 0, 0, 0.08)",
	"0px 4px 9px rgba(0, 0, 0, 0.08)",
	"0px 4px 10px rgba(0, 0, 0, 0.08)",
	"0px 4px 10px rgba(0, 0, 0, 0.08)",
	"0px 4px 10px rgba(0, 0, 0, 0.08)",
	"0px 4px 11px rgba(0, 0, 0, 0.08)",
	"0px 4px 11px rgba(0, 0, 0, 0.08)",
	"0px 4px 11px rgba(0, 0, 0, 0.08)",
	"0px 4px 12px rgba(0, 0, 0, 0.08)",
	"0px 4px 12px rgba(0, 0, 0, 0.08)",
	"0px 4px 12px rgba(0, 0, 0, 0.08)",
	"0px 4px 13px rgba(0, 0, 0, 0.08)",
	"0px 4px 13px rgba(0, 0, 0, 0.08)",
	"0px 4px 13px rgba(0, 0, 0, 0.08)",
	"0px 4px 14px rgba(0, 0, 0, 0.08)",
	"0px 4px 14px rgba(0, 0, 0, 0.08)",
	"0px 4px 14px rgba(0, 0, 0, 0.08)",
	"0px 4px 15px rgba(0, 0, 0, 0.08)",
	"0px 4px 15px rgba(0, 0, 0, 0.08)",
	"0px 4px 15px rgba(0, 0, 0, 0.08)",
	"0px 4px 16px rgba(0, 0, 0, 0.08)",
	"0px 4px 16px rgba(0, 0, 0, 0.08)",
	"0px 4px 16px rgba(0, 0, 0, 0.08)",
];

const wsgTheme = createTheme({
	themeName: "worldsmarathons",
	breakpoints: {
		values: common.breakpoints,
	},
	components: {
		MuiLink: {
			styleOverrides: {
				root: {
					color: wmPalette.grey[90],
					textDecorationColor: wmPalette.grey[90],
					cursor: "pointer",
					"&.secondary": {
						color: wmPalette.blue[70],
						textDecorationColor: wmPalette.blue[70],
						cursor: "pointer",
					},
				},
			},
		},
		MuiRadio: {
			defaultProps: {
				color: "primary",
			},
			styleOverrides: {
				root: {
					color: wmPalette.black[50],
				},
				colorSecondary: {
					"&$checked": {
						color: wmPalette.green[70],
					},
				},
			},
		},
		MuiButton: {
			defaultProps: {
				color: "primary",
				disableElevation: true,
				disableRipple: true,
			},
			styleOverrides: {
				root: ({ ownerState }) => ({
					textTransform: "none",
					borderRadius: "8px",
					padding: "8px 24px",
					fontWeight: 600,
					"&.Mui-disabled": {
						color: wmPalette.grey[50],
						hover: {
							"&:hover": {
								backgroundColor: "transparent",
							},
						},
					},
					...(ownerState.variant === "outlined" && {
						border: `2px solid ${wmPalette.black[90]}`,
						color: wmPalette.black[90],
						padding: "8px 24px",
						fontWeight: 600,
						"&:hover": {
							border: `2px solid ${wmPalette.black[100]}`,
							color: wmPalette.black[100],
						},
						...(ownerState.color === "primary" && {
							borderWidth: "2px !important",
							borderColor: wmPalette.green[70],
							fontWeight: 600,
							color: wmPalette.green[70],
							"&:hover": {
								color: wmPalette.green[70],
								background: wmPalette.green[5],
							},
							"&:active": {
								background: wmPalette.green[10],
							},
							"&.Mui-disabled": {
								borderColor: wmPalette.black[40],
								color: wmPalette.black[40],
							},
						}),
						...(ownerState.color === "secondary" && {
							borderWidth: "2px !important",
							borderColor: wmPalette.black[90],
							fontWeight: 600,
							color: wmPalette.black[90],
							"&:hover": {
								color: wmPalette.black[100],
								borderColor: wmPalette.black[100],
								background: wmPalette.white[100],
							},
							"&:active": {
								color: wmPalette.black[50],
								borderColor: wmPalette.black[50],
								background: wmPalette.white[100],
							},
							"&.Mui-disabled": {
								borderColor: wmPalette.black[40],
								color: wmPalette.black[40],
								background: wmPalette.white[100],
							},
						}),
					}),
				}),
				text: {
					color: wmPalette.green[70],
					hover: {
						"&:hover": {
							backgroundColor: wmPalette.green[10],
						},
					},
					focus: {
						"&:focus": {
							backgroundColor: wmPalette.green[20],
						},
					},
				},
			},
			variants: [
				{
					props: { variant: "primary-text-dark" },
					style: {
						color: wmPalette.grey[90],
						"&:hover": {
							backgroundColor: wmPalette.green[10],
						},
						"&:focus": {
							backgroundColor: wmPalette.green[20],
						},
					},
				},
			],
		},
		MuiTextField: {
			defaultProps: {
				variant: "outlined",
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					boxShadow: "none",
					border: `1px solid ${wmPalette.black[30]}`,
					borderLeft: 0,
					borderRight: 0,
				},
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					padding: `0 0 ${baseTheme.spacing(3)} 0`,
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					marginTop: baseTheme.spacing(1),
					paddingTop: 0,
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					"&$focused": {
						color: wmPalette.black[90],
					},
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					paddingTop: common.pxToRemCssValue(48),
					paddingRight: common.pxToRemCssValue(48),
					paddingLeft: common.pxToRemCssValue(48),
				},
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				root: {
					paddingRight: common.pxToRemCssValue(48),
					paddingLeft: common.pxToRemCssValue(48),
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					paddingRight: common.pxToRemCssValue(48),
					paddingLeft: common.pxToRemCssValue(48),
					paddingBottom: common.pxToRemCssValue(32),
					button: {
						fontSize: common.pxToRemCssValue(16),
					},
				},
			},
		},
		MuiPaper: {
			defaultProps: {
				elevation: 0,
			},
			styleOverrides: {
				rounded: {
					borderRadius: "8px",
				},
			},
		},
		MuiCard: {
			defaultProps: {
				elevation: 0,
			},
			styleOverrides: {
				root: {
					borderRadius: "8px",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
					fontWeight: "normal",
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				head: {
					color: wmPalette.black[90],
					fontWeight: "bold",
					backgroundColor: wmPalette.grey[10],
				},
			},
		},
		MuiListItem: {
			styleOverrides: {
				root: {
					"&$selected": {
						background: `${wmPalette.green[5]} !important`,
						color: wmPalette.green[70],
					},
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				hover: {
					"&:hover": {
						background: `${wmPalette.green[5]} !important`,
					},
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					fontWeight: "bold",
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				arrow: {
					color: "#ffffff",
				},
				tooltip: {
					padding: "1rem",
					maxWidth: "25vw",
					fontSize: common.pxToRemCssValue(14),
					backgroundColor: "#ffffff",
					color: "#000000",
					boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.08)",
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				"@global": {
					actionButton: {
						fontWeight: "bold",
					},
				},
			},
		},
		MuiAlert: {
			defaultProps: {
				elevation: 0,
			},
			styleOverrides: {
				root: {
					borderRadius: "8px",
					boxShadow: "none",
				},
				outlinedError: {
					backgroundColor: "#fff",
				},
			},
		},
		MuiAlertTitle: {
			styleOverrides: {
				root: {
					fontWeight: "bold",
				},
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				colorPrimary: {
					color: wmPalette.grey["50"],
				},
				colorSecondary: {
					"&$checked": {
						color: wmPalette.green[70],
					},
				},
			},
		},
	},
	palette: {
		primary: {
			main: wmPalette.green[70],
			dark: wmPalette.green[100],
			light: wmPalette.green[30],
			contrastText: "#ffffff",
		},
		secondary: {
			main: wmPalette.blue[100],
			dark: wmPalette.blue[100],
			light: wmPalette.blue[20],
			contrastText: "#ffffff",
		},
		tertiary: {
			main: wmPalette.blue[100],
			dark: wmPalette.blue[100],
			light: wmPalette.blue[70],
			contrastText: "#ffffff",
		},
		text: {
			primary: "#353E44",
			secondary: "#353E44",
		},
		background: {
			default: wmPalette.grey[20],
		},
		common: {
			white: wmPalette.white[100],
			black: wmPalette.softBlack[100],
		},
		error: {
			main: wmPalette.red[100],
		},
		neutral: {
			main: wmPalette.black[90],
		},
		grey: {
			"100": wmPalette.grey[5],
			"200": wmPalette.grey[10],
			"300": wmPalette.grey[20],
			"400": wmPalette.grey[30],
			"500": wmPalette.grey[40],
			"600": wmPalette.grey[50],
			"700": wmPalette.grey[80],
			"800": wmPalette.grey[90],
			"900": wmPalette.grey[90],
		},
	},
	typography: {
		fontFamily: ["Inter", "Helvetica Neue", "Helvetica", "Arial"].join(","),
		h1: {
			fontSize: common.pxToRemCssValue(24),
			...headings,
		},
		h2: {
			fontSize: common.pxToRemCssValue(20),
			...headings,
		},
		h3: {
			fontSize: common.pxToRemCssValue(18),
			...headings,
		},
		h4: {
			fontSize: common.pxToRemCssValue(16),
			...headings,
		},
		h5: {
			fontSize: common.pxToRemCssValue(14),
			...headings,
		},
		h6: {
			fontSize: common.pxToRemCssValue(12),
			...headings,
		},
		body1: {
			fontSize: common.pxToRemCssValue(16),
		},
		body2: {
			fontSize: common.pxToRemCssValue(16),
			...headings,
		},
		subtitle1: {
			fontSize: common.pxToRemCssValue(14),
		},
		subtitle2: {
			fontSize: common.pxToRemCssValue(14),
			...headings,
		},
		helperText: {
			fontSize: common.pxToRemCssValue(14),
			color: wmPalette.grey[50],
		},
	},
	shadows: shadows as Shadows,
});

export default wsgTheme;
export { wmPalette };
