export type MainCheckoutLayoutParams = {
	orderId: string;
	participantToken: string;
};

export enum PageId {
	TicketSelect = 0,
	PersonalDetails = 2,
	TeamInfo = 3,
	AddOns = 1,
	Payment = 4,
	Confirmation = 5,
	Option = 9,
}

export const RoutePatterns = Object.freeze({
	CHANGE_TICKET: "/change-ticket",
	CLAIM_SPOT: "/claim-spot",
	SELECT_TICKETS: "/select-tickets",
} as const);
