import { Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useOrder from "services/Order/useOrder";
import useTeamTicket from "services/Order/useTeamTicket";
import { MainCheckoutLayoutParams } from "types/common/pages";

interface Props {
	index: number;
	fullName?: string;
}

function TeamInfoSummaryItem({ index, fullName }: Props) {
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const { data: order } = useOrder(orderId);
	const teamTicket = useTeamTicket(orderId);
	const { t } = useTranslation("common");

	const getTeamMemberPrice = useCallback(
		(memberPosition: number) => {
			const lengthAtMemberPosition = memberPosition + 1;
			let memberPriceText = "";
			if (teamTicket) {
				const { includedTeamMemberCount, additionalTeamMemberPrice } =
					teamTicket;

				if (includedTeamMemberCount) {
					memberPriceText =
						lengthAtMemberPosition > includedTeamMemberCount
							? `${additionalTeamMemberPrice} ${order?.convertToCurrency}`
							: t("free.label");
				}
			}

			return (
				memberPriceText.trim() && <Typography>{memberPriceText}</Typography>
			);
		},
		[teamTicket, order]
	);

	return (
		<Stack direction="row" justifyContent="space-between">
			<Typography>
				{fullName?.trim() ? fullName : `Team Member ${index + 1}`}
			</Typography>
			{getTeamMemberPrice(index)}
		</Stack>
	);
}

export default TeamInfoSummaryItem;
