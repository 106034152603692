import axios from "axios";
import { LocaleContext } from "contexts/LocaleProvider";
import { PropsWithChildren, useContext } from "react";
import { axiosErrorHandler } from "services/AxiosErrorHandling";
import { ApiServices } from "services/common/constants";
import { ServiceBaseUrls } from "services/common/constants";
import Contextualizer from "services/ServiceContextualizer";
import { EventProductType } from "types/models/EventProduct/eventProduct.enums";
import { IEventService } from "types/services/eventService.types";

const EventServiceContext = Contextualizer.createContext(
	ApiServices.EventService
);

interface EventServiceProps extends PropsWithChildren {}

function EventService({ children }: EventServiceProps) {
	const { localeState } = useContext(LocaleContext);
	const { currency } = localeState;
	const eventService: IEventService = {
		async getEvent(id) {
			try {
				const { data } = await axios.get(
					`${ServiceBaseUrls.Marathons}/${id}/active-race?currency=${currency}`
				);
				return data;
			} catch (error) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(error);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Marathons}/${id}/active-race?currency=${currency}`,
					});
				}
			}
		},

		async getCustomQuestions({ eventId, raceId }) {
			try {
				const { data } = await axios.get(
					`${ServiceBaseUrls.Marathons}/${eventId}/race/${raceId}/customquestions`
				);

				return data;
			} catch (error) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(error);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Marathons}/${eventId}/race/${raceId}/customquestions`,
					});
				}
			}
		},

		async getEventRaceProducts({ eventId, raceId }) {
			try {
				const { data } = await axios.get(
					`${ServiceBaseUrls.Events}/${eventId}/races/${raceId}/products?\
					productType=${EventProductType.Race}&\
					currency=${currency}`
				);

				return data;
			} catch (e) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(e);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Events}/${eventId}/races/${raceId}/products?\
						productType=${EventProductType.Race}&\
						currency=${currency}`,
					});
				}
			}
		},
	};

	return (
		<EventServiceContext.Provider value={eventService}>
			{children}
		</EventServiceContext.Provider>
	);
}
export const useEventService = () =>
	Contextualizer.use<IEventService>(ApiServices.EventService);

export default EventService;
