import { createContext } from "react";
import { LocaleSettings } from "types/common/global";
import { currencies, languages, measures } from "types/common/models";

export interface LocaleContextProps {
	localeState: LocaleSettings;
	onLocaleStateChange?(
		key: keyof LocaleSettings | "all",
		value: string | LocaleSettings
	): void;
	preventCurrencyChange?: {
		value: boolean;
		setValue: (value: boolean) => void;
	};
	getLocaleStateDisplayText(from: keyof LocaleSettings): string | undefined;
}

export const localeContextDefaults: LocaleContextProps = {
	localeState: {
		language: languages[0].value.toString(),
		currency: currencies[0].value.toString(),
		measure: measures[0].value.toString(),
		modified: false,
	},
	preventCurrencyChange: {
		value: false,
		setValue: () => void 0,
	},
	getLocaleStateDisplayText: () => "",
};

const LocaleContext = createContext<LocaleContextProps>(localeContextDefaults);

export default LocaleContext;
