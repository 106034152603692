import { Box, BoxProps } from "@mui/material";
import { IconName, default as icons } from "./assets";
import themeUtils from "theme/common";

const sizeMap = {
	xs: themeUtils.pxToRemCssValue(16),
	sm: themeUtils.pxToRemCssValue(18),
	md: themeUtils.pxToRemCssValue(24),
	lg: themeUtils.pxToRemCssValue(32),
};

type SizeMapKey = keyof typeof sizeMap;

export interface IconProps
	extends Omit<BoxProps, "component" | "width" | "height"> {
	name: IconName;
	size?: SizeMapKey | [SizeMapKey, SizeMapKey];
	rawSize?: [string, string];
	iconType?: "default";
}

const Icon = ({ name, size = "md", rawSize, ...props }: IconProps) => {
	const Icon = icons[name];

	const boxSize = (() => {
		if (!rawSize) {
			if (typeof size === "string") {
				const resolvedSize = sizeMap[size];
				return [resolvedSize, resolvedSize];
			}

			const width = sizeMap[size[0]];
			const height = sizeMap[size[1]];

			return [width, height];
		}

		return rawSize;
	})();

	return (
		<Box component={Icon} width={boxSize[0]} height={boxSize[1]} {...props} />
	);
};

export default Icon;
