import { Context, createContext, useContext } from "react";
import { ApiServices } from "services/common/constants";
import { Nullable } from "types/common/global";

const contexts = new Map<ApiServices, Context<any | undefined>>();

/**
 * Pattern used by Daniel Hansen (from Dev.to)
 * @see https://dev.to/dansolhan/simple-dependency-injection-functionality-for-react-518j?signin=true
 */
const Contextualizer = {
	createContext: <T>(service: ApiServices): Context<Nullable<T>> => {
		const context = createContext<Nullable<T>>(undefined);
		contexts.set(service, context);
		return context;
	},
	use: <T>(services: ApiServices): T => {
		const context = contexts.get(services);
		if (context === undefined) {
			throw Error(`${ApiServices[services]} was not created`);
		}

		/** This will be used inside a component anyway. It can be ignored here without any
		 * unknown side-effects */

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const service = useContext(context);

		if (service === undefined) {
			throw new Error(
				`You must use ${ApiServices[services]} from within it's service`
			);
		}

		return service;
	},
	clear() {
		contexts.clear();
	},
};

export default Contextualizer;
