import { Box, GridProps, SxProps, useTheme } from "@mui/material";
import { gridContainerStaticSx, gridItemStaticSx } from "./CssGrid.styles";
import { PropsWithChildren, useMemo } from "react";

type StandardBreakpoint = "xs" | "sm" | "md" | "lg" | "xl";

/**
 * Used to replace the Material Grid which makes use of Flex Box instead of the inbuilt
 * Grid layout.
 *
 * @see https://mui.com/material-ui/react-grid/#css-grid-layout
 *
 * __Important__
 *
 * Use this __only__ if the default MUI Grid causes unnecessary layout shifts due to
 * MUI's use of negative margins in their grid. This component was made to fix
 * this issue.
 *
 * @returns CSS Based Grid
 */
function CssGrid(props: PropsWithChildren<GridProps>) {
	const { columns = 12, children, item = false, ...styleProps } = props;

	const theme = useTheme();

	const getGridSx = useMemo<SxProps>(() => {
		if (item) {
			const responsiveSpans = Object.keys(theme.breakpoints.values).map(
				(key) => {
					const breakpoint = key as StandardBreakpoint;
					return {
						[theme.breakpoints.up(breakpoint)]: {
							gridColumn: `span ${props[breakpoint] ?? "auto"}`,
						},
					};
				}
			);

			const itemSx = Object.assign(
				{},
				styleProps,
				gridItemStaticSx,
				...responsiveSpans,
				props.sx
			);

			return itemSx;
		}

		return {
			...gridContainerStaticSx,
			...styleProps,
			gridTemplateColumns: props.gridTemplateColumns
				? props.gridTemplateColumns
				: `repeat(${columns}, 1fr)`,
			gap: props.gap,
		};
	}, [columns, item, theme, props, styleProps]);

	return <Box sx={getGridSx}>{children}</Box>;
}

export default CssGrid;
