export interface PaymentError {
	show: boolean;
	error?: string;
}

export interface PaymentRequirementState extends PaymentError {
	selected: boolean;
}

export const PaymentErrors = {
	REFUNDABLE_BOOKING_UNSELECTED_ERROR: "Refundable booking option not selected",
	PAYMENT_REQUIREMENTS_NOT_MET_ERROR:
		"Terms and Conditions or waivers have not been accepted",
} as const;

export type PayPalButtonOnClickActions = {
	reject: () => Promise<void>;
	resolve: () => Promise<void>;
};
