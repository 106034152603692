import { Button, MenuItem, styled } from "@mui/material";
import { wmPalette } from "theme/WSGTheme";

export const NMDropdownButton = styled(Button)(({ theme }) => ({
	color: wmPalette.black["90"],
	paddingInline: `${theme.themeName === "ahotu" ? ".5rem" : 0}`,
	fontSize: ".875rem",
}));

export const NavigationMenuItem = styled(MenuItem)(({ selected, theme }) => ({
	":is(.Mui-selected, .Mui-disabled)": {
		color: theme.palette.primary.main,
		fontWeight: selected ? "700" : "400",
		backgroundColor: "transparent",
		opacity: 1,
	},
}));
