import { useTranslation } from "react-i18next";
import { Domain } from "types/common/global";
import { PageId } from "types/common/pages";

type UserJourneyMapRouteName =
	| "Main"
	| "TeamTicket"
	| "ChangeTicket"
	| "ClaimSpot";

export interface UserJourneyMapStep {
	key: string;
	name: string;
	pageId: PageId;
	excludeOrigin?: Domain;
}

export type UserJourneyMap = Record<
	UserJourneyMapRouteName,
	UserJourneyMapStep[]
>;

const useUserJourneyMap = (): UserJourneyMap => {
	const { t } = useTranslation(["common"]);
	const userJourneyMap: UserJourneyMap = {
		Main: [
			{
				key: "select-tickets",
				name: t("common:selectTickets.stepper"),
				pageId: PageId.TicketSelect,
				excludeOrigin: "worldsmarathons",
			},
			{
				key: "info",
				name: t("common:personalDetails.stepper"),
				pageId: PageId.PersonalDetails,
			},
			{
				key: "addons",
				name: t("common:addOns.stepper"),
				pageId: PageId.AddOns,
			},
			{
				key: "itab",
				name: t("common:itab.stepper"),
				pageId: PageId.Option,
			},
			{
				key: "payment",
				name: t("common:payment.stepper"),
				pageId: PageId.Payment,
			},
			{
				key: "confirmation",
				name: t("common:confirmation.stepper"),
				pageId: PageId.Confirmation,
			},
		],
		TeamTicket: [
			{
				key: "select-tickets",
				name: t("common:selectTickets.stepper"),
				pageId: PageId.TicketSelect,
				excludeOrigin: "worldsmarathons",
			},
			{
				key: "info",
				name: t("common:personalDetails.stepper"),
				pageId: PageId.PersonalDetails,
			},
			{
				key: "team-info",
				name: t("common:teamInfo.stepper"),
				pageId: PageId.TeamInfo,
			},
			{
				key: "itab",
				name: t("common:itab.stepper"),
				pageId: PageId.Option,
			},
			{
				key: "payment",
				name: t("common:payment.stepper"),
				pageId: PageId.Payment,
			},
			{
				key: "confirmation",
				name: t("common:confirmation.stepper"),
				pageId: PageId.Confirmation,
			},
		],
		ChangeTicket: [
			{
				key: "payment",
				name: t("common:payment.stepper"),
				pageId: PageId.Payment,
			},
			{
				key: "confirmation",
				name: t("common:confirmation.stepper"),
				pageId: PageId.Confirmation,
			},
		],
		ClaimSpot: [
			{
				key: "info",
				name: t("common:personalDetails.stepper"),
				pageId: PageId.PersonalDetails,
			},
			{
				key: "confirmation",
				name: t("common:confirmation.stepper"),
				pageId: PageId.Confirmation,
			},
		],
	};

	return userJourneyMap;
};

export default useUserJourneyMap;
