import { LoadingButton } from "@mui/lab";
import { styled, SxProps, Theme } from "@mui/material";

export const StyledContinueButton = styled(LoadingButton)`
	font-size: 1rem;
	&[data-theme="ahotu"] {
		min-height: 56px;
	}
`;

export const continueButtonError: SxProps<Theme> = {
	display: "block",
	paddingBottom: "0.5rem",
};
