import { SWRResponse } from "swr";

export interface ServiceActionDefaults {
	onError?(err: any): void;
}

export function configureDefaultActions(
	modifiers: SWRResponse,
	actions?: ServiceActionDefaults
) {
	if (actions) {
		const { onError } = actions;
		if (modifiers.error && onError) {
			onError(modifiers.error);
		}
	}
}
