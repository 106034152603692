import {
	Alert,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	FormControl,
	FormControlLabel,
	Link,
	Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import creditSectionSx from "./CreditSection.styles";
import { usePaymentModifier } from "components/Checkout/PaymentSummary/PaymentModifierProvider/PaymentModifierProvider";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useOrder from "services/Order/useOrder";
import { useCreditService } from "services/UserCredits";
import useUserCredits from "services/UserCredits/useUserCredits";
import { MainCheckoutLayoutParams } from "types/common/pages";
import StringUtils from "utils/string-extensions";

const CreditSection = () => {
	const { t } = useTranslation(["payment-page", "common"]);
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const [showAvailableCreditDialog, setShowAvailableCreditDialog] =
		useState(false);
	const {
		isUsingCoupon,
		errorPopoverElement,
		closePopover: closeErrorPopover,
		openPopover: openErrorPopover,
	} = usePaymentModifier();
	const { activateCredits } = useCreditService();
	const { mutate: updateOrder, data: order } = useOrder(orderId);
	const { data: userCredits } = useUserCredits();

	function closeAvailableCreditDialog() {
		setShowAvailableCreditDialog(false);
	}

	async function handleCreditsToggle() {
		if (orderId) {
			const newOrder = await activateCredits(orderId);
			if (newOrder) {
				updateOrder(newOrder, { revalidate: false });
			}
		}
	}

	const isPopoverVisible = isUsingCoupon && Boolean(errorPopoverElement);
	const hasCredits = (userCredits?.balanceConverted ?? 0) > 0;
	const remainingCredits = (() => {
		if (userCredits) {
			return order?.useCredits
				? // If the user is using credits, we need to subtract the credits used from the total balance
				  (userCredits.balanceConverted - order.creditsUsedConverted).toFixed(2)
				: userCredits.balanceConverted.toFixed(2);
		}

		// If the user has no credits or an error occurred, return 0
		return 0;
	})();

	if (!hasCredits) {
		return null;
	}

	return (
		<>
			<Divider />
			<Box
				aria-owns={isPopoverVisible ? errorPopoverElement?.id : undefined}
				aria-haspopup="true"
			>
				<Stack sx={creditSectionSx.creditSectionContainerSx} gap="0.5rem">
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						sx={{ width: "100%" }}
						onMouseEnter={isUsingCoupon ? openErrorPopover : undefined}
						onMouseLeave={isUsingCoupon ? closeErrorPopover : undefined}
					>
						<FormControl
							disabled={isUsingCoupon}
							sx={creditSectionSx.toggleSx.formControl}
						>
							<FormControlLabel
								label={t("payment-page:paymentModifier.credits.title")}
								control={
									<Checkbox
										checked={Boolean(order?.creditsUsedConverted)}
										onChange={handleCreditsToggle}
										sx={creditSectionSx.toggleSx.checkbox}
									/>
								}
								componentsProps={{
									typography: {
										fontSize: "inherit",
										sx: creditSectionSx.toggleSx.label,
									},
								}}
							/>
						</FormControl>
						{order?.creditsUsedConverted && (
							<Typography
								fontSize="inherit"
								color={(theme) => theme.palette.error.main}
							>
								- {order?.creditsUsedConverted} {order?.convertToCurrency}
							</Typography>
						)}
					</Stack>
					<Link
						sx={creditSectionSx.getOpenRemainingCreditsDialogLinkSx}
						onClick={() => setShowAvailableCreditDialog(true)}
					>
						{t("payment-page:paymentModifier.viewCurrentBalance")}
					</Link>
				</Stack>
				{/* Remaining Credit Display Dialog */}
				<Dialog
					open={showAvailableCreditDialog}
					onClose={closeAvailableCreditDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					PaperProps={{
						sx: creditSectionSx.remainingCreditAlertSx,
					}}
				>
					<DialogContent sx={{ padding: "1.5rem" }}>
						<Stack gap="1.5rem" sx={{ width: "100%" }}>
							<Typography
								id="alert-dialog-title"
								component="h1"
								variant="h6"
								sx={{ fontSize: { xs: "1.125rem", md: "1rem" } }}
							>
								{t("payment-page:paymentModifier.currentBalance")}
							</Typography>
							<Alert icon={false} sx={creditSectionSx.remainingCreditAlertSx}>
								<Stack
									id="alert-dialog-description"
									component={Typography}
									direction="row"
									fontSize="1rem"
									alignItems="baseline"
									gap=".5rem"
								>
									<Typography
										variant="h2"
										component="span"
										sx={creditSectionSx.creditValueTextSx}
									>
										{remainingCredits}
									</Typography>
									<Typography component="span" variant="body1">
										{t(
											remainingCredits > 1
												? "payment-page:paymentModifier.credits.suffix_plural"
												: "payment-page:paymentModifier.credits.suffix"
										)}
									</Typography>
								</Stack>
							</Alert>
							<Box fontSize={{ xs: "0.875rem", md: "1rem" }}>
								<Typography fontSize="inherit">
									{`1 ${StringUtils.capitalizeFirstLetter(
										t("payment-page:paymentModifier.credits.suffix")
									)} = 1 ${order?.convertToCurrency}`}
								</Typography>
								<Typography variant="helperText" fontSize="inherit">
									{t("payment-page:paymentModifier.credits.conversionMessage")}
								</Typography>
							</Box>
						</Stack>
					</DialogContent>
					<DialogActions sx={creditSectionSx.remainingCreditsDialogActionsSx}>
						<Button
							variant="primary-text-dark"
							onClick={closeAvailableCreditDialog}
						>
							{t("common:close")}
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		</>
	);
};

export default CreditSection;
