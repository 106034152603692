import PaymentContext from "contexts/PaymentProvider/PaymentContext";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { PaymentTypes } from "types/models/Payment";
import { PaymentError, PaymentRequirementState } from "./PaymentContext.types";

function PaymentProvider({ children }: PropsWithChildren) {
	const [expandedOption, setExpandedOption] = useState<PaymentTypes | false>(
		"stripe"
	);
	const [disabledOptions, setDisabledOptions] = useState<PaymentTypes[]>([]);
	const [refundableBookingOptionSelected, setRefundableBookingOptionSelected] =
		useState(false);

	const [isCurrencySupported, setIsCurrencySupported] = useState(false);
	const [refundableBookingError, setRefundableBookingError] =
		useState<PaymentError>({
			show: false,
		});
	const [paymentRequirementError, setPaymentRequirementError] =
		useState<PaymentRequirementState>({
			show: false,
			selected: false,
		});

	const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

	useEffect(() => {
		setRefundableBookingError(
			!refundableBookingOptionSelected
				? (prevState) => ({
						...prevState,
						error: "Please select one of the options",
				  })
				: { show: false, error: undefined }
		);
	}, [refundableBookingOptionSelected]);

	const refundableBookingErrorValues = useMemo(
		() => ({
			set: setRefundableBookingError,
			get error() {
				return refundableBookingError.error;
			},
			show: refundableBookingError.show,
		}),
		[refundableBookingError]
	);

	const paymentRequirementsValues = useMemo(
		() => ({
			set: setPaymentRequirementError,
			get error() {
				return paymentRequirementError.error;
			},
			show: paymentRequirementError.show,
			selected: paymentRequirementError.selected,
		}),
		[paymentRequirementError]
	);

	const value = useMemo(
		() => ({
			expandedOption,
			setExpandedOption,
			refundableBookingOptionSelected,
			setRefundableBookingOptionSelected,
			isCurrencySupported: {
				current: isCurrencySupported,
				set: setIsCurrencySupported,
			},
			refundableBookingError: refundableBookingErrorValues,
			paymentRequirements: paymentRequirementsValues,
			disabledOptions: {
				value: disabledOptions,
				set: setDisabledOptions,
			},
			isPaymentProcessing,
			setIsPaymentProcessing,
		}),
		[
			expandedOption,
			refundableBookingOptionSelected,
			isCurrencySupported,
			refundableBookingErrorValues,
			paymentRequirementsValues,
			disabledOptions,
			isPaymentProcessing,
		]
	);

	return (
		<PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
	);
}

export default PaymentProvider;
