import { Popover, Typography } from "@mui/material";
import PaymentModifierContext from "components/Checkout/PaymentSummary/PaymentModifierProvider/PaymentModifierContext";
import {
	MouseEvent,
	PropsWithChildren,
	useContext,
	useEffect,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Order } from "services/common/models/order";
import modifierSx from "./PaymentModifierProvider.styles";

export type PaymentModifierType = "coupon" | "credit";

interface PaymentModifierProviderProps extends PropsWithChildren {
	order: Order;
	hideChildren?: boolean;
}

const PaymentModifierProvider = ({
	order,
	children,
	hideChildren = false,
}: PaymentModifierProviderProps) => {
	const [currentModifier, setCurrentModifier] = useState<PaymentModifierType>();
	// Anchor element for any additional component that modifies the price.
	const [modifierErrorAnchorEl, setModifierErrorAnchorEl] =
		useState<HTMLElement>();

	function openPopover(e: MouseEvent<HTMLDivElement>) {
		setModifierErrorAnchorEl(e.currentTarget);
	}

	function closePopover() {
		setModifierErrorAnchorEl(undefined);
	}

	const isModifierErrorPopoverVisible =
		Boolean(modifierErrorAnchorEl) && currentModifier != undefined;

	useEffect(() => {
		if (order.coupon) {
			setCurrentModifier("coupon");
		} else if (order.creditsUsedConverted) {
			setCurrentModifier("credit");
		}

		return () => {
			setCurrentModifier(undefined);
		};
	}, [order]);

	const { t } = useTranslation(["payment-page"]);

	return (
		<PaymentModifierContext.Provider
			value={{
				isUsingCoupon: currentModifier === "coupon",
				isUsingCredits: currentModifier === "credit",
				setCurrentPaymentModifierTo: setCurrentModifier,
				errorPopoverElement: modifierErrorAnchorEl,
				openPopover,
				closePopover,
			}}
		>
			{!hideChildren && children}
			{/* Popover for modifiers that clash when either is active (ex: Coupons and Credits)*/}
			<Popover
				id="coupon-and-credit-not-allowed-popover"
				anchorEl={modifierErrorAnchorEl}
				open={isModifierErrorPopoverVisible}
				onClose={closePopover}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				sx={modifierSx.errorPopoverSx.root}
				PaperProps={{
					sx: modifierSx.errorPopoverSx.paper,
				}}
				disableRestoreFocus
				disableScrollLock
			>
				<Typography fontSize=".875rem">
					{t("payment-page:paymentModifier.couponAndCreditNotAllowed")}
				</Typography>
			</Popover>
		</PaymentModifierContext.Provider>
	);
};

export const usePaymentModifier = () => {
	const context = useContext(PaymentModifierContext);
	if (context === undefined) {
		throw new Error(
			"usePaymentModifier must be used within a PaymentModifierProvider"
		);
	}
	return context;
};

export default PaymentModifierProvider;
