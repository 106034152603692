import { PropsWithChildren } from "react";
import EventService from "services/Event";
import OrderService from "services/Order";
import AnalyticsService from "services/Analytics";
import AuthenticationService from "services/Authentication";
import CreditService from "./UserCredits";

/**
 * This component is a global wrapper for all service classes.
 * Any additional services need to be added here in order of their requirement
 *
 * Ex: Most times, the event is derived from the Order so the OrderService will be hoisted
 * above an EventService (if at all any)
 */
const ContextualizerServiceProvider = ({ children }: PropsWithChildren) => {
	return (
		<AuthenticationService>
			<AnalyticsService>
				<OrderService>
					<EventService>
						<CreditService>{children}</CreditService>
					</EventService>
				</OrderService>
			</AnalyticsService>
		</AuthenticationService>
	);
};

export default ContextualizerServiceProvider;
