export enum ProductType {
	Race,
	Extra,
	All,
	Participant,
	PackageDeal,
	////// DEPRECATED ////////
	Donation,
	/////////////////////////
	AddOn,
	GiftCard,
	ServiceFee,
	TransactionFee,
}

export const ProductId = {
	TicketUpgrade: "ticketupgrade",
} as const;

export type ProductId = typeof ProductId[keyof typeof ProductId];

export type ProductTypeStr =
	| "race"
	| "extra"
	| "all"
	| "participant"
	| "packageDeal"
	| "donation"
	| "addon"
	| "giftCard"
	| "serviceFee"
	| "transactionFee";
