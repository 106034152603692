import { Button, MobileStepper, styled, SxProps, Theme } from "@mui/material";
import AhotuTheme from "theme/AhotuTheme";
import { wmPalette } from "theme/WSGTheme";
import common from "theme/common";

export const stepperSx = (itemCount: number): SxProps<Theme> => ({
	my: "3rem",
	mx: "auto",
	maxWidth: `${100 - 100 / itemCount}%`,
	[".MuiStepIcon-root"]: {
		color: (theme) => theme.palette.grey[500],
		["&:is(.Mui-active, .Mui-completed)"]: {
			color: (theme) =>
				theme.themeName === "ahotu"
					? theme.palette.secondary.main
					: theme.palette.primary.main,
		},
	},
});

export const stepperTextSx: SxProps<Theme> = {
	["span"]: {
		color: (theme) =>
			theme.themeName === "worldsmarathons"
				? wmPalette.grey[50]
				: AhotuTheme.AhotuColorPalette.grey[50],
		["&.MuiStepLabel-label"]: {
			fontSize: common.pxToRemCssValue(14),
			fontWeight: 400,
			["&.MuiStepLabel-label.Mui-active"]: {
				fontWeight: 500,
			},
		},
	},
};

export const AhotuMobileStepper = styled(MobileStepper)(({ theme }) => ({
	backgroundColor:
		theme.themeName === "ahotu"
			? AhotuTheme.AhotuColorPalette.grey[10]
			: "#f9f9f9",
	boxShadow: "none",
}));

export const MobileStepperActionButton = styled(Button)(() => ({
	["&.MuiButton-root"]: {
		backgroundColor: "transparent",
		paddingInline: 0,
	},
}));
