import useMutation from "swr/mutation";
import axios, { AxiosError } from "axios";
import { ServiceBaseUrls } from "services/common/constants";
import useCurrency from "services/Localization/useCurrency";
import { RequestWithOrderId } from "types/services/common.types";
import {
	ICreatePaymentIntentionRequest,
	IPageStateUpdateRequest,
	ISubmitPersonalDetailsRequest,
	IAddTicketRequest,
	EmailValidationRequest,
	ApplyCouponCodeRequest,
	AddAdditionalTeamMemberRequest,
	ISendToEmailRequest,
	AddTeamMemberListRequest,
	DeleteParticipantRequest,
	PostSubmitPersonalDetailsActions,
	PersonalDetailsErrorMessageMap,
	ISendToEmailResponse,
	CreatePayPalIntentRequest,
	CreatePayPalIntentMutation,
	ContinueErrorMessageMap,
	IUpdateRefundPreferenceRequest,
	PostAcceptInvitationActions,
	IAcceptInvitationRequest,
	AcceptInvitationErrorMessageMap,
	PostEmailValidationMutation,
} from "types/services/orderService.types";
import { Order } from "services/common/models/order";
import { useSnackbar } from "notistack";
import { PaymentIntent } from "@stripe/stripe-js";
import { MutationExtraArg, PostMutationActions } from "types/common/swr";
import { useLocale } from "contexts/LocaleProvider/LocaleProvider";
import { PaypalIntent } from "types/models/PaypalRequestBody/PaypalIntent";
import { axiosErrorHandler } from "services/AxiosErrorHandling";
import { ITeamInfoMember } from "types/models/TeamInfo/teamInfoMember";

type OrderMutationKey =
	| "validateEmail"
	| "submitPersonalDetails"
	| "removeCouponCode"
	| "applyCouponCode"
	| "addParticipant"
	| "deleteParticipant"
	| "updateCheckoutPageState"
	| "createPaymentIntention"
	| "addAdditionalTeamMember"
	| "sendToEmail"
	| "addTeamMemberList"
	| "createPaypalPaymentIntent"
	| "updateRefundPreference"
	| "acceptInvitation";

export const useEmailValidationMutation = (
	orderId?: string,
	participantId?: string,
	actions?: PostEmailValidationMutation
) => {
	const currency = useCurrency();
	const { enqueueSnackbar } = useSnackbar();
	let capturedEmail: string;
	return useMutation<boolean, any, OrderMutationKey, EmailValidationRequest>(
		"validateEmail",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<EmailValidationRequest>
		) => {
			capturedEmail = arg.email;
			const queries = `email=${arg.email}&currency=${currency}`;
			const { status } = await axios.put(
				`${ServiceBaseUrls.Order}/${orderId}/participants/${participantId}/validateEmail?${queries}`,
				{ email: arg.email }
			);
			return status === 200 || status === 204;
		},
		{
			onError(err: AxiosError) {
				const email = capturedEmail;
				const queries = `email=${email}&currency=${currency}`;
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/participants/${participantId}/validateEmail?${queries}`,
						orderId: orderId ?? "",
						payload: JSON.stringify({ email: email }),
					});
				}
				const isAxiosError = axios.isAxiosError(err);
				if (isAxiosError) {
					const errorData = err.response?.data;

					if (Array.isArray(errorData)) {
						// HACK: This is a temporary fix for the error message
						// that is returned from the API. The API should return
						// an object instead of an array.
						if (errorData.some((d) => typeof d === "string")) {
							if (
								(errorData as string[])[0] ===
								PersonalDetailsErrorMessageMap.EmailInvalidCode
							) {
								actions?.onEmailInvalidError?.();
								return;
							}
						}
					}
				}
				enqueueSnackbar(err.message, { variant: "error" });
			},
		}
	);
};

export const useSubmitPersonalDetailsMutation = (
	actions?: PostSubmitPersonalDetailsActions
) => {
	const currency = useCurrency();
	const { enqueueSnackbar } = useSnackbar();
	let capturedOrderId: string;
	let capturedPayload: string;
	return useMutation<
		Order,
		any,
		OrderMutationKey,
		ISubmitPersonalDetailsRequest
	>(
		"submitPersonalDetails",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<ISubmitPersonalDetailsRequest>
		) => {
			if (!arg.orderId) {
				throw new AxiosError(
					"Order Id is not provided",
					AxiosError.ERR_BAD_REQUEST
				);
			}

			capturedOrderId = arg.orderId;
			capturedPayload = JSON.stringify(arg.participants);
			const { data } = await axios.put<Order>(
				`${ServiceBaseUrls.Order}/${arg.orderId}/participants?currency=${currency}`,
				arg.participants
			);
			return data;
		},
		{
			onError(err) {
				const isAxiosError = axios.isAxiosError(err);
				const apiError = axiosErrorHandler(axios).getErrorMessage(err);
				const orderId = capturedOrderId;
				const payload = capturedPayload;
				if (isAxiosError) {
					const errorData = err.response?.data;

					if (Array.isArray(errorData)) {
						// HACK: This is a temporary fix for the error message
						// that is returned from the API. The API should return
						// an object instead of an array.
						if (errorData.some((d) => typeof d === "object")) {
							const errorObj = errorData as Array<Record<string, any>>;
							const message =
								Object.values<string>(errorObj[0])[0] === "EMAIL_NOT_VALID"
									? "This is not a valid email address"
									: Object.values<string>(errorObj[0])[0];
							enqueueSnackbar(message, { variant: "error" });
							if (typeof newrelic !== "undefined") {
								newrelic.noticeError(Object.values<string>(errorObj[0])[0], {
									requestUrl: apiError.requestUrl,
									orderId: orderId,
									payload: apiError.payload,
								});
							}
						}

						if (errorData.some((d) => typeof d === "string")) {
							if (
								(errorData as string[])[0] ===
								PersonalDetailsErrorMessageMap.EmailMismatch
							) {
								actions?.onEmailMismatchError?.();
							}
						}
					}
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: apiError.requestUrl,
							orderId: orderId,
							payload: apiError.payload,
						});
					}
					if (actions?.onEmailMismatchError !== undefined) {
						actions?.onEmailMismatchError();
					}
					return;
				}
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/participants?currency=${currency}`,
						orderId: orderId,
						payload: payload,
					});
				}
				enqueueSnackbar(
					"There was an issue when submitting your personal details",
					{ variant: "error" }
				);
			},
		}
	);
};

export const useRemoveCouponCodeMutation = () => {
	const { enqueueSnackbar } = useSnackbar();
	let capturedOrderId: string;
	return useMutation<boolean, any, OrderMutationKey>(
		"removeCouponCode",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<RequestWithOrderId>
		) => {
			capturedOrderId = arg.orderId;
			const { status } = await axios.delete(
				`${ServiceBaseUrls.Order}/${arg.orderId}/removecoupon`
			);
			return status === 200;
		},
		{
			onError(err: AxiosError) {
				const orderId = capturedOrderId;
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/removecoupon`,
						orderId: orderId,
					});
				}
				enqueueSnackbar(err.message, { variant: "error" });
			},
		}
	);
};

export const useAddCouponCodeMutation = () => {
	const currency = useCurrency();

	const { enqueueSnackbar } = useSnackbar();
	let capturedOrderId: string;
	let capturedCouponCode: string;
	return useMutation<Order, any, OrderMutationKey, ApplyCouponCodeRequest>(
		"applyCouponCode",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<ApplyCouponCodeRequest>
		) => {
			capturedOrderId = arg.orderId;
			capturedCouponCode = arg.couponCode;
			const { data } = await axios.put<Order>(
				`${ServiceBaseUrls.Order}/${arg.orderId}/applyCoupon?code=${arg.couponCode}&currency=${currency}`
			);
			return data;
		},
		{
			onError(err: AxiosError) {
				const orderId = capturedOrderId;
				const couponCode = capturedCouponCode;
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/applyCoupon?code=${couponCode}&currency=${currency}`,
						orderId: orderId,
					});
				}
				enqueueSnackbar(err.message, { variant: "error" });
			},
		}
	);
};

export const useAddTicketMutation = () => {
	const currency = useCurrency();
	const { enqueueSnackbar } = useSnackbar();

	let capturedOrderId: string;
	let capturedPayload: string;
	return useMutation<Order, any, OrderMutationKey, IAddTicketRequest>(
		"addParticipant",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<IAddTicketRequest>
		) => {
			if (!arg.orderId) {
				throw new AxiosError(
					"Order Id is not provided",
					AxiosError.ERR_BAD_REQUEST
				);
			}

			capturedOrderId = arg.orderId;
			capturedPayload = JSON.stringify(arg.data);
			const { data } = await axios.post<Order>(
				`${ServiceBaseUrls.Order}/${arg.orderId}/multipleparticipants?currency=${currency}`,
				arg.data
			);
			return data;
		},
		{
			onError(err: AxiosError) {
				const orderId = capturedOrderId;
				const payload = capturedPayload;
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/multipleparticipants?currency=${currency}`,
						orderId: orderId,
						payload: payload,
					});
				}
				enqueueSnackbar(err.message, { variant: "error" });
			},
		}
	);
};

export const useCheckoutPageStateMutation = (actions?: PostMutationActions) => {
	const currency = useCurrency();
	const { enqueueSnackbar } = useSnackbar();

	let capturedOrderId: string;
	let capturedState: number;
	let capturedCurrency: string;
	return useMutation<Order, any, OrderMutationKey, IPageStateUpdateRequest>(
		"updateCheckoutPageState",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<IPageStateUpdateRequest>
		) => {
			if (!arg.orderId) {
				throw new AxiosError(
					"Order Id is not provided",
					AxiosError.ERR_BAD_REQUEST
				);
			}

			capturedOrderId = arg.orderId;
			capturedState = arg.state ?? 0;
			capturedCurrency = arg.currency ?? currency ?? "";
			const { data } = await axios.put<Order>(
				arg.state !== undefined
					? `${ServiceBaseUrls.Order}/${arg.orderId}/setstate/${arg.state}?currency=${currency}`
					: `${ServiceBaseUrls.Order}/${arg.orderId}/continue?currency=${
							arg.currency || currency
					  }`
			);

			return data;
		},
		{
			onError(err) {
				const isAxiosError = axios.isAxiosError(err);
				const apiError = axiosErrorHandler(axios).getErrorMessage(err);
				const orderId = capturedOrderId;
				const state = capturedState;
				if (isAxiosError) {
					const errorData = err.response?.data;

					if (Array.isArray(errorData)) {
						// HACK: This is a temporary fix for the error message
						// that is returned from the API. The API should return
						// an object instead of an array.
						if (errorData.some((d) => typeof d === "object")) {
							const errorObj = err.response?.data as Array<Record<string, any>>;
							enqueueSnackbar(Object.values<string>(errorObj[0])[0], {
								variant: "error",
							});
							if (typeof newrelic !== "undefined") {
								newrelic.noticeError(Object.values<string>(errorObj[0])[0], {
									requestUrl: apiError.requestUrl,
									orderId: orderId,
								});
							}
						}
						if (errorData.some((d) => typeof d === "string")) {
							if (
								(errorData as string[])[0] ===
								ContinueErrorMessageMap.ValidationError
							) {
								actions?.onComplete?.();
							}
						}
					}
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: apiError.requestUrl,
							orderId: orderId,
						});
					}
					return;
				}
				enqueueSnackbar("Failed to update page state", { variant: "error" });
				actions?.onComplete?.();
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl:
							state !== undefined
								? `${ServiceBaseUrls.Order}/${orderId}/setstate/${state}?currency=${currency}`
								: `${ServiceBaseUrls.Order}/${orderId}/continue?currency=${
										capturedCurrency || currency
								  }`,
						orderId: orderId,
					});
				}
			},
			onSuccess: actions?.onComplete,
		}
	);
};

export const useCreatePaymentIntentionMutation = () => {
	const { enqueueSnackbar } = useSnackbar();
	let capturedOrderId: string;
	let capturedParams: string;
	return useMutation<
		string | PaymentIntent,
		any,
		OrderMutationKey,
		ICreatePaymentIntentionRequest
	>(
		"createPaymentIntention",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<ICreatePaymentIntentionRequest>
		) => {
			if (!arg.orderId) {
				throw new AxiosError(
					"Order Id is not provided",
					AxiosError.ERR_BAD_REQUEST
				);
			}

			const searchParams = new URLSearchParams();
			if (arg.paymentIntentId) {
				searchParams.append("paymentIntentId", arg.paymentIntentId);
			}
			if (arg.skipValidation) {
				searchParams.append("skipValidation", "true");
			}

			capturedOrderId = arg.orderId;
			capturedParams = searchParams.toString();
			const res = await axios.post<PaymentIntent | string>(
				`${ServiceBaseUrls.Order}/${arg.orderId}/createpaymentintention`,
				null,
				{
					params: searchParams,
				}
			);

			return res.data;
		},
		{
			onError(err: AxiosError) {
				const orderId = capturedOrderId;
				const params = capturedParams;
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/createpaymentintention`,
						orderId: orderId,
						params: params,
					});
				}
				enqueueSnackbar(err.message, { variant: "error" });
			},
		}
	);
};

export const useAddTeamMemberListMutation = (actions?: PostMutationActions) => {
	const currency = useCurrency();
	const localeState = useLocale();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	let capturedOrderId: string;
	let capturedIsSkipValidateTeamName: boolean;
	let capturedFormData: {
		teamName?: string | undefined;
		members: ITeamInfoMember[];
	};
	return useMutation<Order, any, OrderMutationKey, AddTeamMemberListRequest>(
		"addTeamMemberList",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<AddTeamMemberListRequest>
		) => {
			const { orderId, isSkipValidateTeamName = false, ...formData } = arg;
			capturedOrderId = orderId;
			capturedIsSkipValidateTeamName = isSkipValidateTeamName;
			capturedFormData = { ...formData };
			const { data } = await axios.post<Order>(
				`${ServiceBaseUrls.Order}/${orderId}/teammembers?currency=${currency}`,
				{ ...formData, isSkipValidateTeamName, lng: localeState.language }
			);

			return data;
		},
		{
			onError: (err: AxiosError) => {
				const orderId = capturedOrderId;
				const isSkipValidateTeamName = capturedIsSkipValidateTeamName;
				const formData = capturedFormData;
				closeSnackbar();
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if (err.response?.data[0] == "TEAM_NAME_CANT_DUPLICATE") {
					enqueueSnackbar("Team name already exists", { variant: "error" });
				} else {
					enqueueSnackbar("Failed to submit team info", { variant: "error" });
				}
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/teammembers?currency=${currency}`,
						orderId: orderId,
						payload: JSON.stringify({
							...formData,
							isSkipValidateTeamName,
							lng: localeState.language,
						}),
					});
				}
				actions?.onComplete?.();
			},
		}
	);
};

export const useAddTeamMemberMutation = () => {
	const currency = useCurrency();

	const { enqueueSnackbar } = useSnackbar();
	let capturedOrderId: string;
	let capturedPayload: string;
	return useMutation<
		Order,
		any,
		OrderMutationKey,
		AddAdditionalTeamMemberRequest
	>(
		"addAdditionalTeamMember",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<AddAdditionalTeamMemberRequest>
		) => {
			const { orderId, ...requestData } = arg;
			capturedOrderId = orderId;
			capturedPayload = JSON.stringify(requestData);
			const res = await axios.post<Order>(
				`${ServiceBaseUrls.Order}/${orderId}/teammember?currency=${currency}`,
				requestData
			);

			return res.data;
		},
		{
			onError(err: AxiosError) {
				const orderId = capturedOrderId;
				const payload = capturedPayload;
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/teammember?currency=${currency}`,
						orderId: orderId,
						payload: payload,
					});
				}
				enqueueSnackbar(err.message, { variant: "error" });
			},
		}
	);
};

export const useResendEmailMutation = () => {
	const { enqueueSnackbar } = useSnackbar();

	let capturedOrderId: string;
	let capturedEmail: string;
	let capturedShouldSendReceipt: boolean;
	return useMutation<
		ISendToEmailResponse,
		any,
		OrderMutationKey,
		ISendToEmailRequest
	>(
		"sendToEmail",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<ISendToEmailRequest>
		) => {
			if (!arg.orderId) {
				throw new AxiosError(
					"Order Id is not provided",
					AxiosError.ERR_BAD_REQUEST
				);
			}

			capturedOrderId = arg.orderId;
			capturedEmail = arg.email ?? "";
			capturedShouldSendReceipt = arg.shouldSendReceipt;
			const { data } = await axios.post<boolean>(
				`${ServiceBaseUrls.Order}/${arg.orderId}/sendtoemail`,
				{ email: arg.email, shouldSendReceipt: arg.shouldSendReceipt }
			);
			return { success: data, email: arg.email } as ISendToEmailResponse;
		},
		{
			onError(err) {
				const orderId = capturedOrderId;
				const email = capturedEmail;
				const shouldSendReceipt = capturedShouldSendReceipt;
				const isAxiosError = axios.isAxiosError(err);
				const apiError = axiosErrorHandler(axios).getErrorMessage(err);
				if (isAxiosError) {
					const errorObj = err.response?.data as Array<Record<string, any>>;
					enqueueSnackbar(Object.values<string>(errorObj[0])[0], {
						variant: "error",
					});
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: apiError.requestUrl,
							orderId: orderId,
							payload: JSON.stringify({
								email: email,
								shouldSendReceipt: shouldSendReceipt,
							}),
						});
					}
					return;
				}
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/sendtoemail`,
						orderId: orderId,
						payload: JSON.stringify({
							email: email,
							shouldSendReceipt: shouldSendReceipt,
						}),
					});
				}
				enqueueSnackbar(
					"There was an issue when sending the confirmation email",
					{
						variant: "error",
					}
				);
			},
		}
	);
};

export const useRemoveParticipantMutation = (actions?: PostMutationActions) => {
	const currency = useCurrency();

	let capturedOrderId: string;
	let capturedParticipantId: string;
	return useMutation<Order, any, OrderMutationKey, DeleteParticipantRequest>(
		"deleteParticipant",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<DeleteParticipantRequest>
		) => {
			capturedOrderId = arg.orderId;
			capturedParticipantId = arg.participantId;
			const { data } = await axios.delete(
				`${ServiceBaseUrls.Order}/${arg.orderId}/participants/${arg.participantId}?currency=${currency}`
			);
			return data;
		},
		{
			onError(err) {
				const orderId = capturedOrderId;
				const participantId = capturedParticipantId;
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/participants/${participantId}?currency=${currency}`,
						orderId: orderId,
					});
				}
				actions?.onComplete;
			},
			onSuccess: actions?.onComplete,
		}
	);
};

export const useCreatePayPalIntentMutation = ({
	onIntentFail,
}: CreatePayPalIntentMutation) => {
	const currency = useCurrency();
	let capturedOrderId: string;
	return useMutation<
		PaypalIntent,
		AxiosError<string[] | object>,
		OrderMutationKey,
		CreatePayPalIntentRequest
	>(
		"createPaypalPaymentIntent",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<CreatePayPalIntentRequest>
		) => {
			capturedOrderId = arg.orderId;
			const { data } = await axios.post<PaypalIntent>(
				`${ServiceBaseUrls.Order}/${arg.orderId}/paypalpay?currency=${currency}`
			);
			return data;
		},
		{
			onError: (err: AxiosError<string[] | object>) => {
				const orderId = capturedOrderId;
				if (Array.isArray(err.response?.data)) {
					onIntentFail((err.response?.data as string[])[0]);
				}
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/paypalpay?currency=${currency}`,
						orderId: orderId,
					});
				}
			},
		}
	);
};

export const useUpdateRefundPreferenceMutation = () => {
	const { enqueueSnackbar } = useSnackbar();
	const currency = useCurrency();
	let capturedOrderId: string;
	let capturedNeedsRefund: boolean;
	return useMutation<
		Order,
		any,
		OrderMutationKey,
		IUpdateRefundPreferenceRequest
	>(
		"updateRefundPreference",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<IUpdateRefundPreferenceRequest>
		) => {
			if (!arg.orderId) {
				throw new AxiosError(
					"Order Id is not provided",
					AxiosError.ERR_BAD_REQUEST
				);
			}
			capturedOrderId = arg.orderId;
			capturedNeedsRefund = arg.needsRefund;
			const { data } = await axios.put<Order>(
				`${ServiceBaseUrls.Order}/${arg.orderId}/refund?selected=${arg.needsRefund}&currency=${currency}`
			);
			return data;
		},
		{
			onError(err) {
				const orderId = capturedOrderId;
				const needsRefund = capturedNeedsRefund;
				const message = "Failed to update payment preference";
				enqueueSnackbar(message, { variant: "error" });
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/refund?selected=${needsRefund}&currency=${currency}`,
						orderId: orderId,
					});
				}
			},
		}
	);
};

export const useAcceptInvitationMutation = (
	actions?: PostAcceptInvitationActions
) => {
	const currency = useCurrency();
	const { enqueueSnackbar } = useSnackbar();
	let capturedOrderId: string;
	let capturedPayload: string;
	let capturedParticipantToken: string;
	return useMutation<Order, any, OrderMutationKey, IAcceptInvitationRequest>(
		"acceptInvitation",
		async (
			_: OrderMutationKey,
			{ arg }: MutationExtraArg<IAcceptInvitationRequest>
		) => {
			if (!arg.orderId) {
				throw new AxiosError(
					"Order Id is not provided",
					AxiosError.ERR_BAD_REQUEST
				);
			}
			const participants = [];
			participants.push(arg.participant);
			capturedOrderId = arg.orderId;
			capturedPayload = JSON.stringify(participants);
			capturedParticipantToken = arg.participantToken;

			const { data } = await axios.put<Order>(
				`${ServiceBaseUrls.Order}/${arg.orderId}/participants?currency=${currency}&token=${arg.participantToken}`,
				participants
			);
			return data;
		},
		{
			onError(err) {
				const isAxiosError = axios.isAxiosError(err);
				const apiError = axiosErrorHandler(axios).getErrorMessage(err);
				const orderId = capturedOrderId;
				const payload = capturedPayload;
				const participantToken = capturedParticipantToken;
				if (isAxiosError) {
					const errorData = err.response?.data;

					if (Array.isArray(errorData)) {
						// HACK: This is a temporary fix for the error message
						// that is returned from the API. The API should return
						// an object instead of an array.
						if (errorData.some((d) => typeof d === "object")) {
							const errorObj = err.response?.data as Array<Record<string, any>>;
							enqueueSnackbar(Object.values<string>(errorObj[0])[0], {
								variant: "error",
							});
						}
						if (errorData.some((d) => typeof d === "string")) {
							if (
								(errorData as string[])[0] ===
								AcceptInvitationErrorMessageMap.EmailMismatch
							) {
								actions?.onEmailMismatchError?.();
							}
						}
					}
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: apiError.requestUrl,
							orderId: orderId,
							payload: apiError.payload,
						});
					}
					return;
				}
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/participants
						?currency=${currency}
						&token=${participantToken}`,
						orderId: orderId,
						payload: payload,
					});
				}
				enqueueSnackbar("There was an issue when claiming your spot", {
					variant: "error",
				});
			},
		}
	);
};
