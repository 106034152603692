import {
	AccordionSummary,
	Box,
	Stack,
	styled,
	SxProps,
	Theme,
} from "@mui/material";
import { wmPalette } from "theme/WSGTheme";

interface AccordionContainerProps {
	isOpen: boolean;
}

export const AccordionContainer = styled(Box, {
	shouldForwardProp: (propName) => propName !== "isOpen",
})<AccordionContainerProps>(({ isOpen }) => ({
	position: "fixed",
	width: "calc(100% - 1rem)",
	bottom: `${isOpen ? "8px" : "16px"}`,
	left: "50%",
	overflow: "visible",
	transform: "translateX(-50%)",
}));

export const accordionSx: SxProps<Theme> = {
	border: "none",
	overflowY: "visible",
	filter: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.09))",
	pb: "1rem",
	["&.MuiAccordion-root:last-of-type"]: {
		borderRadius: ({ themeName }) =>
			themeName === "worldsmarathons" ? "8px" : 0,
	},
};

type AccordionStateProps = {
	"data-open": boolean;
};

export const StyledAccordionSummary = styled(
	AccordionSummary
)<AccordionStateProps>(({ "data-open": open }) => ({
	"&.MuiAccordionSummary-root.Mui-expanded": {
		minHeight: open ? "initial" : 0,
	},
	".MuiAccordionSummary-content": { margin: open ? "1rem" : "initial" },
}));

export const AccordionRevealStack = styled(Stack)<AccordionStateProps>(
	({ "data-open": open }) => ({
		width: "100%",
		my: "1rem",
		zIndex: 20,
		".accordionReveal-row": { paddingBottom: open ? 0 : ".5rem" },
	})
);

export const slideMenuToggleButtonSx: SxProps<Theme> = {
	position: "absolute",
	right: 0,
	top: -35,
	bgcolor: "white",
	borderRadius: ({ themeName }) =>
		themeName === "worldsmarathons" ? "8px 8px 0 0" : 0,
	color: wmPalette.grey[90],
	fontWeight: 400,
	zIndex: 10,
	["&:hover"]: { bgcolor: "white", color: wmPalette.grey[90] },
};

export const mobileSummaryDetailsSx: SxProps = {
	maxHeight: "77.25dvh",
	overflowY: "scroll",
	padding: "0.25rem 1rem 1rem",
	zIndex: 1,
	position: "relative",
};

export const mobileSummaryDetails_ContentContainer: SxProps = {
	display: "flex",
	flexDirection: "column",
};

export const addParticipantBtnSx: SxProps<Theme> = {
	gap: 0,
	paddingInline: 0,
	fontWeight: "400",
	color: (theme) => theme.palette.tertiary.main,
};

export const summaryTextStack: SxProps<Theme> = {
	alignItems: "center",
	justifyContent: "space-between",
	["&[data-theme=worldsmarathons]"]: {
		paddingBottom: "1.5rem",
	},
};
