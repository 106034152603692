import { Order } from "services/common/models/order";
import { ProductTypeStr } from "types/models/Product";

export interface IAnalyticsService {
	reportNewRegistration(order: Order): Promise<void>;
	reportEventToAnalytics(
		event: GAAnalyticsEvent,
		orderId: string
	): Promise<void>;
}

export interface AnalyticsProduct {
	sku: string;
	name: string;
	category: ProductTypeStr;
	price: number;
	quantity: number;
}

export type AnalyticsItem = Record<string, AnalyticsProduct>;

export enum AnalyticsConstants {
	TransactionCurrency = "EUR",
}

export interface GA4EventItem {
	item_id: string;
	item_name: string;
	affiliation?: string;
	coupon?: string;
	discount?: number;
	item_brand?: string;
	item_category2?: string;
	item_category3?: string;
	item_category4?: string;
	item_category5?: string;
	item_list_id?: string;
	item_list_name?: string;
	item_variant?: string;
	location_id?: string;
	price?: number;
	quantity?: number;
}

export interface CustomEventRequest {
	analyticsEvent: GAAnalyticsEvent;
	orderId: string;
}

export interface RecommendedEventRequest {
	analyticsEvent: GAAnalyticsEvent;
	allowMultiple: boolean;
	currency?: string;
	value?: number;
	items?: GA4EventItem[];
	transaction_id?: string;
}

/* IMPORTANT: When testing GA Events, append the word "-test" to the end and create copy of the event
 * in Google Tag Manager with the same name
 * ex: If the event is "start", create a copy of this on GTM, rename the copy to "start-test" and then change
 * the name here temporarily in order to test it
 */
export enum GAAnalyticsEvent {
	NewRegistration = "newRegistration",
	RequestResults = "requestResults",
	ClaimResult = "claimResult",
	ClaimResultEmbedded = "claimResultEmbedded",
	NotifyAbout = "notifyAbout",
	RegisterAndRace = "registerAndRace",
	RegisterGoToOffical = "goToRegister",
	RegisterTitle = "registerTitle",
	AddToBucketList = "addToBucketList",
	JoinCommunity = "joinCommunity",
	DiscoverInviteFriends = "discoverInviteFriends",
	DiscoverBucketlist = "discoverBucketlist",
	DiscoverReviews = "discoverReviews",
	DiscoverResults = "discoverResults",
	CreateBucketlist = "createBucketlist",
	WriteReview = "writeReview",
	BecomeAffiliate = "becomeAffiliate",
	CheckoutFlowCredits = "checkoutFlowCredits",
	CheckoutFlowConfirmation = "checkoutFlowConfirmation",
	CheckoutFlowUserInfo = "checkoutFlowUserInfo",
	BucketlistShare = "BucketlistShare",
	InvitedFriend = "invitedFriend",
	ReferredFriend = "referredFriend",
	InviteFriends = "inviteFriends",
	Affiliate = "affiliate",
	FollowUser = "followUser",
	ShowFollowersUser = "showFollowersUser",
	ShowFollowingUser = "showFollowingUser",
	FollowAuthor = "followAuthor",
	ClaimEvent = "claimEvent",
	Signup = "signup",
	OrganizerSignup = "organizerSignup",
	InternationalReach = "internationalReach",
	DiscoverOrganizers = "discoverOrganizers",
	GiftCardClaim = "giftCardClaim",
	CheckoutStart = "start",
	PersonalDetailsDone = "personalDetailsDone",
	AddedPaymentDetails = "addedPaymentDetails",
	Confirmation = "confirmation",
	SubscribeToNewsletter = "newsletterSubscription",
	AddToCart = "add_to_cart",
	RemoveFromCart = "remove_from_cart",
	AddPaymentInfo = "add_payment_info",
	AddPersonalInfo = "add_personal_info",
	AddTeamInfo = "add_team_info",
	AddAddOn = "add_add_on",
	Purchase = "purchase",
	SelectTicket = "select_ticket",
}

export interface TagManagerData {
	event: GAAnalyticsEvent;
	transactionId: string;
	transactionCurrency: string;
	transactionAffiliation: string;
	transactionTotal: number;
	transactionProducts: any[];
	content_ids: string[];
	content_type: "product";
}
