import { Dialog, styled } from "@mui/material";
import wsgTheme from "theme/WSGTheme";

export const StyledDialog = styled(Dialog)`
	.MuiDialog-paper {
		max-height: 75vh;
		width: clamp(50vw, 55vw, 60vw);
		padding-block-end: 1rem;
	}

	.MuiDialogTitle-root,
	.MuiDialogContent-root,
	.MuiDialogActions-root {
		padding-inline: 2rem;
	}

	.MuiDialogTitle-root {
		padding-block-start: 1.5rem;
	}

	.MuiDialogActions-root {
		padding-block-end: 0.5rem;
	}

	${wsgTheme.breakpoints.down("md")} {
		.MuiDialog-paper {
			margin: 0;
			max-height: 100vh;
			width: 100vw;
			max-width: unset;
		}
	}
`;
