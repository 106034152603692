import { PaymentModifierType } from "./PaymentModifierProvider";
import { createContext, MouseEvent } from "react";

export interface IPaymentModifierContext {
	isUsingCredits: boolean;
	isUsingCoupon: boolean;
	setCurrentPaymentModifierTo(modifier: PaymentModifierType): void;
	errorPopoverElement?: HTMLElement;
	openPopover(e: MouseEvent<HTMLElement>): void;
	closePopover(): void;
}

const paymentModifierContextDefaults: IPaymentModifierContext = {
	isUsingCredits: false,
	isUsingCoupon: false,
	setCurrentPaymentModifierTo() {
		return;
	},
	openPopover() {
		return;
	},
	closePopover() {
		return;
	},
};

const PaymentModifierContext = createContext<IPaymentModifierContext>(
	paymentModifierContextDefaults
);

export default PaymentModifierContext;
