import { createContext, Dispatch, SetStateAction } from "react";
import AuthenticatedUser, {
	IAuthenticatedUser,
} from "types/models/Authentication/AuthenticatedUser";

export interface IAuthenticationContext {
	user?: IAuthenticatedUser;
	persistUser(user: IAuthenticatedUser): void;
	handleLogout(): void;
	isLoggedIn: boolean;
	authenticationBanner?: {
		show: boolean;
		setShow: Dispatch<SetStateAction<boolean>>;
	};
}

export const authContextDefaults: IAuthenticationContext = {
	user: undefined,
	persistUser(token) {
		localStorage.setItem("user", (token as AuthenticatedUser).token);
	},
	handleLogout() {
		localStorage.removeItem("user");
	},
	isLoggedIn: false,
	authenticationBanner: {
		show: false,
		setShow: () => void 0,
	},
};

const AuthenticationContext =
	createContext<IAuthenticationContext>(authContextDefaults);

export default AuthenticationContext;
