import { GlobalReducerAction } from "types/common/global";

// Payloads
export interface SetTemporaryTotalPayload {
	total: number | undefined;
}

export interface SetTicketCountPayload {
	count: number;
}
export interface SetSelectTicketErrorPayload {
	message: string | undefined;
	type: "button" | "banner" | undefined;
}

// Actions
export const PageControlReducerActionKey = Object.freeze({
	SET_TEMP_TOTAL: "SET_TEMP_TOTAL",
	SET_TICKET_COUNT: "SET_TICKET_COUNT",
	SET_ERROR: "SET_ERROR",
} as const);

export type PageControlReducerActionType =
	keyof typeof PageControlReducerActionKey;

export type PageControlAction<
	ActionType extends PageControlReducerActionType,
	ActionPayload
> = GlobalReducerAction<ActionType, ActionPayload>;

export type SetTemporaryTotalPayloadAction = PageControlAction<
	typeof PageControlReducerActionKey["SET_TEMP_TOTAL"],
	SetTemporaryTotalPayload
>;

export type SetTicketCountAction = PageControlAction<
	typeof PageControlReducerActionKey["SET_TICKET_COUNT"],
	SetTicketCountPayload
>;

export type SetSelectTicketErrorAction = PageControlAction<
	typeof PageControlReducerActionKey["SET_ERROR"],
	SetSelectTicketErrorPayload
>;

export type PageControlReducerAction =
	| SetTemporaryTotalPayloadAction
	| SetTicketCountAction
	| SetSelectTicketErrorAction;

// State
export interface PageControlReducerState {
	temporaryTotal?: number;
	allowSummaryExpansion?: boolean;
	selectTicketState: {
		count: number;
	};
	error: {
		message: string | undefined;
		type: "button" | "banner" | undefined;
	};
}

export const pageControlReducerStateDefaults: PageControlReducerState = {
	temporaryTotal: undefined,
	allowSummaryExpansion: false,
	selectTicketState: {
		count: 0,
	},
	error: {
		message: undefined,
		type: undefined,
	},
};
