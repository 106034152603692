import { Theme, useMediaQuery } from "@mui/material";
import { DialogProps, DialogTitle } from "@mui/material";
import { PropsWithChildren } from "react";
import { StyledDialog } from "./Dialog.styles";

interface CustomDialogProps extends PropsWithChildren<DialogProps> {
	title?: string;
}

function Dialog({ children, title, ...props }: CustomDialogProps) {
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.down("md")
	);
	return (
		<StyledDialog {...props} fullScreen={isMobile}>
			{title && (
				<DialogTitle component="h1" variant="h2">
					{title}
				</DialogTitle>
			)}
			{children}
		</StyledDialog>
	);
}

export default Dialog;
