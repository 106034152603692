import { createContext, Dispatch, SetStateAction } from "react";
import { PaymentTypes } from "types/models/Payment";
import { PaymentError, PaymentRequirementState } from "./PaymentContext.types";

export interface PaymentContextProps {
	expandedOption: PaymentTypes | false;
	setExpandedOption(option: PaymentTypes): void;
	refundableBookingOptionSelected: boolean;
	setRefundableBookingOptionSelected(selected: boolean): void;
	isCurrencySupported: {
		set?: (supported: boolean) => void;
		get current(): boolean;
	};
	refundableBookingError: ErrorNotification<PaymentError>;
	paymentRequirements: ErrorNotificationWithSelectedState<PaymentRequirementState>;
	disabledOptions: {
		value: PaymentTypes[];
		set: Dispatch<SetStateAction<PaymentTypes[]>>;
	};
	isPaymentProcessing: boolean;
	setIsPaymentProcessing(processing: boolean): void;
}

interface ErrorNotification<T> {
	set: Dispatch<SetStateAction<T>>;
	get error(): string | undefined;
	show: boolean;
}

interface ErrorNotificationWithSelectedState<T> extends ErrorNotification<T> {
	selected: boolean;
}

export const paymentContextDefaults: PaymentContextProps = {
	expandedOption: "stripe",
	setExpandedOption: () => void 0,
	refundableBookingOptionSelected: false,
	setRefundableBookingOptionSelected: () => void 0,
	isCurrencySupported: {
		get current() {
			return false;
		},
	},
	refundableBookingError: {
		set() {
			// do nothing
		},
		get error() {
			return undefined;
		},
		show: false,
	},
	paymentRequirements: {
		set() {
			// do nothing
		},
		get error() {
			return undefined;
		},
		show: false,
		selected: false,
	},
	disabledOptions: {
		value: [],
		set: () => void 0,
	},
	isPaymentProcessing: false,
	setIsPaymentProcessing: () => void 0,
};

const PaymentContext = createContext<PaymentContextProps>(
	paymentContextDefaults
);

export default PaymentContext;
