import { useErrorHandler } from "react-error-boundary";
import { SWRRequestKeys } from "services/common/constants";
import {
	configureDefaultActions,
	ServiceActionDefaults,
} from "services/common/utils/hookUtils";
import { useEventService } from "services/Event";
import { useCurrency } from "services/Localization";
import useSWR from "swr";
import { Nullable } from "types/common/global";
import { EventProducts } from "types/models/EventProduct/eventProduct.types";

export const useEventProducts = (
	eventId?: string,
	raceId?: string,
	actions?: ServiceActionDefaults
) => {
	const { getEventRaceProducts } = useEventService();
	const currency = useCurrency();

	const modifiers = useSWR<Nullable<EventProducts>>(
		eventId && raceId && [SWRRequestKeys.EventProducts, currency],
		() => getEventRaceProducts({ eventId, raceId })
	);

	useErrorHandler(modifiers.error);
	configureDefaultActions(modifiers, actions);

	return modifiers;
};

export default useEventProducts;
