import useSWRImmutable from "swr";
import {
	configureDefaultActions,
	ServiceActionDefaults,
} from "services/common/utils/hookUtils";
import { useErrorHandler } from "react-error-boundary";
import axios from "axios";
import { ServiceBaseUrls } from "services/common/constants";
import { axiosErrorHandler } from "services/AxiosErrorHandling";
import { Stock } from "services/common/models/stock";

const useEventStock = (
	eventId?: string,
	editionId?: string,
	hasStocksEnabled?: boolean,
	actions?: ServiceActionDefaults
) => {
	const modifiers = useSWRImmutable<Stock | null>(
		hasStocksEnabled && eventId && editionId ? [eventId, editionId] : null,
		async (key: [string, string]) => {
			const url = `${ServiceBaseUrls.StockManagement}/events/${eventId}/editions/${editionId}`;
			const { data } = await axios.get<Stock>(url);
			return data;
		}
	);

	if (modifiers.error) {
		const apiError = axiosErrorHandler(axios).getErrorMessage(modifiers.error);
		if (typeof newrelic !== "undefined") {
			newrelic.noticeError(apiError.message, {
				requestUrl: `${ServiceBaseUrls.StockManagement}/events/${eventId}/editions/${editionId}`,
			});
		}
	}

	useErrorHandler(modifiers.error);

	configureDefaultActions(modifiers, actions);
	return modifiers;
};

export default useEventStock;
