import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import MainCheckoutLayout from "layouts/MainCheckoutLayout/MainCheckoutLayout";
const PersonalDetailsPage = lazy(() =>
	lazyRetry(() => import("pages/PersonalDetailsPage"), "personalDetailsPage")
);
const NotFoundPage = lazy(() =>
	lazyRetry(() => import("pages/NotFound"), "notFoundPage")
);
const TeamInfoPage = lazy(() =>
	lazyRetry(() => import("pages/TeamInfoPage"), "teamInfoPage")
);
const AddOnsPage = lazy(() =>
	lazyRetry(() => import("pages/AddOnsPage"), "addOnsPage")
);
const MainPaymentPage = lazy(() =>
	lazyRetry(
		() => import("pages/PaymentsPage/PaymentLayers/MainPaymentPageLayer"),
		"mainPaymentPage"
	)
);
const ChangeTicketPaymentPage = lazy(() =>
	lazyRetry(
		() =>
			import("pages/PaymentsPage/PaymentLayers/ChangeTicketPaymentPageLayer"),
		"changeTicketPaymentPage"
	)
);
const OverviewPage = lazy(() =>
	lazyRetry(() => import("pages/OverviewPage"), "overviewPage")
);
const TicketSelectPage = lazy(() =>
	lazyRetry(() => import("pages/TicketSelectPage"), "ticketSelectPage")
);

const ITabPage = lazy(() =>
	lazyRetry(() => import("pages/ITabPage"), "itabPage")
);

import { Fragment, PropsWithChildren, Suspense, lazy, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
	Backdrop,
	GlobalStyles,
	Grow,
	Stack,
	ThemeProvider,
} from "@mui/material";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import wsgTheme from "theme/WSGTheme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SnackbarProvider } from "notistack";
import { SnackbarDismissAction } from "components/common/SnackbarActions";
import { ConfirmProvider } from "material-ui-confirm";
import ContextualizerServiceProvider from "services/ContextualizerService";
import AhotuTheme from "theme/AhotuTheme";
import BaseErrorFallback from "components/Errors/BaseErrorFallback";
import { LocalizationProvider } from "@mui/x-date-pickers";
import ConfirmationDialogProvider from "contexts/ConfirmationDialogProvider/ConfirmationDialogProvider";
import useDomain from "hooks/useDomain";
import ProtectedRoute from "components/Routing/ProtectedRoute/ProtectedRoute";
import { FaviconLinks } from "types/services/navigation.types";
import TagManager from "react-gtm-module";
import CacheBuster from "CacheBuster";
import { lazyRetry } from "utils/lazy-retry";

export enum MainRoutes {
	CheckoutMain = "/book/:orderId",
	UpgradeTicket = "/change-ticket/:orderId",
	ClaimSpot = "/claim-spot/:orderId/:participantToken",
	NotFound = "/not-found",
}

export enum MainCheckoutRoutes {
	PersonalDetails = "info",
	TeamInfo = "team-info",
	AddOns = "addons",
	Payment = "payment",
	Overview = "confirmation",
	SelectTicket = "select-tickets",
	ITab = "itab",
}

const domainThemeMap = {
	worldsmarathons: wsgTheme,
	ahotu: AhotuTheme.theme,
};

const DevelopmentWrapper = ({ children }: PropsWithChildren) => {
	if (process.env.NODE_ENV === "development") {
		return <Fragment>{children}</Fragment>;
	}

	return (
		<ReactErrorBoundary FallbackComponent={BaseErrorFallback}>
			{children}
		</ReactErrorBoundary>
	);
};

function App() {
	const domain = useDomain();

	const ErrorBoundary = DevelopmentWrapper;

	useEffect(() => {
		let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
		if (!link) {
			link = document.createElement("link");
			link.rel = "icon";
			document.getElementsByTagName("head")[0].appendChild(link);
		}
		if (domain.data?.origin) {
			link.href = FaviconLinks[domain.data.origin];

			const GTM_ID =
				domain.data.origin === "ahotu"
					? process.env["REACT_APP_AHOTU_GTM_ID"]
					: process.env["REACT_APP_WM_GTM_ID"];

			if (GTM_ID) {
				TagManager.initialize({
					gtmId: GTM_ID,
				});
			}
		}
	}, [domain.data?.origin]);

	return (
		<CacheBuster>
			{({ loading, isLatestVersion, refreshCacheAndReload }) => {
				if (loading) return null;
				if (!loading && !isLatestVersion) {
					refreshCacheAndReload();
				}
				return (
					<ThemeProvider
						theme={
							domain.data
								? domainThemeMap[domain.data.origin]
								: AhotuTheme.theme
						}
					>
						<GlobalStyles
							styles={{
								body: {
									backgroundColor: domain.isWorldsMarathons
										? "#f9f9f9"
										: AhotuTheme.AhotuColorPalette.grey[10],
								},
							}}
						/>
						{domain.isLoading && (
							<Stack
								alignItems="center"
								justifyContent="center"
								sx={{ height: "100vh" }}
							>
								<CircularProgress />
							</Stack>
						)}
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<SnackbarProvider
								maxSnack={2}
								TransitionComponent={Grow}
								action={(snackbarId) => (
									<SnackbarDismissAction snackbarId={snackbarId} />
								)}
							>
								<ConfirmProvider
									defaultOptions={{
										cancellationText: "Cancel",
										cancellationButtonProps: { variant: "primary-text-dark" },
									}}
								>
									<ConfirmationDialogProvider>
										<ContextualizerServiceProvider>
											<BrowserRouter>
												<ErrorBoundary>
													<Suspense
														fallback={
															<Backdrop open sx={{ backgroundColor: "#fff" }}>
																<CircularProgress />
															</Backdrop>
														}
													>
														{!domain.isLoading && (
															<Routes>
																<Route
																	path={MainRoutes.CheckoutMain}
																	element={<MainCheckoutLayout />}
																>
																	<Route
																		path={MainCheckoutRoutes.SelectTicket}
																		element={
																			<ProtectedRoute
																				isAllowed={
																					domain.data?.origin !==
																					"worldsmarathons"
																				}
																				redirectPath={
																					MainCheckoutRoutes.PersonalDetails
																				}
																			>
																				<TicketSelectPage />
																			</ProtectedRoute>
																		}
																	/>
																	<Route
																		path={MainCheckoutRoutes.PersonalDetails}
																		element={<PersonalDetailsPage />}
																	/>
																	<Route
																		path={MainCheckoutRoutes.TeamInfo}
																		element={<TeamInfoPage />}
																	/>
																	<Route
																		path={MainCheckoutRoutes.AddOns}
																		element={<AddOnsPage />}
																	/>
																	<Route
																		path={MainCheckoutRoutes.ITab}
																		element={<ITabPage />}
																	/>
																	<Route
																		path={MainCheckoutRoutes.Payment}
																		element={<MainPaymentPage />}
																	/>
																	<Route
																		path={MainCheckoutRoutes.Overview}
																		element={<OverviewPage />}
																	/>
																</Route>
																<Route
																	path={MainRoutes.UpgradeTicket}
																	element={<MainCheckoutLayout />}
																>
																	<Route
																		path={MainCheckoutRoutes.Payment}
																		element={<ChangeTicketPaymentPage />}
																	/>
																	<Route
																		path={MainCheckoutRoutes.Overview}
																		element={<OverviewPage />}
																	/>
																</Route>
																<Route
																	path={MainRoutes.ClaimSpot}
																	element={<MainCheckoutLayout />}
																>
																	<Route
																		path={MainCheckoutRoutes.PersonalDetails}
																		element={<PersonalDetailsPage />}
																	/>
																	<Route
																		path={MainCheckoutRoutes.Overview}
																		element={<OverviewPage />}
																	/>
																</Route>
																<Route
																	path="/not-found"
																	element={<NotFoundPage />}
																/>
																<Route
																	path="*"
																	element={<Navigate to="/not-found" />}
																/>
															</Routes>
														)}
													</Suspense>
												</ErrorBoundary>
											</BrowserRouter>
										</ContextualizerServiceProvider>
									</ConfirmationDialogProvider>
								</ConfirmProvider>
							</SnackbarProvider>
						</LocalizationProvider>
					</ThemeProvider>
				);
			}}
		</CacheBuster>
	);
}

export default App;
