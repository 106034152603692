import { DialogProps } from "@mui/material";
import { IconName } from "components/common/Icon/assets";
import { ReactElement, createContext } from "react";

export interface ConfirmationDialogProps {
	DialogProps?: DialogProps;
	title: string;
	message: string | string[] | ReactElement;
	confirmText?: string;
	confirmLink?: string;
	cancelText?: string;
	icon?: IconName;
	materialIcon?: React.ReactNode;
	flag?: string;
	disableBackdropClick?: boolean;
	variant?: "critical" | "default" | "vertical";
}

export type ConfirmContentType = "string" | "array" | "element" | null;

export interface ConfirmCallbackProps {
	props: ConfirmationDialogProps;
}

interface IConfirmationDialogContext {
	confirm(props: ConfirmationDialogProps): Promise<boolean>;
}

const ConfirmationDialogContext = createContext<IConfirmationDialogContext>(
	{} as IConfirmationDialogContext
);

export default ConfirmationDialogContext;
