import { MainCheckoutRoutes, MainRoutes } from "App";
import { useMatch } from "react-router-dom";
import { RoutePatterns } from "types/common/pages";

const useCheckoutRouteMatch = () => {
	const isChangeTicketRoute = useMatch(`${RoutePatterns.CHANGE_TICKET}/*`);
	const MainRoutePatterns = {
		isMainRoute: useMatch(`${MainRoutes.CheckoutMain}/*`),
		isTeamInfoPage: useMatch(
			`${MainRoutes.CheckoutMain}/${MainCheckoutRoutes.TeamInfo}`
		),
		isConfirmationPage: useMatch(
			`${MainRoutes.CheckoutMain}/${MainCheckoutRoutes.Overview}`
		),
		isSelectTicketPage: useMatch(
			`${MainRoutes.CheckoutMain}/${MainCheckoutRoutes.SelectTicket}`
		),
		isPaymentPage: useMatch(
			`${MainRoutes.CheckoutMain}/${MainCheckoutRoutes.Payment}`
		),
		isAddonsPage: useMatch(
			`${MainRoutes.CheckoutMain}/${MainCheckoutRoutes.AddOns}`
		),
	};
	const isClaimSpotRoute = useMatch(`${RoutePatterns.CLAIM_SPOT}/*`);

	return { isChangeTicketRoute, MainRoutePatterns, isClaimSpotRoute };
};

export default useCheckoutRouteMatch;
