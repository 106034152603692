import jwtDecode from "jwt-decode";
import { AuthTokenData } from "types/models/Authentication";

export interface IAuthenticatedUser {
	username: string;
	onBoarded: string;
	role: string;
	name: string;
	email: string;
	profilePictureSrc?: string;
	token: string;
}

class AuthenticatedUser implements IAuthenticatedUser {
	readonly token: string;
	readonly username: string;
	readonly onBoarded: string;
	readonly role: string;
	expires_in: Date;
	name: string;
	email: string;
	profilePictureSrc?: string;

	constructor(access_token: string) {
		const tokenData = jwtDecode<AuthTokenData>(access_token);

		this.username = tokenData.userName;
		this.onBoarded = tokenData.onboarded;
		this.role =
			tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
		this.name =
			tokenData[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
			][1];
		this.email =
			tokenData[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
			][0];
		this.profilePictureSrc = tokenData["profilePicture"];

		this.token = access_token;
		this.expires_in = new Date(tokenData.exp);
	}
}

export default AuthenticatedUser;
