import { Theme } from "@mui/material";

const htmlFontSize = 16;

const convertPxToRem = (inputPx: number, base = htmlFontSize) => inputPx / base;
const getPxToRemCSS = (inputPx: number, base = htmlFontSize) =>
	`${convertPxToRem(inputPx, base)}rem`;

const breakpoints: Theme["breakpoints"]["values"] = {
	xs: 0,
	sm: 600,
	md: 960,
	lg: 1280,
	xl: 1920,
};

export default { pxToRemCssValue: getPxToRemCSS, breakpoints };
