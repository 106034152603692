import { ProductStock } from "services/common/models/stock";
import { Event } from "services/common/models/event";
import { useConfirmationDialog } from "contexts/ConfirmationDialogProvider/ConfirmationDialogProvider";
import { wmPalette } from "theme/WSGTheme";
import { useTranslation } from "react-i18next";
import RemoveShoppingCartOutlinedIcon from "@mui/icons-material/RemoveShoppingCartOutlined";
import useOrder from "services/Order/useOrder";
import { useParams } from "react-router";
import useEventStock from "services/StockManagement/useEventStock";
import useOrderStock from "services/StockOrderManagement/useOrderStock";
import { useOrderService } from "services/Order";
import { MainCheckoutLayoutParams, PageId } from "types/common/pages";
import useCheckoutRouteMatch from "./useRouteCheck";

const useStockManagement = (event?: Event) => {
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const { confirm } = useConfirmationDialog();
	const { t } = useTranslation(["add-on", "common", "dialogs"]);
	const { data: order, mutate: mutateOrder } = useOrder(orderId);
	const { mutate: mutateEventStock } = useEventStock(
		order?.eventId,
		order?.raceId,
		event?.hasStocksEnabled
	);
	const { mutate: mutateOrderStock } = useOrderStock(
		order?.orderId,
		event?.hasStocksEnabled,
		false
	);
	const orderService = useOrderService();
	const {
		MainRoutePatterns: { isAddonsPage, isPaymentPage },
	} = useCheckoutRouteMatch();

	const showOutOfStockModal = async (
		addons: string[],
		hasMandatorySoldOut?: boolean
	) => {
		const confirmed = await confirm({
			title: t("dialogs:outOfStock.modal.title"),
			message: addons,
			confirmText: t("common:selectAnotherOption"),
			cancelText:
				hasMandatorySoldOut === false
					? t("common:continueWithoutThisItem")
					: undefined,
			icon: "removeCart",
			materialIcon: (
				<RemoveShoppingCartOutlinedIcon
					sx={{ fontSize: "64px", color: wmPalette.grey[50] }}
				/>
			),
			variant: "vertical",
		});

		if (order) {
			await mutateOrderStock();
			if (isAddonsPage) {
				if (confirmed || (!confirmed && hasMandatorySoldOut === true)) {
					await mutateOrder();
					await mutateEventStock();
				} else if (hasMandatorySoldOut === false) {
					const updatedOrder = await orderService.updateCheckoutPageState(
						order.orderId
					);
					if (updatedOrder) {
						await mutateOrder(updatedOrder, { revalidate: false });
					}
				}
			}
			if (isPaymentPage) {
				if (confirmed || (!confirmed && hasMandatorySoldOut === true)) {
					const updatedOrder = await orderService.updateCheckoutPageState(
						order.orderId,
						PageId.AddOns
					);
					if (updatedOrder) {
						await mutateOrder(updatedOrder, { revalidate: false });
					}
				} else if (hasMandatorySoldOut === false) {
					await mutateOrder();
				}
			}
		}
	};

	const getOutOfStockNames = (outOfStockAddons: ProductStock[]) => {
		const outOfStockNames = [];
		const extras = event?.extras || [];
		const extraNames = extras
			.filter((e) =>
				outOfStockAddons.some(
					(a) => a.productId === e.productId && a.productOptionId === null
				)
			)
			.map((e) => e.productName);
		outOfStockNames.push(...extraNames);

		extras.forEach((e) => {
			if (e.productDetails.length > 0) {
				const productDetails = e.productDetails[0];
				const name = productDetails.optionName;
				const hasData = Object.keys(productDetails.data).length > 0;
				if (hasData) {
					const productOptions = Object.values(productDetails.data[name]);
					const productOptionsNames = productOptions
						.filter((po) =>
							outOfStockAddons.some((a) => a.productOptionId === po.id)
						)
						.map((po) => e.productName + " " + po.label);
					outOfStockNames.push(...productOptionsNames);
				}
			}
		});
		return outOfStockNames;
	};

	const findMandatoryOutOfStock = (outOfStockAddons: ProductStock[]) => {
		const extras = event?.extras || [];
		const mandatoryExtras = extras.filter((e) => e.required === true);
		const outOfStockMandatoryExtras = mandatoryExtras.filter((e) =>
			outOfStockAddons.some((a) => a.productId === e.productId)
		);

		return outOfStockMandatoryExtras.length > 0;
	};
	return { showOutOfStockModal, getOutOfStockNames, findMandatoryOutOfStock };
};

export default useStockManagement;
