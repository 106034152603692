import { Skeleton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useEventFromOrder from "services/Event/useEvent";
import useOrder from "services/Order/useOrder";
import { MainCheckoutLayoutParams } from "types/common/pages";
import {
	orderEventSummarySx,
	orderSummaryEventDetailsSx,
} from "./OrderSummaryHeader.styles";
import useCheckoutRouteMatch from "hooks/useRouteCheck";
import { customFlatMap } from "services/common/utils/arrayUtils";

const OrderSummaryHeader = () => {
	const { orderId } = useParams<MainCheckoutLayoutParams>();
	const order = useOrder(orderId);
	const event = useEventFromOrder(order.data?.eventId);
	const { t } = useTranslation(["common"]);
	const {
		MainRoutePatterns: { isSelectTicketPage },
		isChangeTicketRoute,
	} = useCheckoutRouteMatch();

	const nextPriceStep = useMemo(() => {
		if (!event.data?.tickets) {
			return null;
		}

		const { tickets: eventTickets } = event.data;

		const ticketPriceSteps = customFlatMap(eventTickets, (t) => t.priceSteps)
			.filter((t) => dayjs(t.activationAt).isAfter(dayjs()))
			.sort(
				(a, b) =>
					new Date(a.activationAt).getTime() -
					new Date(b.activationAt).getTime()
			);

		if (ticketPriceSteps?.length === 0) {
			return null;
		}

		const earliestDate = ticketPriceSteps?.[0].activationAt;
		return dayjs(earliestDate).format("DD MMMM, YYYY");
	}, [event.data?.tickets]);

	const registrationEndDateFormatted = dayjs(event.data?.strSaleEndDate).format(
		"DD MMMM"
	);
	const daysTillRegistrationEndDate = dayjs(event.data?.strSaleEndDate).fromNow(
		true
	);

	const isLoading = order.isLoading || event.isLoading;

	return (
		<Stack sx={orderEventSummarySx}>
			<Stack gap="0.5rem" sx={orderSummaryEventDetailsSx}>
				<Typography component="h1" variant="subtitle2">
					{isLoading ? <Skeleton /> : t("common:orderSummary.title")}
				</Typography>
				<Typography component="h2" variant="h1">
					{isLoading ? <Skeleton /> : event.data?.adsTitle}
				</Typography>
			</Stack>
			{!isChangeTicketRoute && (
				<Stack gap="0" sx={orderSummaryEventDetailsSx}>
					{isSelectTicketPage && (
						<Typography
							variant="subtitle1"
							textAlign="center"
							hidden={!nextPriceStep}
						>
							{isLoading ? (
								<Skeleton />
							) : (
								<Trans
									i18nKey="common:orderEventSummary.priceStepEndsOn"
									values={{ nextPriceStep }}
									components={{
										highlightText: (
											<Typography
												component="span"
												variant="inherit"
												fontWeight="bold"
											/>
										),
									}}
								/>
							)}
						</Typography>
					)}
					<Typography variant="subtitle1" textAlign="center">
						{isLoading ? (
							<Skeleton />
						) : (
							<Trans
								i18nKey="common:orderEventSummary.registrationEndInformation"
								values={{
									endDate: registrationEndDateFormatted,
									daysLeftTillDate: daysTillRegistrationEndDate,
								}}
								components={{
									endDateText: (
										<Typography
											component="span"
											variant="inherit"
											fontWeight="bold"
										/>
									),
								}}
							/>
						)}
					</Typography>
				</Stack>
			)}
		</Stack>
	);
};

export default OrderSummaryHeader;
