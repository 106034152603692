import { AxiosStatic } from "axios";
import { ApiErrorCodes } from "types/common/enums";
import ApiError from "types/models/ApiError";

export const axiosErrorHandler = (axiosInstance: AxiosStatic) => {
	function getErrorMessage(e: any): ApiError {
		if (axiosInstance.isAxiosError(e) && e.response) {
			return new ApiError(
				e.response.status,
				e.response.data as string[],
				e.request.responseURL,
				e.config.data,
				true
			);
		}

		return getDefaultInternalError();
	}

	function getDefaultInternalError() {
		return new ApiError(
			ApiErrorCodes.InternalServerError,
			"Something went wrong. Please contact support",
			"",
			"",
			false
		);
	}

	return { getErrorMessage };
};
