import { MenuOption } from "types/common/global";

export const measures: readonly MenuOption[] = [
	{
		label: "Metric",
		i18nKey: "common:measure.metric",
		value: 0,
	},
	{
		label: "Imperial",
		i18nKey: "common:measure.imperial",
		value: 1,
	},
];
