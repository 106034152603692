export type PaymentTypes = "paypal" | "stripe";

export enum ClientSecretType {
	freeOrder = "freeOrder",
}

export enum PaymentQueryKeys {
	ClientSecret = "payment_intent_client_secret",
	RedirectStatus = "redirect_status",
}
