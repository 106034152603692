import { PaymentIntent } from "@stripe/stripe-js";
import axios from "axios";
import { LocaleContext } from "contexts/LocaleProvider";
import { useSnackbar } from "notistack";
import { PropsWithChildren, useContext } from "react";
import { axiosErrorHandler } from "services/AxiosErrorHandling";
import { ApiServices } from "services/common/constants";
import { ServiceBaseUrls } from "services/common/constants";
import { Order } from "services/common/models/order";
import Contextualizer from "services/ServiceContextualizer";
import { Nullable } from "types/common/global";
import { PaypalIntent } from "types/models/PaypalRequestBody/PaypalIntent";
import {
	AgreeToNewsletter,
	ContinueErrorMessageMap,
	IOrderService,
	TermsAndNewsletter,
} from "types/services/orderService.types";

const OrderServiceContext = Contextualizer.createContext(
	ApiServices.OrderService
);

interface OrderServiceProps extends PropsWithChildren {}

function OrderService({ children }: OrderServiceProps) {
	const { enqueueSnackbar } = useSnackbar();
	const { localeState } = useContext(LocaleContext);
	const { currency } = localeState;

	const orderService: IOrderService = {
		async getOrder(orderId, providedCurrency): Promise<Nullable<Order>> {
			try {
				const { data } = await axios.get<Order>(
					`${ServiceBaseUrls.Order}/${orderId}?currency=${
						providedCurrency ?? currency
					}`
				);
				return data;
			} catch (error) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(error);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}?currency=${
							providedCurrency ?? currency
						}`,
						orderId: orderId,
					});
				}
			}
		},

		async submitPersonalDetails(
			orderId,
			personalDetails
		): Promise<Nullable<Order>> {
			try {
				const { data } = await axios.put<Order>(
					`${ServiceBaseUrls.Order}/${orderId}/participants?currency=${currency}`,
					personalDetails.participants
				);
				return data;
			} catch (e) {
				const isAxiosError = axios.isAxiosError(e);
				if (isAxiosError) {
					const errorObj = e.response?.data as Array<Record<string, any>>;
					enqueueSnackbar(Object.values<string>(errorObj[0])[0], {
						variant: "error",
					});
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(Object.values<string>(errorObj[0])[0], {
							requestUrl: `${ServiceBaseUrls.Order}/${orderId}/participants?currency=${currency}`,
							orderId: orderId,
							payload: JSON.stringify(personalDetails.participants),
						});
					}
					return;
				}
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(e), {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/participants?currency=${currency}`,
						orderId: orderId,
						payload: JSON.stringify(personalDetails.participants),
					});
				}
				enqueueSnackbar(
					"There was an issue when submitting your personal details",
					{ variant: "error" }
				);
			}
		},

		async addParticipant(
			orderId,
			newParticipantTickets
		): Promise<Nullable<Order>> {
			try {
				const { data } = await axios.post<Order>(
					`${ServiceBaseUrls.Order}/${orderId}/multipleparticipants?currency=${currency}`,
					newParticipantTickets
				);
				return data;
			} catch (error) {
				enqueueSnackbar("Failed to add participant", {
					variant: "error",
				});
				const apiError = axiosErrorHandler(axios).getErrorMessage(error);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/multipleparticipants?currency=${currency}`,
						orderId: orderId,
						payload: JSON.stringify(newParticipantTickets),
					});
				}
			}
		},

		async changeParticipantTicket(
			orderId,
			participantId,
			productId
		): Promise<Nullable<Order>> {
			const queryParams = `currency=${currency}`;
			try {
				const { data } = await axios.put(
					`${ServiceBaseUrls.Order}/${orderId}/changeticket/${participantId}/ticket/${productId}?${queryParams}`
				);

				return data;
			} catch (error) {
				enqueueSnackbar("Failed to change participant ticket", {
					variant: "error",
				});
				const apiError = axiosErrorHandler(axios).getErrorMessage(error);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/changeticket/${participantId}/ticket/${productId}?${queryParams}`,
						orderId: orderId,
					});
				}
			}
		},

		async deleteParticipant(orderId, participantId): Promise<Nullable<Order>> {
			try {
				const { data } = await axios.delete(
					`${ServiceBaseUrls.Order}/${orderId}/participants/${participantId}?currency=${currency}`
				);
				return data;
			} catch (error) {
				enqueueSnackbar("Failed to delete participant", {
					variant: "error",
				});
				const apiError = axiosErrorHandler(axios).getErrorMessage(error);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/participants/${participantId}?currency=${currency}`,
						orderId: orderId,
					});
				}
			}
		},

		async updateCheckoutPageState(
			orderId,
			newPageId
		): Promise<Nullable<Order>> {
			try {
				const { data } = await axios.put<Order>(
					newPageId
						? `${ServiceBaseUrls.Order}/${orderId}/setstate/${newPageId}?currency=${currency}`
						: `${ServiceBaseUrls.Order}/${orderId}/continue?currency=${currency}`
				);
				return data;
			} catch (err) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(err);
				const isAxiosError = axios.isAxiosError(err);
				if (isAxiosError) {
					const errorData = err.response?.data;

					if (Array.isArray(errorData)) {
						// HACK: This is a temporary fix for the error message
						// that is returned from the API. The API should return
						// an object instead of an array.
						if (errorData.some((d) => typeof d === "object")) {
							const errorObj = err.response?.data as Array<Record<string, any>>;
							enqueueSnackbar(Object.values<string>(errorObj[0])[0], {
								variant: "error",
							});
							if (typeof newrelic !== "undefined") {
								newrelic.noticeError(Object.values<string>(errorObj[0])[0], {
									requestUrl: newPageId
										? `${ServiceBaseUrls.Order}/${orderId}/setstate/${newPageId}?currency=${currency}`
										: `${ServiceBaseUrls.Order}/${orderId}/continue?currency=${currency}`,
									orderId: orderId,
								});
							}
						}
						if (errorData.some((d) => typeof d === "string")) {
							if (
								(errorData as string[])[0] ===
								ContinueErrorMessageMap.ValidationError
							) {
								if (typeof newrelic !== "undefined") {
									newrelic.noticeError(apiError.message, {
										requestUrl: newPageId
											? `${ServiceBaseUrls.Order}/${orderId}/setstate/${newPageId}?currency=${currency}`
											: `${ServiceBaseUrls.Order}/${orderId}/continue?currency=${currency}`,
										orderId: orderId,
									});
								}
								return;
							}
						}
					}
					if (typeof newrelic !== "undefined") {
						newrelic.noticeError(apiError.message, {
							requestUrl: newPageId
								? `${ServiceBaseUrls.Order}/${orderId}/setstate/${newPageId}?currency=${currency}`
								: `${ServiceBaseUrls.Order}/${orderId}/continue?currency=${currency}`,
							orderId: orderId,
						});
					}
					return;
				}
				enqueueSnackbar("Failed to update page state", { variant: "error" });
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(JSON.stringify(err), {
						requestUrl: newPageId
							? `${ServiceBaseUrls.Order}/${orderId}/setstate/${newPageId}?currency=${currency}`
							: `${ServiceBaseUrls.Order}/${orderId}/continue?currency=${currency}`,
						orderId: orderId,
					});
				}
			}
		},

		async updateOrderAddOns(orderId, productId, addOnData) {
			try {
				const { data } = await axios.post<Order>(
					`${ServiceBaseUrls.Order}/${orderId}/participants/addons/${productId}?currency=${currency}`,
					addOnData
				);

				return data;
			} catch (e) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(e);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/participants/addons/${productId}?currency=${currency}`,
						orderId: orderId,
						payload: JSON.stringify(addOnData),
					});
				}
				enqueueSnackbar("Failed to update add-ons", { variant: "error" });
			}
		},

		async createPaymentIntention(orderId, skipValidation) {
			const query = skipValidation ? "?skipValidation=true" : "";
			try {
				const res = await axios.post<PaymentIntent | string>(
					`${ServiceBaseUrls.Order}/${orderId}/createpaymentintention${query}`
				);

				return res.data;
			} catch (e) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(e);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/createpaymentintention${query}`,
						orderId: orderId,
					});
				}
				const message = "Failed to create payment session";
				throw Error(message);
			}
		},

		async updateRefundPreference(orderId, needsRefund) {
			try {
				const { data } = await axios.put<Order>(
					`${ServiceBaseUrls.Order}/${orderId}/refund?selected=${needsRefund}&currency=${currency}`
				);
				return data;
			} catch (e) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(e);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/refund?selected=${needsRefund}&currency=${currency}`,
						orderId: orderId,
					});
				}
				const message = "Failed to update payment preference";
				enqueueSnackbar(message, { variant: "error" });
			}
		},

		async updateTermsAndConditionsState(
			orderId,
			conditions: TermsAndNewsletter
		) {
			try {
				const { data } = await axios.post<Nullable<TermsAndNewsletter>>(
					`${ServiceBaseUrls.Order}/${orderId}/agreetoterms?currency=${currency}`,
					conditions
				);
				return data;
			} catch (e) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(e);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/agreetoterms?currency=${currency}`,
						orderId: orderId,
						payload: JSON.stringify(conditions),
					});
				}
				enqueueSnackbar("Failed to update terms and conditions", {
					variant: "error",
				});
			}
		},

		async agreeToNewsLetter(orderId, subscribed) {
			try {
				const { data } = await axios.post<AgreeToNewsletter>(
					`${ServiceBaseUrls.Order}/${orderId}/agreetonewsletter`,
					{ newsletter: subscribed }
				);
				return data;
			} catch (e) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(e);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/agreetonewsletter`,
						orderId: orderId,
						payload: JSON.stringify({ newsletter: subscribed }),
					});
				}
				const message = "Failed to create payment session";
				throw Error(message);
			}
		},

		async createPaypalPaymentIntent(orderId) {
			try {
				const { data } = await axios.post<PaypalIntent>(
					`${ServiceBaseUrls.Order}/${orderId}/paypalpay?currency=${currency}`
				);
				return data;
			} catch (error) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(error);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/paypalpay?currency=${currency}`,
						orderId: orderId,
					});
				}
				enqueueSnackbar("Failed to create paypal payment intent", {
					variant: "error",
				});
			}
		},

		async createFreeOrder(orderId) {
			try {
				const { data } = await axios.post<string>(
					`${ServiceBaseUrls.Order}/${orderId}/createFreeOrder`
				);
				return data;
			} catch (e) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(e);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/createFreeOrder`,
						orderId: orderId,
					});
				}
				enqueueSnackbar("Failed to complete order.");
			}
		},

		async agreeToWaiver(orderId, agreementData) {
			try {
				const { data } = await axios.post(
					`${ServiceBaseUrls.Order}/${orderId}/agreetowaiver`,
					agreementData
				);
				return data;
			} catch (e) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(e);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/agreetowaiver`,
						orderId: orderId,
						payload: JSON.stringify(agreementData),
					});
				}
				enqueueSnackbar("Failed to accept waiver.");
			}
		},

		async agreeToAgeConsent(orderId, agreed) {
			try {
				const { data } = await axios.post(
					`${ServiceBaseUrls.Order}/${orderId}/ageconsent?consent=${agreed}`
				);
				return data;
			} catch (e) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(e);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/${orderId}/ageconsent?consent=${agreed}`,
						orderId: orderId,
					});
				}
				enqueueSnackbar("Failed to accept waiver.");
			}
		},

		async confirmPayPalOrder(orderId, paymentId, payerId) {
			try {
				const { data } = await axios.post(
					`${ServiceBaseUrls.Order}/paypalresponse?paymentId=${paymentId}&payerId=${payerId}&orderId=${orderId}`
				);

				return data;
			} catch (e) {
				const apiError = axiosErrorHandler(axios).getErrorMessage(e);
				if (typeof newrelic !== "undefined") {
					newrelic.noticeError(apiError.message, {
						requestUrl: `${ServiceBaseUrls.Order}/paypalresponse?paymentId=${paymentId}&payerId=${payerId}&orderId=${orderId}`,
						orderId: orderId,
					});
				}
				enqueueSnackbar("Failed to complete PayPal order");
			}
		},
	};

	return (
		<OrderServiceContext.Provider value={orderService}>
			{children}
		</OrderServiceContext.Provider>
	);
}

export const useOrderService = () =>
	Contextualizer.use<IOrderService>(ApiServices.OrderService);

export default OrderService;
