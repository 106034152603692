/* eslint-disable prettier/prettier */
import { ReactComponent as close } from "./icon-close.svg";
import { ReactComponent as settings } from "./icon-settings.svg";
import { ReactComponent as arrowRight } from "./icon-arrow-right.svg";
import { ReactComponent as globe } from "./icon-globe.svg";
import { ReactComponent as dollar } from "./icon-dollar.svg";
import { ReactComponent as tapeMeasure } from "./icon-tape-measure.svg";
import { ReactComponent as chevronLeft } from "./icon-chevron-left.svg";
import { ReactComponent as check } from "./icon-check.svg";
import { ReactComponent as calendar } from "./icon-calendar.svg";
import { ReactComponent as trash } from "./icon-trash.svg";
import { ReactComponent as priceTag } from "./icon-price-tag.svg";
import { ReactComponent as tip } from "./icon-tip.svg";
import { ReactComponent as chevronUp } from "./icon-chevron-up.svg";
import { ReactComponent as chevronDown } from "./icon-chevron-down.svg";
import { ReactComponent as info } from "./icon-info.svg";
import { ReactComponent as route } from "./icon-route.svg";
import { ReactComponent as clock } from "./icon-clock.svg";
import { ReactComponent as minus } from "./icon-minus.svg";
import { ReactComponent as plus } from "./icon-plus.svg";
import { ReactComponent as noEntrance } from "./icon-no-entrance.svg";
import { ReactComponent as externalLink } from "./icon-external-link.svg";
import { ReactComponent as deliveryVan } from "./itab/icon-delivery-van.svg";
import { ReactComponent as finishFlag } from "./itab/icon-finish.svg";
import { ReactComponent as smartPhone } from "./itab/icon-smart-phone.svg";
import { ReactComponent as home } from "./itab/icon-home.svg";
import { ReactComponent as medal } from "./itab/icon-medal.svg";
import { ReactComponent as navigateRight } from "./itab/icon-navigate-right.svg";
import { ReactComponent as navigateDown } from "./itab/icon-navigate-down.svg";
import { ReactComponent as creditCard } from "./icon-credit-card.svg";
import { ReactComponent as newTab } from "./icon-new-tab.svg";
import { ReactComponent as removeCart } from "./icon-remove-cart.svg";
import { ReactComponent as registrationClose } from "./icon-registration-close.svg";

export type IconName =
  | "settings"
  | "arrowRight"
  | "globe"
  | "dollar"
  | "tapeMeasure"
  | "chevronLeft"
  | "check"
  | "calendar"
  | "trash"
  | "close"
  | "priceTag"
  | "tip"
  | "chevronUp"
  | "chevronDown"
  | "info"
  | "route"
  | "clock"
  | "minus"
  | "plus"
  | "noEntrance"
  | "externalLink"
  | "deliveryVan"
  | "finishFlag"
  | "smartPhone"
  | "home"
  | "medal"
  | "navigateRight"
  | "navigateDown"
  | "creditCard"
  | "newTab"
  | "removeCart"
  | "registrationClose";

export default {
  settings,
  close,
  arrowRight,
  globe,
  dollar,
  tapeMeasure,
  chevronLeft,
  check,
  calendar: calendar,
  trash,
  priceTag,
  tip,
  chevronUp,
  chevronDown,
  info,
  route,
  clock,
  minus,
  plus,
  noEntrance,
  externalLink,
  deliveryVan,
  finishFlag,
  smartPhone,
  home,
  medal,
  navigateRight,
  navigateDown,
  creditCard,
  newTab,
  removeCart,
  registrationClose
};
