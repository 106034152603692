export const ExternalDomains = {
	WorldsMarathons: "https://worldsmarathons.com",
	WorldsMarathonsDev: "https://devtest.worldsmarathons.com",
	WorldsMarathonsSupport: "https://help.worldsmarathons.com",
	Ahotu: "https://ahotu.com",
	AhotuSupport: "https://help.ahotu.com",
} as const;

const resolveWorldsMarathonDomain = () => {
	if (process.env.NODE_ENV === "development") {
		return ExternalDomains.WorldsMarathonsDev;
	}

	return ExternalDomains.WorldsMarathons;
};

export const MainDomains = {
	worldsmarathons: ExternalDomains.WorldsMarathons,
	ahotu: ExternalDomains.Ahotu,
} as const;

export type ExternalDomains =
	typeof ExternalDomains[keyof typeof ExternalDomains];

export const ExternalRoutes = {
	ProfilePage: (userName: string) =>
		`${resolveWorldsMarathonDomain()}/${userName}/bucketlist`,
	TermsOfService: `${ExternalDomains.WorldsMarathonsSupport}/article/44-terms-of-service`,
	PrivacyPolicy: {
		worldsmarathons: `${ExternalDomains.WorldsMarathonsSupport}/article/48-privacy-policy`,
		ahotu: `${ExternalDomains.Ahotu}/page/privacy-policy`,
	},
	RefundableBooking: {
		Covid19Coverage: `${ExternalDomains.WorldsMarathonsSupport}/article/26-refundable-booking-upgrade-covid-19`,
		TermsAndConditions: "https://www.refundable.me/extended/en/",
	},
	HelpAndSupport: {
		worldsmarathons: ExternalDomains.WorldsMarathonsSupport,
		ahotu: ExternalDomains.AhotuSupport,
	},
	ITab: {
		ReturnPolicy: `https://www.itab.us.com/returns-policy`,
		ITabMedal: `https://www.itab.us.com/wp-content/uploads/2023/01/iTAB-Registration-GIF.gif`,
	},
} as const;

export const FaviconLinks = {
	worldsmarathons: `${ExternalDomains.WorldsMarathons}/favicon.png`,
	ahotu: `${ExternalDomains.Ahotu}/favicon.ico`,
};

export type ExternalRoutes = typeof ExternalRoutes[keyof typeof ExternalRoutes];
